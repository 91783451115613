import React from "react";
import HomePage from "../../../components/CMS/HomePage/Home";

function HomePageContent() {
  return (
    <>
      <div className="col-md-9 p-sm-0 ">
        <div class="right-contentbox">
          <HomePage />
        </div>
      </div>
    </>
  );
}

export default HomePageContent;
