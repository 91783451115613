import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./pages/admin-dashboard/dashboard";
import Users from "./pages/admin-dashboard/User/users";
import EditUsers from "./pages/admin-dashboard/User/EditUser";
import Login from "./pages/admin-dashboard/login";
import BlogCategoryList from "./pages/admin-dashboard/blogs/blogCategoryList";

import Global from "./components/Global";
import { store } from "./redux/combine";
import { Provider } from "react-redux";
import BlogCategoryCreate from "./pages/admin-dashboard/blogs/blogCategoryCreate";
import BlogCategoryEdit from "./pages/admin-dashboard/blogs/blogCategoryEdit";
import { checkLogin, logout } from './helpers/auth'
import { useState } from "react";
import Verify from "./pages/admin-dashboard/verify";
import Details from "./pages/admin-dashboard/details";
import HelpContactus from "./pages/admin-dashboard/help-contactus";
import HelpDeskView from "./pages/admin-dashboard/help & support/HelpDeskView";
import ContactUsView from "./pages/admin-dashboard/help & support/ContactUsView";
import Password from "./pages/admin-dashboard/password";
import Cost from "./pages/admin-dashboard/Cost-Subscription-Plan/Cost";
import Addform from "./pages/admin-dashboard/Cost-Subscription-Plan/AddCostForm";

import Addcost from "./pages/admin-dashboard/Cost-Subscription-Plan/AddCost";

import LoginRoute from "./routes/LoginRoute";
import PrivateRoute from "./routes/PrivateRoute";
import SubscriptionPlanList from "./pages/admin-dashboard/subscription-plans/subscriptionPlanList";
import SubscriptionPlanCreate from "./pages/admin-dashboard/subscription-plans/subscriptionPlanCreate";
import SubscriptionPlanEdit from "./pages/admin-dashboard/subscription-plans/subscriptionPlanEdit";
import ProductCategoryList from "./pages/admin-dashboard/categories/product/categoryList";
import ProductCategoryCreate from "./pages/admin-dashboard/categories/product/categoryCreate";
// import ProductCategoryEdit from "./pages/admin-dashboard/categories/product/categoryEdit";
import ServiceCategoryList from "./pages/admin-dashboard/categories/service-nouse/categoryList";
import ServiceCategoryCreate from "./pages/admin-dashboard/categories/service-nouse/categoryCreate";
import ServiceCategoryEdit from "./pages/admin-dashboard/categories/service-nouse/categoryEdit";
import BrandList from "./pages/admin-dashboard/master/brand/list";
import BrandCreate from "./pages/admin-dashboard/master/brand/create";
import BrandEdit from "./pages/admin-dashboard/master/brand/edit";
import HelpDeskList from "./pages/admin-dashboard/help & support/HelpDeskList";
import ContactUsList from "./pages/admin-dashboard/help & support/ContactUsList";
import Content from "./pages/admin-dashboard/cms/content";
import WhoWeAreContent from "./components/CMS/WhoWeAre/WhoWeAre";
import BlogContent from "./pages/admin-dashboard/cms/BlogPage";



// =============================================================
import About from "./pages/admin-dashboard/cms/AboutUsPage";
import Whatweoffer from "./pages/admin-dashboard/cms/WhatWeOfferPage";
import ChooseYourPlan from "./pages/admin-dashboard/cms/ChooseYourPlanPage";
import WriteYourExperiance from './pages/admin-dashboard/cms/WriteYourExperiancePage'


import Addposting from "./pages/add-posting";
import Addpromotion from "./pages/add-promotion";
import ProductSubCategoryCreate from "./pages/admin-dashboard/categories/product/SubCategoryCreate";
import ProducSubCategoryList from "./pages/admin-dashboard/categories/product/SubCategoryList";
import ProductSubCategoryEdit from "./pages/admin-dashboard/categories/product/SubCategoryEdit";
import AdminEdit from "./pages/admin-dashboard/User/EditAdmin";
import Reviews from "./pages/admin-dashboard/ReviewRating/ReviewRating";
import ReviewsFeedback from "./pages/admin-dashboard/ReviewRating/FeedbackRating";
import Analytic from "./pages/admin-dashboard/Cost-Subscription-Plan/Analytic";
// import SubscriptionContentCMS from "./pages/admin-dashboard/cms/Subscription/SubscriptionContent";

import BlogPage from "./pages/admin-dashboard/cms/BlogPage";
import RangeOfProductPage from "./pages/admin-dashboard/cms/RangeOfProductPage";
import Wishlist from "./pages/admin-dashboard/cms/WishListPage";
import ContactUsPage from "./pages/admin-dashboard/cms/ContactUsPage"
import TermsAndConditionPage from "./pages/admin-dashboard/cms/TermsAndConditionPage";
import PrivacyAndPolicyPage from "./pages/admin-dashboard/cms/PrivacyPolicyPage";

import Subsglobally from "./pages/admin-dashboard/User/subsglobally";
import Revenglobally from "./pages/admin-dashboard/User/revenglobally";
import Countrywise from "./pages/admin-dashboard/User/countrywise";
import Maincategorylist from "./pages/admin-dashboard/categories/product/maincategorylist";
import BulkProductAdd from "./pages/admin-dashboard/categories/product/bulkcategoryedit";
import ProductCategoriesList from "./pages/admin-dashboard/categories/product/ProductCategoriesList";
import ProductCategoryEdit from "./pages/admin-dashboard/categories/product/ProductCategoryEdit"
import CookiePolicyPage from "./pages/admin-dashboard/cms/CookiePolicyPage";
import BlogList from "./components/CMS/Blog/BlogList";
import BlogEdit from "./components/CMS/Blog/BlogEdit";
import Seo from "./components/Seo/Seo";
import SeoList from "./components/Seo/SeoListing";
import SeoEdit from "./components/Seo/SeoEdit";



function App() {

    return (
        <>
            <Provider store={store}>
                <Global />
                <Router>
                    <Routes>
                        <Route path='/login' element={<LoginRoute><Login /></LoginRoute>} />

                        <Route path='/' exact element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path='/users' exact element={<PrivateRoute><Users /></PrivateRoute>} />
                        <Route path='/user' exact element={<PrivateRoute><AdminEdit /></PrivateRoute>} />
                        <Route path='/edit-user/:id' exact element={<PrivateRoute><EditUsers /></PrivateRoute>} />
                        <Route path='/detail/:id' exact element={<PrivateRoute><Details /></PrivateRoute>} />
                        <Route path='/blogs/blog-categories' exact element={<PrivateRoute><BlogCategoryList /></PrivateRoute>} />
                        <Route path='/blogs/blog-category/create' exact element={<PrivateRoute><BlogCategoryCreate /></PrivateRoute>} />
                        <Route path='/blogs/blog-category/edit/:id' exact element={<PrivateRoute><BlogCategoryEdit /></PrivateRoute>} />

                        <Route path='/master/brands' exact element={<PrivateRoute><BrandList /></PrivateRoute>} />
                        <Route path='/master/brand/create' exact element={<PrivateRoute><BrandCreate /></PrivateRoute>} />
                        <Route path='/master/brand/edit/:id' exact element={<PrivateRoute><BrandEdit /></PrivateRoute>} />

                        <Route path='/categories/product-categories' exact element={<PrivateRoute><ProductCategoryList /></PrivateRoute>} />
                        <Route path='/categories/product-category/create' exact element={<PrivateRoute><ProductCategoryCreate /></PrivateRoute>} />
                        <Route path='/categories/product-category/edit/:id' exact element={<PrivateRoute><ProductCategoryEdit /></PrivateRoute>} />
                        <Route path='/product/categories-listing/:id' exact element={<PrivateRoute><ProductCategoriesList /></PrivateRoute>} />
                        <Route path='/product-category-edit/:id' exact element={<PrivateRoute><ProductCategoryEdit /></PrivateRoute>} />



                        <Route path='/subcategories/product-subcategories' exact element={<PrivateRoute><ProducSubCategoryList /></PrivateRoute>} />
                        <Route path='/subcategories/product-subCategory/create' exact element={<PrivateRoute><ProductSubCategoryCreate /></PrivateRoute>} />
                        <Route path='/subcategories/product-subCategory/edit/:id' exact element={<PrivateRoute><ProductSubCategoryEdit /></PrivateRoute>} />



                        <Route path='/categories/service-categories' exact element={<PrivateRoute><ServiceCategoryList /></PrivateRoute>} />
                        <Route path='/categories/service-category/create' exact element={<PrivateRoute><ServiceCategoryCreate /></PrivateRoute>} />
                        <Route path='/categories/service-category/edit/:id' exact element={<PrivateRoute><ServiceCategoryEdit /></PrivateRoute>} />

                        <Route path='/subscription-plans' exact element={<PrivateRoute><SubscriptionPlanList /> </PrivateRoute>} />
                        <Route path='/subscription-plan/create' exact element={<PrivateRoute><SubscriptionPlanCreate /></PrivateRoute>} />
                        <Route path='/subscription-plan/edit/:id' exact element={<PrivateRoute><SubscriptionPlanEdit /></PrivateRoute>} />


                        {/* Content CMS SUBSCRIPTION */}
                        

                        <Route path='/help-support/contactus-queries' exact element={<PrivateRoute><ContactUsList /></PrivateRoute>} />
                        <Route path='/help-support/contactus-query/:id' exact element={<PrivateRoute><ContactUsView /></PrivateRoute>} />
                        <Route path='/help-support/helpdesk-queries' exact element={<PrivateRoute><HelpDeskList /></PrivateRoute>} />
                        <Route path='/help-support/helpdesk-query/:id' exact element={<PrivateRoute><HelpDeskView /></PrivateRoute>} />


                        <Route path='/verify' exact element={<PrivateRoute><Verify /></PrivateRoute>} />
                        <Route path='/details' exact element={<PrivateRoute><Details /></PrivateRoute>} />
                        <Route path='/helpcontactus' exact element={<PrivateRoute><HelpContactus /></PrivateRoute>} />
                        <Route path='/cost' exact element={<PrivateRoute><Cost /></PrivateRoute>} />
                        <Route path='/analytic' exact element={<PrivateRoute><Analytic /></PrivateRoute>} />
                        <Route path='/password' exact element={<PrivateRoute><Password /></PrivateRoute>} />
                        <Route path='/addform' exact element={<PrivateRoute><Addform /></PrivateRoute>} />

                        <Route path='/addcost' exact element={<PrivateRoute><Addcost /></PrivateRoute>} />

                        <Route path='/content' exact element={<PrivateRoute><Content /></PrivateRoute>} />
                        <Route path='/who-we-are' exact element={<PrivateRoute><WhoWeAreContent /></PrivateRoute>} />
                       
                       
                       
                        <Route path='/addposting' exact element={<PrivateRoute><Addposting /></PrivateRoute>} />
                        <Route path='/addpromotion' exact element={<PrivateRoute><Addpromotion /></PrivateRoute>} />
                        <Route path='/reviews' exact element={<PrivateRoute><Reviews /></PrivateRoute>} />
                        <Route path='/review-feedback-edit/:id' exact element={<PrivateRoute><ReviewsFeedback /></PrivateRoute>} />
                        {/* ============================== */}
                        <Route path='/about' exact element={<PrivateRoute><About /></PrivateRoute>} />
                        <Route path='/what-we-offer' exact element={<PrivateRoute><Whatweoffer /></PrivateRoute>} />
                        
                        <Route path='/choose-your-plan' exact element={<PrivateRoute><ChooseYourPlan /></PrivateRoute>} />
                        
                        <Route path='/write-your-experiance' exact element={<PrivateRoute><WriteYourExperiance /></PrivateRoute>} />

                        <Route path='/blog' exact element={<PrivateRoute><BlogPage /></PrivateRoute>} />

                        <Route path='/blog-list' exact element={<PrivateRoute><BlogList /></PrivateRoute>} />

                        <Route path='/blog-edit/:id' exact element={<PrivateRoute><BlogEdit /></PrivateRoute>} />

                        

                        <Route path='/range-of-product' exact element={<PrivateRoute><RangeOfProductPage /></PrivateRoute>} />

                        <Route path='/wish-list' exact element={<PrivateRoute><Wishlist /></PrivateRoute>} />

                        <Route path='/contact-us' exact element={<PrivateRoute><ContactUsPage /> </PrivateRoute>} />

                        <Route path='/terms-and-condition' exact element={<PrivateRoute><TermsAndConditionPage /></PrivateRoute>} />

                        <Route path='/privacy-policy' exact element={<PrivateRoute><PrivacyAndPolicyPage /></PrivateRoute>} />
                        <Route path='/cookie-policy' exact element={<PrivateRoute><CookiePolicyPage/></PrivateRoute>} />
                        

                        <Route path='/subsglobally' exact element={<PrivateRoute><Subsglobally /></PrivateRoute>} />
                        <Route path='/revenglobally' exact element={<PrivateRoute><Revenglobally /></PrivateRoute>} />
                        <Route path='/countrywise' exact element={<PrivateRoute><Countrywise /></PrivateRoute>} />
                        <Route path='/maincategorylist' exact element={<PrivateRoute><Maincategorylist /></PrivateRoute>} />
                        {/* <Route path='maincategorylist' exact element={<PrivateRoute><Maincategorylist /></PrivateRoute>} /> */}
                        <Route path='/bulk-product-add' exact element={<PrivateRoute><BulkProductAdd /></PrivateRoute>} />


                        {/* SEO Forms */}
                        <Route path="/seo-create" exact element={<PrivateRoute><Seo /></PrivateRoute>}/>
                        <Route path="/seo-list" exact element={<PrivateRoute><SeoList /></PrivateRoute>}/>
                        <Route path="/seo-edit/:id" exact element={<PrivateRoute><SeoEdit /></PrivateRoute>}/>

                        







                    </Routes>
                </Router>
            </Provider>
        </>
    );
}

export default App;
