import React, { useEffect, useState } from "react";
import InputFields from "../../Forms/InputField";
import Sidemenu from "../../../pages/include/side-manu";
import Header from "../../../pages/include/header";
import { useNavigate, useParams } from "react-router-dom";
import CmsSideBar from "../CmsSideBar";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";
import TextEditor from "../../ReactQuill/TextEditor";
import { setGlobalState } from "../../../redux/dispatch";

function BlogEdit() {
  const navigate = useNavigate();
  const params = useParams();

  const [blogTypes, setBlogTypes] = useState([]);

  const blogTypeList = async () => {
    try {
      const { success, message, data } = await ApiRequest({
        endUrl: API_END_URLS.blogTypes,
        method: "get",
      });
      if (success) {
        setBlogTypes(data?.list);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: Blog.jsx:29 ~ blogTypeList ~ err:", err);
    }
  };

  const [editBlog, setEditBlog] = useState({});

  const fatchEditBlog = async () => {
    try {
      const { data, message, success } = await ApiRequest({
        endUrl: API_END_URLS.editBlog,
        method: "get",
        params: params,
      });
      if (success) {
        setEditBlog({ ...data?.blog });
      } else {
        showMessage();
      }
    } catch (error) {
      console.log("🚀 ~ file: BlogEdit.jsx:33 ~ fatchEditBlog ~ error:", error);
    }
  };

  const [blogImageView, setBlogImageView] = useState({
    blogImage: "images/whoweare1.png",
  });

  const blogImageRender = (e) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        alert("Please upload file size must be between 200KB and 2MB");
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        let nab = { ...blogImageView };
        nab["blogImageFile"] = file;
        nab["blogImage"] = e.target.result;
        setBlogImageView(nab);
      };

      reader.readAsDataURL(e.target.files[0]);
      setBlogImageView(e.target.files[0]);

      setEditBlog({ ...editBlog, blog_image: "blogs/" + file["name"] });
    }

    return true;
  };

  const UpdateBlog = async () => {
    setGlobalState({ loader: true });
    try {
      let req = editBlog;
      const { data, message, success } = await ApiRequest({
        endUrl: API_END_URLS.updateblog,
        method: "PUT",
        body: req,
        params: params,
      });
      if (success) {
        if (blogImageView["blogImageFile"]) {
          const fileData = new FormData();
          let fileJson = {};
          fileData.append("file", blogImageView["blogImageFile"]);
          let fname = blogImageView["blogImageFile"]["name"];
          fileJson[fname] = "blogs/" + fname;
          fileData.append("jsondata", JSON.stringify(fileJson));
          const { success } = await ApiRequest({
            endUrl: API_END_URLS.fileUpload,
            method: "post",
            body: fileData,
            headerType: "file",
          });
          showMessage("Blog Upated Successfully");
          navigate("/blog-list");
        } else {
          showMessage(message);
        }
        navigate("/blog-list");
      }
    } catch (error) {
      console.log("🚀 ~ file: BlogEdit.jsx:48 ~ UpdateBlog ~ error:", error);
    } finally {
      setGlobalState({ loader: false });
    }
  };

  useEffect(() => {
    fatchEditBlog();
    blogTypeList();
  }, []);

  return (
    <React.Fragment>
      {console.log(editBlog)}
      {console.log(blogImageView)}
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding padding-rightnone">
                <div class="row">
                  <div className="col-md-3 col-8 pr-0 border-right mb-2 mb-sm-0">
                    <CmsSideBar />
                  </div>
                  <div class="col-md-9">
                    <div class="row mt-4 mt-sm-5">
                      <div class="col-md-12 col-12">
                        <div class="head-content">
                          <p className="dark-p">Blog Catagory</p>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 mt-3">
                        <select
                          class="form-control blog-select-drop"
                          value={editBlog?.blog_type}
                          onChange={(e) => {
                            setEditBlog({
                              ...editBlog,
                              blog_type: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select</option>
                          {blogTypes?.map((item, index) => {
                            return (
                              <option
                                key={index}
                                value={item?.id}
                                selected={item == editBlog?.blog_type}
                              >
                                {item?.blog_type}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div class="col-md-4 mb-sm-0 mb-3">
                        <div class="contentimg-box position-relative">
                          <img
                            src={API_END_URLS.S3Bucket + editBlog?.blog_image}
                            alt=""
                          />
                          <div class="upload-btn-wrapper new-updatebtn">
                            <button class="uploadBtn">
                              <img src="/images/nav-camera.png" alt=""></img>
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                blogImageRender(e, editBlog?.blog_image)
                              }
                            />
                          </div>
                        </div>
                        <div class="head-content mt-2">
                          <p>Image Resolution</p>
                        </div>
                        <span class="lenth-box mt-2">
                          {" "}
                          <img src="images/question.png" alt="" />
                          {/* 298 px * 298 px only */}
                          1440 px * 530 px only
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2 mt-sm-4">
                      <div className="col-md-12 col-12">
                        <div class="head-content">
                          <p className="dark-p">Blog Heading</p>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 mt-3">
                        <div className="">
                          <InputFields
                            placeholder={"Let Us Help!"}
                            className={"mt-0"}
                            value={editBlog?.blog_title}
                            onChange={(e) =>
                              setEditBlog({
                                ...editBlog,
                                blog_title: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4 mt-sm-5">
                      <div className="col-md-12 col-12">
                        <div class="form-group">
                          <b> Blog Text</b>
                          <div class="form-group mt-3">
                            <TextEditor
                              value={editBlog?.blog_description}
                              onChange={(e) =>
                                setEditBlog((prev) => ({
                                  ...prev,
                                  blog_description: e,
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div class="banner-uploadbtn mt-3 mt-sm-5">
                          <button
                            class="uploadBtn new-update-btn align-items-center d-flex"
                            onClick={() => UpdateBlog()}
                          >
                            <img
                              className="mr-1"
                              src="/images/uploadbtn-icon.svg"
                              alt="/"
                            />{" "}
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BlogEdit;
