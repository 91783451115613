import React, { useEffect, useState } from "react";
import Header from "../../include/header";
import Sidemenu from "../../include/side-manu";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import { ApiRequest } from "../../../config/apiRequest";
import { Link } from "react-router-dom";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";
import { useSelector } from "react-redux";
import { setGlobalState } from "../../../redux/dispatch";
import { Pagination } from "@mui/material";
import moment from "moment";
import { SERVER_URL } from "../../../config/contants";
import Nav from "react-bootstrap/Nav";
// import TabContainer from 'react-bootstrap/TabContainer'

function Cost() {
  const [title, setTitle] = useState("Cost Manager - Subscription Plan");

  let index = 0;
  const url = SERVER_URL + API_END_URLS.subscriptionAcceptanceExport;

  const [lgShow, setLgShow] = useState(false);
  const [state, setState] = useState({ data: { plans: [] } });
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const reduxAppState = useSelector((state) => state.app);
  const [key, setKey] = useState(reduxAppState.tabKey);
  const [noOfPages, setNoOfPages] = useState(0);

  const [subAcceptance, setSubAcceptance] = useState({});

  const [query, setQuery] = useState({
    page: 1,
    limit: 5,
  });

  const getSubscriptionPlans = async () => {
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.subscriptionPlans,
      method: "get",
      token: true,
      query: query,
    });
    if (success) {
      setState({ ...state, data: { plans: data.plans.rows } });
      setSubscriptionPlans(data.plans.rows);
      setNoOfPages(Math.ceil(data.plans.count / query.limit));
    } else {
      showMessage(message);
    }
  };

  const getSubscriptionAcceptance = async () => {
    const { data, message, success } = await ApiRequest({
      endUrl: API_END_URLS.subscriptionAcceptance,
      method: "get",
    });
    if (success) {
      setSubAcceptance({ ...data });
    } else {
      showMessage(message);
    }
  };

  useEffect(() => {
    getSubscriptionPlans();
    getSubscriptionAcceptance();
  }, [query]);

  const deleteRecord = async (id) => {
    const { data, success, message } = await ApiRequest({
      endUrl: "v1/admin/subscription-plan/" + id,
      method: "delete",
      token: true,
    });
    showMessage(message);
    const remainingSibscriptionPlans = subscriptionPlans.filter(
      (result) => result.id !== id
    );
    setSubscriptionPlans(remainingSibscriptionPlans);
  };

  const handleTabChange = (k) => {
    setKey(k);
    setGlobalState({ tabKey: k });
  };

  // let mm = Object.keys(subAcceptance[0]);
  const titleChange = (e) => {
    if (e === "profile") {
      setTitle("Advertisement");
    }
    if (e === "home") {
      setTitle("Cost Manager - Subscription Plan");
    }
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="subsription"
                  onSelect={(e) => titleChange(e)}
                >
                  <div className="row align-items-center">
                    <div className="col-md-5 col-12">
                      <div class="head-text cost-head new-cost-head mt-3 mt-sm-0">
                        <h1>{title}</h1>
                      </div>
                    </div>
                    <div className="col-md-1 col-3 px-sm-0 pr-0">
                      <div class="d-flex align-items-center">

                        <img src="/images/new-eye.png" onClick={() => setLgShow(true)} className="cloud-img mr-2" alt='' />
                        <a href={url}>
                          <img
                            src="/images/new-cloud.png"
                            className="cloud-img mr-2"
                            alt=''
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-4 p-sm-0 col-9">
                      <Nav className="user-tabs cost-tab justify-content-end pb-0">
                        <Nav.Item>
                          <Nav.Link eventKey="subsription">
                            Subscription Plan
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="advertisement">
                            Advertisement
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="col-md-2 col-8 mt-sm-0 mt-3">
                      <div className="row">
                        <div className="col-md-12 pr-0 col-9">
                          <Link to="/analytic">
                            <button
                              type="button"
                              class="btn btn-block btn-analy"
                              to="/analytic"
                            >
                              <img src="images/analy-icon.svg" alt="/" />
                              Analytics
                            </button>
                          </Link>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="divider-line"></div>
                    </div>
                    <div className="col-md-12 col-3">
                      <div className="row justify-content-end">
                        <div className="col-md-1">
                          <Link to="/addcost">
                            <button
                              type="button"
                              class="btn btn-block btn-analy"
                              to="/addcost"
                            >
                              {" "}
                              <img
                                className="mr-0"
                                src="images/plus-icon.svg"
                                alt="/"
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-12 mt-2">
                      <Tab.Content>
                        <Tab.Pane eventKey="subsription">
                          <div class="row mt-0 mt-lg-2">
                            <div class="col-md-12">
                              <div class="table-responsive">
                                <Table className="manage-table table">
                                  <thead>
                                    <tr>
                                      <td class="head-td">Serial Number</td>

                                      {/* <td class="head-td" >Number Of Free Post </td> */}
                                      <td class="head-td">Period</td>
                                      {/* <td class="head-td" >Subscription Type</td> */}
                                      {/* <td class="head-td" >Push Notifications Allowed</td> */}
                                      <td class="head-td">Plan Status</td>
                                      <td class="head-td">Created On</td>
                                      <td class="head-td">Days</td>
                                      <td class="head-td">Plan Cost(AED)</td>
                                      <td class="head-td">Action</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {subscriptionPlans.map((item, key) => {
                                      return (
                                        <tr>
                                          <td>{item.id}</td>
                                          {/* <td>{item.number_of_free_post}</td> */}
                                          <td>{item.period}</td>
                                          {/* <td><span className={"badge " + (item.subscription_type == 'premium' ? 'standard-btn' : 'premium-btn')}>
                                                                                        {item.subscription_type}
                                                                                    </span></td> */}
                                          {/* <td>{item.push_notifications_allowed}</td> */}
                                          <td>
                                            <span
                                              className={
                                                "badge " +
                                                (item.status == "Active"
                                                  ? "standard-btn"
                                                  : "premium-btn")
                                              }
                                            >
                                              {item.status}
                                            </span>
                                          </td>
                                          <td>
                                            {moment(item.createdAt).format(
                                              "MMM DD YYYY HH:mm"
                                            )}
                                          </td>
                                          <td>{item.days}</td>
                                          <td>{item.plan_cost}</td>
                                          <td>
                                            <Link to={`/addform?id=${item.id}`}>
                                              <button
                                                type="button"
                                                class="btn btn-edit"
                                              >
                                                Edit
                                              </button>
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                              <div class="row justify-content-end">
                                <div class="text-right mr-3">
                                  {noOfPages ? (
                                    <Pagination
                                      count={noOfPages}
                                      defaultPage={1}
                                      siblingCount={2}
                                      boundaryCount={2}
                                      variant="outlined"
                                      shape="rounded"
                                      color="success"
                                      onChange={(e, page) => {
                                        setQuery({ ...query, page: page });
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="advertisement">
                          <div class="price-structure">
                            <h1 class="ad-head-center text-center">
                              Price Structure
                            </h1>
                            <div class="row justify-content-end pt-4 pt-sm-5">
                              <div class="col-md-4 col-6">
                                <div class="ad-sub-head d-flex align-items-center">
                                  <img
                                    src="images/standard-plan-icon.png"
                                    class="mr-2"
                                    alt="/"
                                  />
                                  <h4>Standard Plan</h4>
                                </div>
                              </div>
                              <div class="col-md-4 col-6">
                                <div class="ad-sub-head d-flex align-items-center">
                                  <img
                                    src="images/premium-plan-icon.png"
                                    class="mr-2"
                                    alt="/"
                                  />
                                  <h4>Premium Plan</h4>
                                </div>
                              </div>
                            </div>
                            <div class="video-plans ">
                              <div class="row align-items-center">
                                <div class="col-md-3 col-12">
                                  <h1>Video ADs ( =&lt; 30 sec )</h1>
                                </div>
                                <div class="col-md-9">
                                  <div class="row justify-content-center align-items-center">
                                    <div class="col-md-6 col-6">
                                      <div class="ad-month-plans">
                                        <h2 class="mb-3 mb-sm-5">
                                          <span>4$</span> - Monthly Plan
                                        </h2>
                                        <h2>
                                          <span>4$</span> - Yearly Plan
                                        </h2>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-6">
                                      <div class="ad-month-plans">
                                        <h2 class="mb-3 mb-sm-5">
                                          <span>3.60$</span> - Monthly Plan
                                        </h2>
                                        <h2>
                                          <span>3.60$</span> - Yearly Plan
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="video-plans ">
                              <div class="row align-items-center">
                                <div class="col-md-3 col-12">
                                  <h1>Video ADs ( 31-60 sec)</h1>
                                </div>
                                <div class="col-md-9">
                                  <div class="row justify-content-center align-items-center">
                                    <div class="col-md-6 col-6">
                                      <div class="ad-month-plans">
                                        <h2 class="mb-3 mb-sm-5">
                                          <span>5$</span> - Monthly Plan
                                        </h2>
                                        <h2>
                                          <span>5$</span> - Yearly Plan
                                        </h2>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-6">
                                      <div class="ad-month-plans">
                                        <h2 class="mb-3 mb-sm-5">
                                          <span>4.50$</span> - Monthly Plan
                                        </h2>
                                        <h2>
                                          <span>4.50$</span> - Yearly Plan
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="video-plans ">
                              <div class="row align-items-center">
                                <div class="col-md-3 col-12">
                                  <h1>Banner ADs ( &lt;=2 MB )</h1>
                                </div>
                                <div class="col-md-9">
                                  <div class="row justify-content-center align-items-center">
                                    <div class="col-md-6 col-6">
                                      <div class="ad-month-plans">
                                        <h2 class="mb-3 mb-sm-5">
                                          <span>3$</span> - Monthly Plan
                                        </h2>
                                        <h2>
                                          <span>3$</span> - Yearly Plan
                                        </h2>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-6">
                                      <div class="ad-month-plans">
                                        <h2 class="mb-3 mb-sm-5">
                                          <span>2.70$</span> - Monthly Plan
                                        </h2>
                                        <h2>
                                          <span>2.70$</span> - Yearly Plan
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
                className="new-model"
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton >
                    <h1 className="new-pophead">Subscription Acceptance Page </h1>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <div className="overscroll-datatable pr-1">
                        <div className="table-responsive" >
                            <Table bordered className="new-tableyes" >
                                <tbody>
                                    <tr>
                                        <th className="text-center" >S.No.</th>
                                        <th className="text-center" >Description</th>
                                        <th className="text-center" colSpan={2} >Standard</th>
                                        <th className="text-center" colSpan={2} >Premium</th>
                                    </tr>
                                    {
                                        Object.entries(subAcceptance).map((list) => {
                                            if (!["id"].includes(list[0])) {
                                                index = index + 1;
                                                return <tr>
                                                    <td>{index}</td>
                                                    <td>{list[1][0]?.description}</td>
                                                    <td className="text-center">{list[1][0]?.standard_1}</td>
                                                    <td className="text-center">{list[1][0]?.standard_2}</td>
                                                    <td className="text-center">{list[1][0]?.premium_one}</td>
                                                    <td className="text-center">{list[1][0]?.premium_2}</td>
                                                </tr>
                                            } else {
                                                <></>
                                            }
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </Modal.Body>
            </Modal> */}
    </>
  );
}

export default Cost;
