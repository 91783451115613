import React, { useEffect, useState } from "react";
import { API_END_URLS } from "../../../config/endUrls";
import { ApiRequest } from "../../../config/apiRequest";
import { setGlobalState } from "../../../redux/dispatch";
import { showMessage } from "../../../helpers/toast";

function RangeOfProduct() {
  const [banner, setBanner] = useState({
    rangeOfProductImageUrl: null,
  });

  const saveImage = (e, key) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        alert("Please upload file size must be between 200KB and 2MB");
        return false;
      }
    }
    UploadImage(file, key);
    return true;
  };

  const UploadImage = async (file, key) => {
    const fileData = new FormData();
    let fileJson = {};
    fileData.append("file", file);
    let fname = file["name"];
    fileJson[fname] = "CMSHomeImage/" + fname;
    fileData.append("jsondata", JSON.stringify(fileJson));
    const { data, success } = await ApiRequest({
      endUrl: API_END_URLS.fileUpload,
      method: "post",
      body: fileData,
      headerType: "file",
    });
    setBanner({ ...banner, [key]: data });

    console.log(
      "🚀 ~ file: HomePage.jsx:77 ~ handleUpdate ~ success:",
      success,
      data
    );
  };

  const handleUpdate = async (key) => {
    const PAYLOAD = {
      module_name: "range_of_product",
      section_name: key,
      content_value: banner[key],
    };
    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };



  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setBanner(data2);

      if (success) {
        console.log(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);




  return (
    <>
      <div className="col-md-9 p-sm-0 ">
        <div class="right-contentbox">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="head-content">
                <p>Range of Product - Banner</p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    banner?.rangeOfProductImageUrl != null
                      ? banner?.rangeOfProductImageUrl
                      : "/images/blog-banner.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="/images/nav-camera.png" alt=""></img>
                  </button>
                  <input
                    name="rangeOfProductImageUrl"
                    type="file"
                    onChange={(e) => saveImage(e, "rangeOfProductImageUrl")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-4">
              <div class="head-content">
                <p>Image Resolution </p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                1440 px * 365 px only{" "}
              </span>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("rangeOfProductImageUrl")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RangeOfProduct;
