import React, { useEffect, useState } from "react";
import TextEditor from "../../ReactQuill/TextEditor";
import { API_END_URLS } from "../../../config/endUrls";
import { ApiRequest } from "../../../config/apiRequest";
import { setGlobalState } from "../../../redux/dispatch";
import { showMessage } from "../../../helpers/toast";

function WhatWeOffer() {
  const [whatWeOfferData, setWhatWeOfferData] = useState({
    bannerImageOneUrl: null,
    sectionHeadingDisplayProductOrService: null,
    sectionHeadingDisplayProductOrServiceText: null,
    bannerImageTwoUrl: null,
    sectionHeadingPhotosAndPicturesOfTheProduct: null,
    sectionHeadingPhotosAndPicturesOfTheProductText: null,
    bannerImageThreeUrl: null,
    bannerImageFourUrl: null,
    sectionHeadingAdvertisementsBannersOrVideos: null,
    sectionHeadingAdvertisementsBannersOrVideosText: null,
    sectionHeadingPromotionNotifications: null,
    sectionHeadingPromotionNotificationsText: null,
    sectionHeadingVoiceTextMessaging: null,
    sectionHeadingVoiceTextMessagingText: null,
    sectionHeadingVideoChatting: null,
    sectionHeadingVideoChattingText: null,
    sectionHeadingPersonalizedDashboard: null,
    sectionHeadingPersonalizedDashboardText: null,
  });

  const saveImage = (e, key, module) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        alert("Please upload file size must be between 200KB and 2MB");
        return false;
      }
    }
    UploadImage(file, key, module);
    return true;
  };

  const hanldeChange = (value, key) => {
    setWhatWeOfferData((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    console.log(whatWeOfferData);
  }, [whatWeOfferData]);

  const UploadImage = async (file, key, module) => {
    const fileData = new FormData();
    let fileJson = {};
    fileData.append("file", file);
    let fname = file["name"];
    fileJson[fname] = "CMSHomeImage/" + fname;
    fileData.append("jsondata", JSON.stringify(fileJson));
    const { data, success } = await ApiRequest({
      endUrl: API_END_URLS.fileUpload,
      method: "post",
      body: fileData,
      headerType: "file",
    });

    setWhatWeOfferData({ ...whatWeOfferData, [key]: data });
    console.log(
      "🚀 ~ file: HomePage.jsx:77 ~ handleUpdate ~ success:",
      success,
      data
    );
  };

  const handleUpdate = async (keys) => {
    let data = [];
    keys.map((x) => {
      let data2 = { key: x, value: whatWeOfferData[x] };
      data.push(data2);
    });
    const PAYLOAD = {
      data,
    };
    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createWhatWeAre,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage("Data updated Successfully");
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };

  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getDataWhatWeAre,
        method: "get",
      });
      let ekta = {};
      data.map((x) => {
        ekta[x?.key] = x?.value;
      });
      setWhatWeOfferData(ekta);
      if (success) {
        console.log(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {console.log("ghdgsahd", whatWeOfferData)}
      <div class="col-md-9 p-sm-0">
        <div class="right-contentbox">
          <div class="row mt-3 mt-sm-5">
            <div class="col-md-5">
              <div class="contentimg-box position-relative">
                <img
                  src={
                    whatWeOfferData?.bannerImageOneUrl != null
                      ? whatWeOfferData?.bannerImageOneUrl
                      : "images/whatoffer-img.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper edit-upload  newedit-upload">
                  <button class="uploadBtn edit-userallow">
                    <img src="images/nav-camera.png" alt="" />
                  </button>
                  <input
                    name="bannerImageOneUrl"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      saveImage(e, "bannerImageOneUrl");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-4">
              <div class="head-content ">
                <p>Image Resolution </p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                685px * 550 px only{" "}
              </span>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate(["bannerImageOneUrl"])}
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="head-content">
                <p>Section 1 Heading</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "22",
                  }}
                >
                  DISPLAY PRODUCT OR SERVICE
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  value={whatWeOfferData?.sectionHeadingDisplayProductOrService}
                  name="sectionHeadingDisplayProductOrService"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingDisplayProductOrService")
                  }
                />
              </div>
            </div>
          </div>

          <div className="row mt-sm-4">
            <div className="col-md-12">
              <div className="head-content">
                <p>Text</p>
                <TextEditor
                  value={
                    whatWeOfferData?.sectionHeadingDisplayProductOrServiceText
                  }
                  name="sectionHeadingDisplayProductOrServiceText"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingDisplayProductOrServiceText")
                  }
                />
              </div>
            </div>
          </div>

          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                  }}
                >
                  Display any product or part or service that you wish to sell
                  or lease for website and app visitors and other subscribers to
                  view through our website and mobile app. Products may be any
                  which is used in Marine, Air and Land based applications
                </h3>
              </div>
            </div>
          </div>
          <div class="banner-uploadbtn mt-3">
            <button
              class="uploadBtn"
              onClick={() =>
                handleUpdate([
                  "sectionHeadingDisplayProductOrService",
                  "sectionHeadingDisplayProductOrServiceText",
                ])
              }
            >
              Update
            </button>
          </div>

          <div class="row mt-5 mt-sm-5">
            <div class="col-md-5">
              <div class="contentimg-box position-relative">
                <img
                  src={
                    whatWeOfferData?.bannerImageTwoUrl != null
                      ? whatWeOfferData?.bannerImageTwoUrl
                      : "images/whatoffer-img.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper edit-upload newedit-upload">
                  <button class="uploadBtn edit-userallow">
                    <img src="images/nav-camera.png" alt="" />
                  </button>
                  <input
                    name="bannerImageTwoUrl"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      saveImage(e, "bannerImageTwoUrl");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-4">
              <div class="head-content">
                <p>Image Resolution </p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                440px * 365 px only{" "}
              </span>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate(["bannerImageTwoUrl"])}
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="head-content">
                <p>Section 2 Heading</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "22",
                  }}
                >
                  Photos and Pictures of the Product / Services
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingPhotosAndPicturesOfTheProduct"
                  onChange={(e) =>
                    hanldeChange(
                      e,
                      "sectionHeadingPhotosAndPicturesOfTheProduct"
                    )
                  }
                  value={
                    whatWeOfferData?.sectionHeadingPhotosAndPicturesOfTheProduct
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mt-sm-4">
            <div className="col-md-12">
              <div className="head-content">
                <p>Text</p>
                <TextEditor
                  name="sectionHeadingPhotosAndPicturesOfTheProductText"
                  onChange={(e) =>
                    hanldeChange(
                      e,
                      "sectionHeadingPhotosAndPicturesOfTheProductText"
                    )
                  }
                  value={
                    whatWeOfferData?.sectionHeadingPhotosAndPicturesOfTheProductText
                  }
                />
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    // fontWeight: "700",
                    // fontSize: "22",
                  }}
                >
                  For each product displayed; the subscriber is eligible to add
                  additional photographs, and technical specifications ,manuals
                  and documents.
                </h3>
              </div>
            </div>
          </div>
          <div class="banner-uploadbtn mt-3">
            <button
              class="uploadBtn"
              onClick={() =>
                handleUpdate([
                  "sectionHeadingPhotosAndPicturesOfTheProduct",
                  "sectionHeadingPhotosAndPicturesOfTheProductText",
                ])
              }
            >
              Update
            </button>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-6 col-6">
              <div className="row">
                <div className="col-md-6">
                  <div class="contentimg-box position-relative">
                    <img
                      src={
                        whatWeOfferData?.bannerImageThreeUrl != null
                          ? whatWeOfferData?.bannerImageThreeUrl
                          : "images/whatoffer-img.png"
                      }
                      alt=""
                    />
                    <div class="upload-btn-wrapper edit-upload  newedit-uploadbtn">
                      <button class="uploadBtn edit-userallow">
                        <img src="/images/nav-camera.png" alt="/" />
                      </button>
                      <input
                        name=" whatWeOfferData?.bannerImageThreeUrl"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          saveImage(e, "bannerImageThreeUrl");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <span class="lenth-box align-items-center mt-3">
                    <img src="images/question.png" alt="/" />
                    300px * 445 px only
                  </span>

                  <div class="upload-btn-wrapper mt-3">
                    <button
                      class="uploadBtn"
                      onClick={() => handleUpdate(["bannerImageThreeUrl"])}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-6">
              <div className="row">
                <div className="col-md-6">
                  <div class="contentimg-box position-relative">
                    <img
                      src={
                        whatWeOfferData?.bannerImageFourUrl !== null
                          ? whatWeOfferData?.bannerImageFourUrl
                          : "images/whatoffer-img.png"
                      }
                      alt=""
                    />
                    <div class="upload-btn-wrapper edit-upload  newedit-uploadbtn">
                      <button class="uploadBtn edit-userallow">
                        <img src="/images/nav-camera.png" alt="/" />
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          saveImage(e, "bannerImageFourUrl");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <span class="lenth-box align-items-center mt-3">
                    <img src="images/question.png" alt="/" />
                    300px * 445 px only
                  </span>

                  <div class="upload-btn-wrapper mt-3">
                    <button
                      class="uploadBtn"
                      onClick={() => handleUpdate(["bannerImageFourUrl"])}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="head-content">
                <p>Section 3 (i) Heading</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "22",
                  }}
                >
                  Advertisements Banners or Videos
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingAdvertisementsBannersOrVideos"
                  onChange={(e) =>
                    hanldeChange(
                      e,
                      "sectionHeadingAdvertisementsBannersOrVideos"
                    )
                  }
                  value={
                    whatWeOfferData?.sectionHeadingAdvertisementsBannersOrVideos
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mt-sm-4">
            <div className="col-md-12">
              <div className="head-content">
                <p>Text</p>
                <TextEditor
                  name="sectionHeadingAdvertisementsBannersOrVideosText"
                  onChange={(e) =>
                    hanldeChange(
                      e,
                      "sectionHeadingAdvertisementsBannersOrVideosText"
                    )
                  }
                  value={
                    whatWeOfferData?.sectionHeadingAdvertisementsBannersOrVideosText
                  }
                />
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    // fontWeight: "700",
                    // fontSize: "22",
                  }}
                >
                  The subscriber can also post special marketing videos and
                  banners that will be visible for all the website and app
                  visitors and other subscribers
                </h3>
              </div>
            </div>
          </div>

          <div class="banner-uploadbtn mt-3">
            <button
              class="uploadBtn"
              onClick={() =>
                handleUpdate([
                  "sectionHeadingAdvertisementsBannersOrVideos",
                  "sectionHeadingAdvertisementsBannersOrVideosText",
                ])
              }
            >
              Update
            </button>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="head-content">
                <p>Section 3 (ii) Heading</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "22",
                  }}
                >
                  Promotion Notifications
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingPromotionNotifications"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingPromotionNotifications")
                  }
                  value={whatWeOfferData?.sectionHeadingPromotionNotifications}
                />
              </div>
            </div>
          </div>

          <p>Text</p>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    // fontWeight: "700",
                    // fontSize: "22",
                  }}
                >
                  For any posted product the subscriber can add a promotion
                  notification to increase the attention of the website and app
                  visitors and other subscribers
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingPromotionNotificationsText"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingPromotionNotificationsText")
                  }
                  value={
                    whatWeOfferData?.sectionHeadingPromotionNotificationsText
                  }
                />
              </div>
              <div class="banner-uploadbtn">
                <button
                  class="uploadBtn"
                  onClick={() =>
                    handleUpdate([
                      "sectionHeadingPromotionNotifications",
                      "sectionHeadingPromotionNotificationsText",
                    ])
                  }
                >
                  Update{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="head-content">
                <p>Section 4 (i) Heading</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "22",
                  }}
                >
                  VOICE, TEXT MESSAGING
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingVoiceTextMessaging"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingVoiceTextMessaging")
                  }
                  value={whatWeOfferData?.sectionHeadingVoiceTextMessaging}
                />
              </div>
            </div>
          </div>
          <p>Text</p>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    // fontWeight: "700",
                    // fontSize: "22",
                  }}
                >
                  For any posted product the subscriber can add a promotion
                  notification to increase the attention of the website and app
                  visitors and other subscribers
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingVoiceTextMessagingText"
                  value={whatWeOfferData?.sectionHeadingVoiceTextMessagingText}
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingVoiceTextMessagingText")
                  }
                />
              </div>
              <div class="banner-uploadbtn">
                <button
                  class="uploadBtn"
                  onClick={() =>
                    handleUpdate([
                      "sectionHeadingVoiceTextMessaging",
                      "sectionHeadingVoiceTextMessagingText",
                    ])
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="head-content">
                <p>Section 4 (ii) Heading</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "22",
                  }}
                >
                  VIDEO CHATTING
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingVideoChatting"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingVideoChatting")
                  }
                  value={whatWeOfferData?.sectionHeadingVideoChatting}
                />
              </div>
            </div>
          </div>
          <p>Text</p>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    // fontWeight: "700",
                    // fontSize: "22",
                  }}
                >
                  For any posted product the subscriber can add a promotion
                  notification to increase the attention of the website and app
                  visitors and other subscribers
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingVideoChattingText"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingVideoChattingText")
                  }
                  value={whatWeOfferData?.sectionHeadingVideoChattingText}
                />
              </div>
              <div class="banner-uploadbtn">
                <button
                  class="uploadBtn"
                  onClick={() =>
                    handleUpdate([
                      "sectionHeadingVideoChatting",
                      "sectionHeadingVideoChattingText",
                    ])
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="head-content">
                <p>Section 4 (iii) Heading</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    fontSize: "22",
                  }}
                >
                  PERSONALIZED DASHBOARD
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingPersonalizedDashboard"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingPersonalizedDashboard")
                  }
                  value={whatWeOfferData?.sectionHeadingPersonalizedDashboard}
                />
              </div>
            </div>
          </div>

          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3
                  className="text-left"
                  style={{
                    color: "#000000",
                    // fontWeight: "700",
                    // fontSize: "22",
                  }}
                >
                  For any posted product the subscriber can add a promotion
                  notification to increase the attention of the website and app
                  visitors and other subscribers
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="sectionHeadingPersonalizedDashboardText"
                  onChange={(e) =>
                    hanldeChange(e, "sectionHeadingPersonalizedDashboardText")
                  }
                  value={
                    whatWeOfferData?.sectionHeadingPersonalizedDashboardText
                  }
                />
              </div>
              <div class="banner-uploadbtn">
                <button
                  class="uploadBtn"
                  onClick={() =>
                    handleUpdate([
                      "sectionHeadingPersonalizedDashboard",
                      "sectionHeadingPersonalizedDashboardText",
                    ])
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatWeOffer;
