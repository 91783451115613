import React from 'react';
import Header from '../../../include/header';
import Sidemenu from '../../../include/side-manu';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import { Modal } from 'react-bootstrap';


// import { Link, useParams } from "react-router-dom";
// import { API_END_URLS } from '../../../../config/endUrls';
// import { ApiRequest } from '../../../../config/apiRequest';
// import { showMessage } from '../../../../helpers/toast';
import { Pagination } from '@mui/material';


function Maincategorylist() {


    // const [state, setState] = useState({ data: { "product_categories": [] } });
    // const [productCategories, setProductCategories] = useState([]);
    // const [noOfPages, setNoOfPages] = useState(0);

    // const [query, setQuery] = useState({
    //     page: 1,
    //     limit: 10,
    // });

    // useEffect(() => {
    //     const getProductCategories = async () => {
    //         const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productCategories, method: "get", token: true, query: query })
    //         setState({ ...state, data: { "product_categories": data.product_categories.rows } });
    //         setProductCategories(data.product_categories.rows);
    //         setNoOfPages(Math.ceil(data.product_categories.count / query.limit));
    //     }

    //     getProductCategories();
    // }, [query])

    // const deleteRecord = async (id) => {
    //     let permision = window.confirm('Are you sure you want to delete the category')
    //     if (!permision) {
    //         return;
    //     }
    //     const { data, success, message } = await ApiRequest({ endUrl: 'v1/admin/product-category/' + id, method: "delete", token: true })
    //     showMessage(message);
    //     const remainingProductCategories = productCategories.filter((result) => result.id !== id)
    //     setProductCategories(remainingProductCategories);
    // }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 p-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9 p-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>
                            <div class="pageinnner-padding">
                                <div className='row align-items-center mt-sm-0 mt-3'>
                                    <div className='col-md-9 col-12'>
                                        <div class="head-text new-cost-head">
                                            <h1>Category Manager</h1>
                                        </div>
                                    </div>
                                    <div className='col-md-3 col-12'>
                                        <Link to="/bulk-product-add">
                                            <button 
                                            type="button" 
                                            class="btn btn-block btn-analy btn-analy-2"> 
                                            <img src="/images/plus-btn.svg" alt="/" />
                                            ADD SUB CATEGORY
                                            </button>
                                        </Link>
                                    </div>
                                    <div class="col-md-12"><div class="divider-line"></div></div>
                                </div>
                                {/* <div class="row justify-content-end">
                                    <div class="new-seller">
                                        <ul>
                                            <li>
                                                <Link to="/categories/product-categories">
                                                    <button type="button" class="btn active">Product</button>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/categories/service-categories">
                                                    <button type="button" class="btn ">Service</button>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}

                                <div class="row">
                                    <div class="col-md-12">

                                        {/* <div class="text-right mt-3">
                                            <Link to="/categories/product-category/create">  <button type="button" class="btn subscription-btn">Add Category</button> </Link>
                                        </div> */}
                                        {/* <div class="text-right mt-3">
                                            <Link to="/subcategories/produc-subCategory/create">  <button type="button" class="btn subscription-btn">Sub Category</button> </Link>
                                        </div>  */}

                                        <div class="row mt-2">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <Table className="manage-table table">
                                                        <thead>
                                                            <tr>
                                                                <td class="head-td" >ID</td>
                                                                {/* <td class="head-td" >Name</td> */}
                                                                {/* <td class="head-td" >Alias</td> */}
                                                                <td class="head-td" >Category</td>
                                                                <td class="head-td">Product </td>
                                                                {/* <td class="head-td" >Total product</td> */}
                                                                <td class="head-td" >Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>#3628</td>
                                                                <td>Air Navigator</td>
                                                                <td>36 Product</td>
                                                                {/* <td>36 Product</td> */}
                                                                <td><Link to="/categories/product-categories">
                                                                    <button className='btn btn-edit'>View</button> </Link></td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Maincategorylist;