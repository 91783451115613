import React, { useState, useEffect } from "react";
import Header from "../../include/header";
import Sidemenu from "../../include/side-manu";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { ApiRequest } from "../../../config/apiRequest";
import { Pagination } from "@mui/material";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { Link } from "react-router-dom";
import { API_END_URLS } from "../../../config/endUrls";
import moment from "moment";

import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import { showMessage } from "../../../helpers/toast";
import { SubScriptionStatus, period } from "../../../config/contants";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const currentYear = parseInt(moment().year());

function Users() {
  const [chartdata, setChartData] =
    useState(null); /* Data ko jaan buzh kr maine "null " kra, */
  /* Because phele null rahega , jab tak API reponse nhi aataaa */

  const [lgShow, setLgShow] = useState(-1);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [sh, setSh] = useState(false);
  const handleClo = () => setSh(false);
  const handleSh = () => setSh(true);

  const [data, setData] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const fetchUsers = async () => {
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.users,
      method: "get",
      query,
    });
    console.log("🚀 ~ file: users.js:56 ~ fetchUsers ~ data:", data);
    if (success) {
      setData(data?.userSubscritions?.rows);
      setNoOfPages(Math.ceil(data?.userSubscritions?.count / query.limit));
      console.log(
        Math.ceil(data?.userSubscritions?.count / query.limit),
        "page"
      );
    } else {
      showMessage(message);
    }
  };

  const handleTextChange = (e) => {
    setQuery((prev) => ({ ...prev, searchText: e.target.value }));
  };

  useEffect(() => {
    fetchUsers("");
  }, [query]);

  const subscriptionCss = (SubscriptionStatus) => {
    if (SubscriptionStatus === SubScriptionStatus.standard) {
      return "standard-btn";
    } else if (SubscriptionStatus === SubScriptionStatus.premium) {
      return "permium-btn";
    }
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 d-none d-sm-block p-0">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div className="row align-items-center justify-content-end">
                  <div className="col-md-7 col-12">
                    <div class="head-text new-cost-head">
                      <h1>Users Globally</h1>
                    </div>
                  </div>
                  <div className="col-md-4 col-9 pr-0">
                    <div class="form-group m-0">
                      <input
                        type="text"
                        class="form-control serch-input"
                        placeholder="Search "
                        onChange={handleTextChange}
                      />
                      <img
                        src="images/search-img.png"
                        class="search-img"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-1 text-right col-3">
                    <Link to="/">
                      <button type="button" class="btn btn-analy btn-block">
                        {" "}
                        <img
                          className="mr-0"
                          src="images/back-arrow.svg"
                          alt="/"
                        />
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="border-bottom pb-2"></div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <Table className="table manage-table">
                            <thead>
                              <tr>
                                <td class="head-td">Name</td>
                                <td class="head-td">Email</td>
                                <td class="head-td">Subscription</td>
                                <td class="head-td">Country</td>
                                <td class="head-td">Start Date</td>
                                <td class="head-td">Expiry Date</td>
                                <td class="head-td">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item, key) => {
                                return (
                                  <tr>
                                    <td>{item?.User?.name}</td>
                                    <td>{item?.User?.email}</td>

                                    <td>
                                      <span
                                        className={`badge ${subscriptionCss(
                                          item?.SubscriptionAcceptance
                                            ?.subscription_type
                                        )}`}
                                      >
                                        {item?.SubscriptionAcceptance
                                          ?.subscription_type +
                                          "-" +
                                          period[
                                            item?.SubscriptionAcceptance?.period
                                          ]}
                                      </span>
                                    </td>
                                    <td>{item?.User?.country}</td>
                                    <td>
                                      {moment(item.start_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(item.end_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td className="d-flex align-items-center">
                                      {/* <img src="images/eyes-icon.svg" className="new-eyestext" alt='' /> */}
                                      <button
                                        onClick={() => setLgShow(key)}
                                        className="btn btn-edit"
                                      >
                                        View
                                      </button>
                                      <DropdownButton className="new-drop">
                                        <Dropdown.Item>
                                          <Link
                                            to={`/edit-user/${item?.User?.id}`}
                                          >
                                            Edit User
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Send Notification
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                          Block User
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-4">
                                          Send E-mail
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-5">
                                          Send Message{" "}
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-6">
                                          Chat with User
                                        </Dropdown.Item>
                                      </DropdownButton>
                                    </td>
                                  </tr>
                                );
                              })}
                              {/* {
                                                                data.length === 0 && <p>Data Not Found</p>
                                                            } */}
                            </tbody>
                          </Table>
                        </div>
                        <div className="row justify-content-end">
                          <div className="text-right mr-3">
                            {noOfPages ? (
                              <Pagination
                                count={noOfPages}
                                defaultPage={1}
                                siblingCount={2}
                                boundaryCount={2}
                                variant="outlined"
                                shape="rounded"
                                color="success"
                                onChange={(e, page) => {
                                  setQuery({ ...query, page: page });
                                }}
                              />
                            ) : (
                              " "
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {lgShow !== -1 && (
        <Modal
          className="new-model"
          size="lg"
          show={lgShow === -1 ? false : true}
          onHide={() => setLgShow(-1)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <h1 className="new-pophead">User - Details</h1>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <Table className="pop-table">
                <tbody>
                  <tr>
                    <td>User Name</td>
                    <td>{data[lgShow]?.User?.name}</td>
                    <td>User Email Id</td>
                    <td>{data[lgShow]?.User?.email}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{data[lgShow]?.User?.country}</td>
                    <td>Subscription Type</td>
                    <td>
                      {data[lgShow]?.SubscriptionPlan?.subscription_type}{" "}
                      {data[lgShow]?.SubscriptionAcceptance?.period}
                    </td>
                  </tr>
                  <tr>
                    <td>Subscription Start Date</td>
                    <td>
                      {moment(data[lgShow]?.start_date).format(
                        "DD-MM-YYYY  hh:mm A "
                      )}
                    </td>
                    <td>Subscription End Date</td>
                    <td>
                      {moment(data[lgShow]?.end_date).format(
                        "DD-MM-YYYY  hh:mm A"
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* <Modal show={show} onHide={handleClose} className="filter-popup modal-dialog-slideout">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div class="pop-head">
                            <h1>Filters</h1>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Example select</label>
                                <select class="form-control" id="exampleFormControlSelect1">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> */}

      <Modal
        className="new-model chart-pop-1"
        show={sh}
        onHide={handleClo}
        size="lg"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="row text-center justify-content-center">
            <div className="col-md-11">
              <div class="revenue-chart-box">
                <div class="total-revenue d-flex align-items-center justify-content-between">
                  <div class="head-text">
                    <h1>Total Users (Numbers)</h1>
                  </div>

                  <div class="y-drops">
                    <div class="form-group">
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        // onChange={handleChange}
                      >
                        <option value={currentYear}>{currentYear}</option>
                        <option value={currentYear - 1}>
                          {currentYear - 1}
                        </option>
                        <option value={currentYear - 2}>
                          {currentYear - 2}
                        </option>
                      </select>
                    </div>
                    {/* <div class="export-img ml-4">
                                            <a href="#"><img onClick={handleSh} src="images/export-icon.png" alt="/" /></a>
                                        </div> */}
                  </div>
                </div>
                <div class="revenue-chart">
                  <div className="row">
                    <div className="col-md-12">
                      <Line height="200px" data={chartdata}>
                        Hello
                      </Line>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Users;
