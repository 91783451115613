import React, { useEffect, useState } from 'react';
import Header from '../../../include/header';
import Sidemenu from '../../../include/side-manu';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal } from 'react-bootstrap';


import { Link, useParams } from "react-router-dom";
import { API_END_URLS } from '../../../../config/endUrls';
import { ApiRequest } from '../../../../config/apiRequest';
import { showMessage } from '../../../../helpers/toast';
import { Pagination } from '@mui/material';


function SubCategoryList() {

    const [productSubCategory, setProductSubCategory] = useState([])
    const [noOfPages, setNoOfPages] = useState(0);

    const [query, setQuery] = useState({
        page: 1,
        limit: 5,
    });


    const fatchProductSubCategory = async () => {
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productSubCategories, method: "get", token: true })
        if (success) {
            setProductSubCategory(data.product_subcategories);
            setNoOfPages(Math.ceil(data.product_subcategories.count / query.limit));
        } else {
            showMessage(message);
        }
    }

    useEffect(() => {
        fatchProductSubCategory()
    }, [query])


    const deleteRecord = async (id) => {
        let permision = window.confirm('Are you sure you want to delete the Sub Category')
        if (!permision) {
            return;
        }
        const { data, success, message } = await ApiRequest({ endUrl: 'v1/admin/product-subCategory/' + id, method: "delete", token: true })
        if (success) {
            showMessage(message);
            const remainingProductCategories = productSubCategory.filter((result) => result.id !== id)
            setProductSubCategory(remainingProductCategories);
        } else {
            showMessage(message);
        }
    }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div class="head-text user-headtext">
                                    <h1>Manage Sub Categories</h1>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-right mt-3">
                                            <Link to="/subcategories/product-subCategory/create">  <button type="button" class="btn subscription-btn"> Add Sub Category</button> </Link>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <Table className="buy-table">
                                                        <thead>
                                                            <tr>
                                                                <td colSpan={2} class="head-td" >Serial Number</td>
                                                                <td class="head-td" >Category Name</td>
                                                                <td class="head-td" >Sub Category Name</td>
                                                                <td class="head-td" >Status</td>
                                                                <td class="head-td" >Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {productSubCategory.map((item, key) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <tr>
                                                                            <td colSpan={2}>{((query.page - 1) * query.limit) + key + 1}</td>
                                                                            <td>{item?.ProductCategory?.name}</td>
                                                                            <td>{item.name}</td>
                                                                            <td><span className={"badge " + (item.status === "Active" ? 'standard-btn' : 'premium-btn')}>{item.status}</span></td>
                                                                            <td>
                                                                                <Link to={`/subcategories/product-subCategory/edit/${item.id}`}><button type="button" class="btn details-btn">Edit</button></Link>
                                                                                <button type="button" class="btn details-btn ml-1" onClick={() => {
                                                                                    deleteRecord(item.id)
                                                                                }}>Delete</button>
                                                                            </td>
                                                                        </tr>

                                                                    </React.Fragment>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div class="row justify-content-end">
                                                    <div class="text-right mr-3">
                                                        {
                                                            noOfPages ?
                                                                <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="primary" onChange={(e, page) => {
                                                                    setQuery({ ...query, page: page });
                                                                }} />
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubCategoryList;