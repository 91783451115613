import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Sidemenu from "../../pages/include/side-manu";
import Header from "../../pages/include/header";
import { Link } from "react-router-dom";
import { ApiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";

function SeoList() {
  const [seoList, setSeoList] = useState([]);

  const getSeoListing = async () => {
    const { data, message, success } = await ApiRequest({
      endUrl: API_END_URLS.seoListing,
      method: "GET",
      token: true,
    });
    if (success) {
      setSeoList(data?.list);
    } else {
      showMessage(message);
    }
  };

  const SeoDelete = async (id) => {
        let permision = window.confirm(
          "Are you sure you want to delete the record"
        );
        if (!permision) {
          return;
        }
    try {
      const { success, message } = await ApiRequest({
        endUrl: API_END_URLS.seoDelete + "/" + id,
        method: "delete",
        token: true 
      });
      if (success) {
        showMessage(message);
        getSeoListing()

      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: SeoListing.jsx:38 ~ SeoDelete ~ err:", err);
    }
  };

  useEffect(() => {
    getSeoListing();
  }, []);

  return (
    <React.Fragment>
      {}
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div className="row align-items-center mt-sm-0 mt-3">
                  <div className="col-md-9 col-12">
                    <div class="head-text new-cost-head">
                      <h1>SEO List</h1>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <Link to="/seo-create">
                      <button
                        type="button"
                        class="btn btn-block btn-analy btn-analy-2"
                      >
                        <img src="/images/plus-btn.svg" alt="/" />
                        ADD SEO
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="divider-line"></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <Table className="manage-table table">
                            <thead>
                              <tr>
                                <td class="head-td">ID</td>
                                <td class="head-td">SEO Name</td>
                                <td class="head-td">SEO PATH </td>
                                <td class="head-td">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {seoList?.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{index+1}</td>
                                    <td>{item?.seo_name}</td>
                                    <td>{item?.seo_path}</td>
                                    <td>
                                      <Link
                                        to={`/seo-edit/${item?.id}`}
                                      >
                                        <button className="btn btn-edit">
                                          Edit
                                        </button>{" "}
                                      </Link>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-edit"
                                        onClick={() => SeoDelete(item?.id)}
                                      >
                                        Delete
                                      </button>{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div class="row justify-content-end">
                          {/* <div class="text-right mr-3">
                            {noOfPages ? (
                              <Pagination
                                count={noOfPages}
                                defaultPage={1}
                                siblingCount={2}
                                boundaryCount={2}
                                variant="outlined"
                                shape="rounded"
                                color="success"
                                onChange={(e, page) => {
                                  setQuery({ ...query, page: page });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SeoList;
