import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiRequest, formRequestToRaw } from '../../config/apiRequest';
import { API_END_URLS } from '../../config/endUrls';
import { saveCredentials } from '../../helpers/auth';
import { showMessage } from '../../helpers/toast';
import { setGlobalAuthState, setGlobalState } from '../../redux/dispatch';
import { objectValidate } from './validator';


function Login() {

    /* Component state */
    const navigate = useNavigate()

    /* Form Ref */
    const loginForm = useRef()


    /* handle password show */
    const [eyeVisibility, setEyeVisibility] = useState(false)

    const handleClickShowPassword = () => {
        setEyeVisibility(!eyeVisibility)
    }

    /* Handle api's request */
    const handleSubmit = async (e) => {
        e.preventDefault()
        let formData = new FormData(loginForm.current)
        let rawReq = formRequestToRaw(formData)

        console.log(rawReq);

        /* Validate user fields */
        let validateResponse = objectValidate(rawReq)
        if (!validateResponse.isValid) {
            return showMessage(validateResponse.msg)
        }

        setGlobalState({ loader: true })
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.login, method: "post", body: rawReq, token: false })
        setGlobalState({ loader: false })

        if (success) {
            showMessage(message)
            saveCredentials({ token: data.access_token })
            setGlobalAuthState({ token: data.access_token })
            navigate("/")
        } else {
            showMessage(message)
        }

    }

    return (
        <>
            <section class="login-bg  py-4 ">
                <div class="container-fluid">

                    <div class="row justify-content-center pt-0 pt-lg-5">
                        <div class="col-md-7 col-lg-5">
                            <div class="login-form ">

                                <div class="text-center mt-3 mt-lg-5">
                                    <img src="images/black-logo.png" alt='' />
                                    <h1>Dashboard Panel Login</h1>
                                </div>

                                <form ref={loginForm} onSubmit={handleSubmit}>
                                    {/* <div class="row">
                                        <div class="col-md-12 col-lg-8">
                                            <input type="text" name="email" />
                                            <input type="password" name="password" />
                                        </div>
                                        <div class="col-md-10 col-lg-9   mt-5">
                                            <button type="submit" class="btn login-btn btn-block">Login</button>
                                        </div>
                                    </div> */}
                                    <div class="row justify-content-center mt-3  ">
                                        <div class="col-md-9 col-lg-8">
                                            <input type="text" class="form-control" name="email" placeholder="Email" maxLength={80} />

                                            <div className='mt-3' style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#F0EFFF", borderRadius: "5px", paddingRight: '10px' }}>
                                                <input
                                                    type={eyeVisibility ? "text" : "password"}
                                                    class="form-control mt-0"
                                                    name="password"
                                                    placeholder="Password" maxLength={20}

                                                />
                                                {eyeVisibility ? <img  style={{cursor:"pointer"}} src="/images/eyes.svg" alt='' onClick={handleClickShowPassword}/> : <img style={{cursor:"pointer"}}  src="/images/eyes-close.svg" alt="" onClick={handleClickShowPassword}/>}
                                            </div>
                                            <p><Link to="/">Forgot Password</Link></p>
                                        </div>
                                        <div class="col-md-10 col-lg-9 mt-4 pb-5">
                                            <button type="submit" class="btn login-btn btn-block">Login</button>
                                            {/* <Link to="/"><button type="button" class="btn login-btn btn-block">Login</button></Link> */}
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Login;