import React from "react";
import { Link } from "react-router-dom";

function CmsSideBar() {
  const activeClass=window.location.pathname
  return (
    <>
      <div className="content-menubox">
        <ul>
          <li className={activeClass==="/content" && "active"}>
            <Link to="/content">Home Page</Link>
          </li>
          <li  className={activeClass==="/who-we-are" && "active"}>
            <Link to="/who-we-are"> Who We Are </Link>
          </li>
          <li  className={activeClass==="/about" && "active"}>
            <Link to="/about"> About Us </Link>
          </li>
          <li  className={activeClass==="/choose-your-plan" && "active"}>
            <Link to="/choose-your-plan"> Choose Your Plan </Link>
          </li>
          <li  className={activeClass==="/write-your-experiance" && "active"} >
            <Link to="/write-your-experiance">Write your Experiance</Link>
          </li>
          <li  className={activeClass=== "/blog-list"  && "active" ||  activeClass===  "/blog" && "active" || activeClass===  "/blog-edit" && "active"}>
            <Link to="/blog-list"> Blog</Link>
          </li>
          {/* <li  className={activeClass==="/blog-list" && "active"}>
            <Link to="/blog-list"> Blog</Link>
          </li> */}
          <li  className={activeClass==="/range-of-product" && "active"}>
            <Link to="/range-of-product">Range Of Products</Link>
          </li>
          <li  className={activeClass==="/wish-list" && "active"}>
            <Link to="/wish-list">Wishlist</Link>
          </li>
          <li  className={activeClass==="/what-we-offer" && "active"}> 
            <Link to="/what-we-offer">What We Offer</Link>
          </li>
          <li  className={activeClass==="/contact-us" && "active"}>
            <Link to="/contact-us">Contact</Link>
          </li>
          <li  className={activeClass==="/terms-and-condition" && "active"}>
            <Link to="/terms-and-condition"> Terms & Condition </Link>
          </li>
          <li  className={activeClass==="/privacy-policy" && "active"}>
            <Link to="/privacy-policy"> Privacy Policy </Link>
          </li>
          <li  className={activeClass==="/cookie-policy" && "active"}>
            <Link to="/cookie-policy"> Cookie Policy </Link>
          </li>

        </ul>
      </div>
    </>
  );
}

export default CmsSideBar;
