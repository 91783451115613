import React from "react";
import Header from "../../include/header";
import Sidemenu from "../../include/side-manu";
import CmsSideBar from "../../../components/CMS/CmsSideBar";
import ContactUs from "../../../components/CMS/ContactUs/ContactUs";

export default function ContactUsPage() {
  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>
              <div class="pageinnner-padding padding-rightnone ">
                <div class="row">
                  <div class="col-md-3 col-8 pr-0 border-right mb-2 mb-sm-0">
                    <CmsSideBar />
                  </div>
                  <ContactUs />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
