import React from 'react';
import Header from '../../src/pages/include/header';
import Sidemenu from '../../src/pages/include/side-manu';

import { Link } from "react-router-dom";

function Addpromotion() {

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">

                                <div class="row ">
                                    <div class="col-md-6">
                                        <div class="head-text user-headtext add-text">
                                            <h1>Add Promotion</h1>
                                        </div>
                                    </div>
                                    <div class="col-md-6 text-right mt-2 mt-sm-0">
                                        <Link to="/cost"><button type="button" class="btn subscription-btn">Back</button></Link>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="border-bottom pb-2"></div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-6">

                                        <div class="form-group">
                                            <label for="" className="add-label"  >Subscription Type</label>
                                            <select class="form-control add-select" >
                                                <option>Standard</option>
                                                <option>Premium</option>
                                            </select>
                                        </div>


                                        <div class="form-group">
                                            <label for="" className="add-label">No. of free posting allowed</label>
                                            <input type="text" class="form-control add-form" placeholder="15" />
                                        </div>

                                        <div class="form-group">
                                            <label for="" className="add-label">No. of free Push notification allowed per week</label>
                                            <input type="text" class="form-control add-form" placeholder="2" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="" className="add-label"  >Period</label>
                                            <select class="form-control add-select " >
                                                <option>yearly</option>
                                                <option>Monthly</option>

                                            </select>
                                        </div>



                                        <div class="form-group">
                                            <label for="" className="add-label">Plan Status</label>
                                            <div class="row mt-2">
                                                <div class="col-md-4 col-4">
                                                    <div class="form-group post-check  ">
                                                        <input class="filled-in" type="checkbox" id="Check" />
                                                        <label for="Check" className="active-label"  > Active </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-4">
                                                    <div class="form-group post-check  ">
                                                        <input class="filled-in" type="checkbox" id="Check2" />
                                                        <label className="active-label" for="Check2"> Inactive </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row mt-3 ">
                                    <div class="col-md-12 text-center ">
                                        <div className="border-top mb-4"></div>
                                        <button type="button" class="btn sparessborder-btn new-cancel  mr-2 ">Back</button>
                                        <button type="button" class="btn sparessfill-btn new-save">Submit</button>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <h6 className="note-text" >* $1 / week will be charged for every notification after free promotion.</h6>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Addpromotion;