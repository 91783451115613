import React, { useEffect, useState } from 'react';
import Header from '../../include/header';
import Sidemenu from '../../include/side-manu';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import { Link } from "react-router-dom";
import { ApiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { Pagination } from '@mui/material';
import { HelpDeskStatus } from '../../../config/contants';


function HelpDeskList() {

    const [state, setState] = useState({ data: { "help_desk_list": [] } });
    const [queries, setQueries] = useState([]);
    const [noOfPages, setNoOfPages] = useState(0);

    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
    });

    const getContactUsList = async () => {
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.getHelpDeskList, method: "get", token: true, query: query })
        setState({ ...state, data: { "help_desk_list": data.help_desk_list.rows } });
        setQueries(data.help_desk_list.rows);
        setNoOfPages(Math.ceil(data.help_desk_list.count / query.limit));
    }

    useEffect(() => {
        getContactUsList();
    }, [query])

    const HelpDeskButtonCss = (status) => {
        if (status === HelpDeskStatus.View) {
            return "badge standard-btn"
        } else if (status === HelpDeskStatus.Viewed) {
            return "badge premium-btn"
        } else if (status === HelpDeskStatus.Responded) {
            return "contact-responded-btn"
        }
    }



    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 p-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9 col-12 p-0">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div className='nav-tabs pb-3'>
                                    <div class="head-text user-headtext">
                                        <h1>Help & Support - Contact Us</h1>
                                    </div>

                                    <div class="row justify-content-end pr-3 mt-sm-0 mt-3">
                                        <div class="new-seller">
                                            <ul>
                                                <li>
                                                    <Link to="/help-support/contactus-queries">
                                                        <button type="button" class="btn">Contact Us</button>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/help-support/helpdesk-queries">
                                                        <button type="button" class="btn active">HelpDesk</button>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row mt-3">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <Table className="table manage-table">
                                                        <thead>
                                                            <tr>
                                                                <td class="head-td" >Name</td>
                                                                <td class="head-td" >Title</td>
                                                                <td class="head-td" >Status</td>
                                                                {/* <td class="head-td" >Contact No.</td> */}
                                                                {/* <td class="head-td" >Location</td> */}
                                                                <td class="head-td" >Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {state.data.help_desk_list.map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{item.name}</td>
                                                                            <td>{item?.title}</td>
                                                                            <td> <span className={`badge ${HelpDeskButtonCss(item.status)}`}>
                                                                                {item.status}
                                                                            </span></td>

                                                                            {/* <td>{item?.User?.mobile}</td> */}
                                                                            {/* <td>{item?.User?.location}</td> */}
                                                                            <td>
                                                                                <Link to={`/help-support/helpdesk-query/${item.id}`}>
                                                                                    <button type="button" class="btn btn-edit">Details</button>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div class="row justify-content-end">
                                                    <div class="text-right mr-3">
                                                        {
                                                            noOfPages ?
                                                                <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="success" onChange={(e, page) => {
                                                                    setQuery({ ...query, page: page });
                                                                }} />
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default HelpDeskList;