import React, { useEffect, useState } from 'react';
import Header from '../../../include/header';
import Sidemenu from '../../../include/side-manu';
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_END_URLS } from '../../../../config/endUrls';
import { ApiRequest } from '../../../../config/apiRequest';
import { showMessage } from '../../../../helpers/toast';
import { setGlobalState } from '../../../../redux/dispatch';


function SubCategoryEdit() {

    const params = useParams();

    const navigate = useNavigate();

    const [state, setState] = useState({ data: { "product_category": [] } });
    const [productCategory, setProductCategories] = useState([{}]);
    const [productSubCategory, setProductSubCategory] = useState({});

    const [status, setStatus] = useState({ status: "Active" })


    /* Radio Button Handle */
    const onChangeValue = (event) => {
        setStatus({ status: event.target.value });
    }

    const getProductCategories = async () => {
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productCategories, method: "get", token: true })
        console.log("🚀 ~ file: SubCategoryEdit.js:32 ~ getProductCategories ~ data:", data)
        if (success) {
            setState({ ...state, data: { "product_categories": data.product_categories.rows } });
            setProductCategories(data.product_categories.rows);

        } else {
            showMessage(message);
        }
    }

    const fatchProductSubCategory = async () => {
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productSubCategoryEdit, method: 'get', params: params, token: true })
        console.log("🚀 ~ file: SubCategoryEdit.js:45 ~ fatchProductSubCategory ~ data:", data)
        if (success) {
            setProductSubCategory(data.product_subcategories)
            setStatus({ ...status, status: data.product_subcategories.status })
        } else {
            showMessage(message)
        }
    }

    const handleSubmit = async () => {
        let req = {
            "ProductCategoryId": productSubCategory.ProductCategoryId,
            "name": productSubCategory.name,
            // "status": productSubCategory.status,
            "status": status.status,
        }
        setGlobalState({ loader: true })
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productSubCategoryUpdate, method: "put", body: req, params: params, token: true })
        console.log(data)
        setGlobalState({ loader: false })
        if (success) {
            showMessage(message)
            navigate("/subcategories/product-subcategories")
        } else {
            showMessage(message)
        }
    }

    useEffect(() => {
        getProductCategories()
        fatchProductSubCategory()
    }, [])


    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">

                                <div class="row ">
                                    <div class="col-md-6">
                                        <div class="head-text user-headtext add-text">
                                            <h1> SubCategory Edit</h1>
                                        </div>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <Link to="/subcategories/product-subcategories"><button type="button" class="btn subscription-btn">Back</button></Link>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="border-bottom pb-2"></div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="">Select Category</label>
                                            <select
                                                style={{ cursor: 'pointer' }}
                                                class="form-control"
                                                onChange={(e) => {
                                                    setProductSubCategory({ ...productSubCategory, ProductCategoryId: e.target.value })
                                                }}
                                                value={productSubCategory.ProductCategoryId}
                                            >
                                                <option value="">Select</option>
                                                {productCategory.map((item, key) => {
                                                    console.log(item.id)
                                                    return (
                                                        <option value={item.id} selected={item.id === productCategory.id}>{item.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="" >Sub Category</label>
                                            <input
                                                type="text"
                                                name="name"
                                                class="form-control"
                                                placeholder="Enter Sub Category"
                                                value={productSubCategory.name}
                                                onChange={(e) => {
                                                    setProductSubCategory({ ...productSubCategory, name: e.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="" >Status</label>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <input
                                                        class="filled-in" product-category
                                                        name="status"
                                                        type="radio"
                                                        value={"Active"}
                                                        id="Check"
                                                        checked={status?.status === "Active" ? true : false}
                                                        onChange={onChangeValue}
                                                    />
                                                    {console.log(productSubCategory.status)}
                                                    <label class="checkFrom-label" for="Check">Active</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input
                                                        class="filled-in"
                                                        name="status"
                                                        type="radio"
                                                        value={"Inactive"}
                                                        id="Check2"
                                                        checked={status?.status === "Inactive" ? true : false}
                                                        onChange={onChangeValue}
                                                    />
                                                    <label class="checkFrom-label" for="Check2">Inactive</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 text-right">
                                        <Link to="/subcategories/product-subcategories"><button type="button" class="btn details-btn mr-2">Back</button></Link>
                                        <button
                                            type="submit"
                                            class="btn subscription-btn "
                                            onClick={handleSubmit}
                                        >Submit</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubCategoryEdit;