import React, { useEffect, useState } from 'react';
import Header from '../../include/header';
import Sidemenu from '../../include/side-manu';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal } from 'react-bootstrap';

import { Link, useNavigate } from "react-router-dom";
import { API_END_URLS } from '../../../config/endUrls';
import { ApiRequest, formRequestToRaw } from '../../../config/apiRequest';
import { saveCredentials } from '../../../helpers/auth';
import { showMessage } from '../../../helpers/toast';
import { setGlobalAuthState, setGlobalState } from '../../../redux/dispatch';




function SubscriptionPlanCreate() {


    /* Component state */
    const navigate = useNavigate()

    /* Handle api's request */
    const handleSubmit = async (e) => {
        e.preventDefault()
        let formData = new FormData(e.target)
        let rawReq = formRequestToRaw(formData)

        console.log(rawReq);

        /* Validate user fields */
        // let validateResponse = objectValidate(rawReq)
        // if (!validateResponse.isValid) {
        //     return showMessage(validateResponse.msg)
        // }

        setGlobalState({ loader: true })
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.subscriptionPlanCreate, method: "post", body: rawReq, token: true })
        setGlobalState({ loader: false })
        
        if (success) {
            showMessage(message)
            navigate("/cost")
        } else {
            showMessage(message)
        }

    }

    const handleBackBtn = () => {

        setGlobalState({ costTabKey: 'subscription-plans' })
        navigate("/cost")
    }


    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">

                                <div class="row ">
                                    <div class="col-md-6">
                                        <div class="head-text user-headtext add-text">
                                            <h1>Subscription Plan Add</h1>
                                        </div>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <button type="button" onClick={handleBackBtn} class="btn subscription-btn">Back</button>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="border-bottom pb-2"></div>
                                    </div>
                                </div>

                                <form method="POST" onSubmit={handleSubmit}>
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Name</label>
                                                <input type="text" name="name" class="form-control" placeholder="Enter Name" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >No of Posting</label>
                                                <input type="text" name="no_of_posting" class="form-control" placeholder="Enter No of Posting" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >No of Photos</label>
                                                <input type="text" name="no_of_photos" class="form-control" placeholder="Enter No of Photos" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >No of Push Notifications</label>
                                                <input type="text" name="no_of_push_notifications" class="form-control" placeholder="Enter No of Push Notifications" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Annual Price</label>
                                                <input type="text" name="annual_price" class="form-control" placeholder="Enter Annual Price" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Monthly Price</label>
                                                <input type="text" name="monthly_price" class="form-control" placeholder="Enter Monthly Price" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Plan Status</label>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <input class="filled-in" name="status" type="radio" value="active" id="Check" checked />
                                                        <label class="checkFrom-label" for="Check">Active</label>
                                                    </div>
                                                    <div class="col-md-6">

                                                        <input class="filled-in" name="status" type="radio" value="inactive" id="Check2" />
                                                        <label class="checkFrom-label" for="Check2">Inactive</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 text-right">
                                            <button type="button" onClick={handleBackBtn} class="btn details-btn mr-2">Back</button>
                                            <button type="submit" class="btn subscription-btn ">Submit</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubscriptionPlanCreate;