import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import Header from '../../include/header';
import Sidemenu from '../../include/side-manu';
import { useNavigate, useParams } from "react-router-dom";
import { ApiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { showMessage } from '../../../helpers/toast';
import { setGlobalState } from '../../../redux/dispatch';
import { useFormik } from 'formik';



function EditAdmin() {

    const navigate = useNavigate()

    const [profileimage, setProfileImage] = useState({
        profileFile: null,
        profileRender: null,
        backgroundFile: null,
        backgroundRender: null,

    })


    const initialValues = {
        name: null,
        email: null,
        mobile: null,
        alternate_mobile: null,
        alternate_email: null,
        landline_no: '',
        address: '',
        profile_url: '',
        background_url: ''
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(5).max(60).required("Please enter your name"),
        email: Yup.string().min(10).max(80).email('Invalid email').required('please Enter Your Email'),
        alternate_email: Yup.string().email('Invalid email').required('please Enter Your Alternate Email'),
        mobile: Yup.string().max(14).matches(phoneRegExp, 'Phone number is not valid').required('Please Enter Mobile Number'),
        alternate_mobile: Yup.string().max(14).matches(phoneRegExp, 'Alternate Mobile number is not valid'),
        landline_no: Yup.string().max(14).matches(phoneRegExp, 'Landline number is not valid'),
        address: Yup.string().min(10).max(80).required("Please enter a valid address")

    })


    const saveImage = (e, filetype) => {
        let type = ['image/gif', 'image/jpeg', 'image/png'];
        const file = e.target.files[0];
        const fileSize = e.target.files[0].size / 1024;
        if (e.target.files.length > 0) {
            if (type.indexOf(file.type) < 0) {
                showMessage("Please upload correct file type: png, jpeg");
                return;
            }
            if (fileSize < 50 && fileSize < 2000) {
                showMessage("Please upload file size must be between 200KB and 2MB");
                return false;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                let nab = { ...profileimage };
                if (filetype === "profile") {
                    nab["profileFile"] = file;
                    nab["profileRender"] = e.target.result;

                } else {
                    nab["backgroundFile"] = file;
                    nab["backgroundRender"] = e.target.result;

                }
                setProfileImage(nab);

            };

            reader.readAsDataURL(e.target.files[0]);
        }

        return true;
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            let req = {
                name: values.name,
                email: values.email,
                alternate_email: values.alternate_email,
                mobile: values.mobile,
                alternate_mobile: values.alternate_mobile,
                landline_no: values.landline_no,
                address: values.address,
            }
            const fileData = new FormData();
            let userid = values.id;
            let fileJson = {};
            if (profileimage.profileFile !== null) {
                const profileimg = (profileimage.profileFile.name).split(".")
                setValues({ ...values, profileimage: 'profile.' + profileimg[profileimg.length - 1] });
                req["profile_url"] = userid + "/profile." + profileimg[profileimg.length - 1];
                fileJson[profileimage.profileFile.name] = userid + "/profile." + profileimg[profileimg.length - 1];
                fileData.append("file", profileimage.profileFile);
            }
            if (profileimage.backgroundFile !== null) {
                const backimg = (profileimage.backgroundFile.name).split(".")
                setValues({ ...values, backgroundimage: 'background.' + backimg[backimg.length - 1] });
                req["background_url"] = userid + "/background." + backimg[backimg.length - 1];
                fileJson[profileimage.backgroundFile.name] = userid + "/background." + backimg[backimg.length - 1];
                fileData.append("file", profileimage.backgroundFile);
            }
            fileData.append("jsondata", JSON.stringify(fileJson));
            setGlobalState({ loader: true })
            const { data, success, mesaage } = await ApiRequest({ endUrl: API_END_URLS.editAdmin, method: "put", body: req })
            if (success) {
                console.log(Object.keys(fileJson).length)
                if (Object.keys(fileJson).length > 0) {
                    const response = await ApiRequest({ endUrl: API_END_URLS.fileUpload, method: "post", body: fileData, "headerType": "file" })
                    console.log('response>>>>>>>>>>>', response);
                    showMessage("Updated successfully")
                }
                navigate("/")
                setGlobalState({ loader: false })
            } else {
                showMessage(mesaage)
            }
        }
    })


    const { values, touched, errors, getFieldProps, handleSubmit, setValues } = formik;
    console.log("🚀 ~ file: EditUser.js:50 ~ UserEdit ~ values:", values)


    const fetchAdminData = async () => {
        const { data, message, success } = await ApiRequest({ endUrl: API_END_URLS.userGet, method: "get" })
        console.log("🚀 ~ file: header.js:18 ~ getAdmin ~ response:", data)
        if (success) {
            setValues({ ...data })
            setProfileImage({
                ...profileimage,
                profileRender: API_END_URLS.S3Bucket + data?.profile_url,

                backgroundRender: API_END_URLS.S3Bucket + data?.background_url,

            })
        } else {
            showMessage(message)
        }
    }

    useEffect(() => {
        fetchAdminData();
    }, [])

    return (
        <>
            <div className="container-fluid" id="seller-panel">
                <div className="row">
                    <div className="col-md-3 d-none d-sm-block p-0">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div className="col-md-9 col-12 p-0">
                        <div className="main-box">
                            <div className="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="pageinnner-padding">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="profile-editbox">
                                                <img className="bg-newimg" src={profileimage['backgroundRender']} alt='' />
                                                <div className="upload-btn-wrapper edit-upload ">
                                                    <button className="uploadBtn edit-userallow">
                                                        <img src="images/nav-camera.png" alt='' />
                                                    </button>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => saveImage(e, 'background')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-11 col-lg-10">
                                            <div className="edit-form">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="row position-relative">
                                                            <div className="col-md-3">
                                                                <div className="edit-userpic">
                                                                    <div class="upload-btn-wrapper new-upload new-allow">
                                                                        <img src={profileimage['profileRender']} alt='' />
                                                                        <button class="uploadBtn edit-userallow">
                                                                            <img src="images/nav-camera.png" alt=''>
                                                                            </img>
                                                                        </button>
                                                                        <input
                                                                            type="file"
                                                                            onChange={(e) => saveImage(e, 'profile')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <h1 >Profile</h1>
                                                                <p>Update your personal details.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-3 mt-sm-4">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="edit-label" > Name <span className="label-star"> * </span>  </label>
                                                            <input
                                                                type="input"
                                                                className="form-control edit-input"
                                                                {...getFieldProps('name')}
                                                                disabled
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {Boolean(touched.name) && errors.name}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="edit-label" > Email <span className="label-star"> * </span>  </label>
                                                            <input
                                                                type="input"
                                                                className="form-control edit-input"
                                                                {...getFieldProps('email')}
                                                                disabled
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {Boolean(touched.email) && errors.email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="edit-label" > Alternate Email  </label>
                                                            <input
                                                                type="input"
                                                                className="form-control edit-input"
                                                                {...getFieldProps('alternate_email')}
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {Boolean(touched.alternate_email) && errors.alternate_email}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="edit-label" > Mobile No. <span className="label-star"> * </span>  </label>
                                                            <input
                                                                type="text"
                                                                className="form-control edit-input"
                                                                {...getFieldProps('mobile')}
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {Boolean(touched.mobile) && errors.mobile}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="edit-label" > Alternate Mobile No. </label>
                                                            <input
                                                                type="input"
                                                                className="form-control edit-input"
                                                                {...getFieldProps('alternate_mobile')}
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {Boolean(touched.alternate_mobile) && errors.alternate_mobile}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="edit-label" > Landline No. </label>
                                                            <input
                                                                type="input"
                                                                className="form-control edit-input"
                                                                {...getFieldProps('landline_no')}
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {Boolean(touched.landline_no) && errors.landline_no}
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="edit-label" > Address <span className="label-star"> * </span>  </label>
                                                            <input
                                                                type="input"
                                                                className="form-control edit-input"
                                                                {...getFieldProps('address')}
                                                            />
                                                            <div style={{ color: "red" }}>
                                                                {Boolean(touched.address) && errors.address}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="text-center mt-3 mt-sm-5 mb-4 mb-sm-0">
                                        <button
                                            type="button"
                                            className="btn sparessborder-btn new-cancel  mr-3 "
                                            onClick={() => {
                                                navigate('/')
                                            }}
                                        >Cancel
                                        </button>
                                        <button type="submit"
                                            className="btn sparessfill-btn new-save"
                                        // onClick={handlesubmit}
                                        >Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default EditAdmin;