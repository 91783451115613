import React, { useState } from "react";
import Sidemenu from "../../pages/include/side-manu";
import Header from "../../pages/include/header";
import { setGlobalState } from "../../redux/dispatch";
import { API_END_URLS } from "../../config/endUrls";
import { ApiRequest } from "../../config/apiRequest";
import { showMessage } from "../../helpers/toast";
import { useNavigate } from "react-router-dom";

function Seo() {
  const navigate = useNavigate()
  const [state, setState] = useState({
    seo_name: null,
    seo_path: null,
    seo_title: null,
    seo_description: null,
    seo_keyword: null,
  });

  const handleSubmit = async () => {
    const req = {
      seo_name: state.seo_name,
      seo_path: state.seo_path,
      seo_title: state.seo_title,
      seo_description : state.seo_description,
      seo_keyword: state.seo_keyword
    };

    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createSeo,
        method: "post",
        body: req,
      });

      if (success) {
        showMessage(message);
        navigate('/seo-list')
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: Seo.jsx:37 ~ handleSubmit ~ err:", err);
    }
    setGlobalState({ loader: false });
  };

  return (
    <React.Fragment>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <div class="head-text user-headtext add-text mt-sm-0 mt-3">
                      <h1>Seo Create</h1>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="border-bottom pb-2"></div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <div class="form-group">
                      <label for="" className="add-label">
                        Seo Name <span className="label-star"> * </span>{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control add-form"
                        placeholder="Enter Seo Name"
                        value={state?.seo_name}
                        onChange={(e) => {
                          setState({ ...state, seo_name: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-group">
                      <label for="" className="add-label">
                        Seo Path <span className="label-star"> * </span>{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control add-form"
                        placeholder="Enter Seo Path"
                        value={state?.seo_path}
                        onChange={(e) =>
                          setState({ ...state, seo_path: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div class="form-group">
                      <label for="" className="add-label">
                        Seo Title<span className="label-star"> * </span>{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control add-form"
                        placeholder="Enter Seo Title"
                        value={state?.seo_title}
                        onChange={(e) =>
                          setState({ ...state, seo_title: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-group">
                      <label for="" className="add-label">
                        Seo Description <span className="label-star"> * </span>{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control add-form"
                        placeholder="Enter Seo Description"
                        value={state?.seo_description}
                        onChange={(e) =>
                          setState({
                            ...state,
                            seo_description: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div class="form-group">
                      <label for="" className="add-label">
                        Seo Keywords <span className="label-star"> * </span>{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control add-form"
                        placeholder="Enter Seo Keywords"
                        value={state?.seo_keyword}
                        onChange={(e) =>
                          setState({ ...state, seo_keyword: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3 ">
                  <div className="col-md-12 text-center ">
                    <div className="border-top mb-4"></div>
                    <button
                      type="button"
                      className="btn sparessborder-btn new-cancel  mr-2 "
                      onClick={()=> navigate('/seo-list')}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="btn sparessfill-btn new-save"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Seo;
