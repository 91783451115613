import React, { useEffect, useState } from 'react';
import Header from '../../include/header';
import Sidemenu from '../../include/side-manu';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal } from 'react-bootstrap';


import { Link, useParams } from "react-router-dom";
import { API_END_URLS } from '../../../config/endUrls';
import { ApiRequest } from '../../../config/apiRequest';
import { computeSegDraggable } from '@fullcalendar/react';
import { showMessage } from '../../../helpers/toast';
import { Pagination } from '@mui/material';


function BlogCategoryList() {

    const params = useParams();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [state, setState] = useState({ data: { "blog_categories": [] } });
    const [blogCategories, setBlogCategories] = useState([]);
    const [noOfPages, setNoOfPages] = useState(0);

    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
    });

    useEffect(() => {
        const getBlogCategories = async () => {
            const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.blogCategories, method: "get", token: true, query: query })

            setState({ ...state, data: { "blog_categories": data["blog_categories"] } });
            setBlogCategories(data.blog_categories.rows);
            setNoOfPages(Math.ceil(data.blog_categories.count / query.limit));
        }
        getBlogCategories();
    }, [query]);

    const deleteRecord = async (id) => {
        const { data, success, message } = await ApiRequest({ endUrl: 'v1/admin/blog-category/' + id, method: "delete", token: true })
        showMessage(message);

        const remainingBlogCategories = blogCategories.filter((result) => result.id !== id)
        setBlogCategories(remainingBlogCategories);
    }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div class="head-text user-headtext">
                                    <h1>Manage Blogs</h1>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <Tabs
                                            defaultActiveKey="home"
                                            id="uncontrolled-tab-example"
                                            className="mb-3 user-tabs justify-content-end "
                                        >
                                            <Tab eventKey="home" title="Blog Categories">

                                                {/* <div class="row">
                                                    <div class="col-md-8 col-9">
                                                        <div class="form-group">
                                                            <input type="email" class="form-control serch-input" placeholder="Search " />
                                                            <img src="images/search-img.png" class="search-img" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-3">
                                                        <div class="filter-box" onClick={handleShow} >
                                                            <img src="images/filter-img.png" />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div class="text-right">
                                                    <Link to="/blogs/blog-category/create">  <button type="button" class="btn subscription-btn">Add Category</button> </Link>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive">
                                                            <Table className="buy-table">
                                                                <thead>
                                                                    <tr>
                                                                        <td class="head-td" >Sr. No</td>
                                                                        <td class="head-td" >Name</td>
                                                                        <td class="head-td" >Alias</td>
                                                                        <td class="head-td" >Status</td>
                                                                        <td class="head-td" >Action</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {blogCategories.map((item, key) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{((query.page - 1) * query.limit) + key + 1}</td>
                                                                                <td>{item.name}</td>
                                                                                <td>{item.alias}</td>
                                                                                <td><span className={"badge " + (item.status == 'active' ? 'standard-btn' : 'premium-btn')}>{item.status}</span></td>
                                                                                <td>
                                                                                    <Link to={`/blogs/blog-category/edit/${item.id}`}><button type="button" class="btn details-btn">Edit</button></Link>
                                                                                    <button type="button" class="btn details-btn ml-1" onClick={() => {
                                                                                        deleteRecord(item.id)
                                                                                    }}>Delete</button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        <div class="row justify-content-end">
                                                            <div class="text-right mr-3">
                                                                {
                                                                    noOfPages ?
                                                                        <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="primary" onChange={(e, page) => {
                                                                            setQuery({ ...query, page: page });
                                                                        }} />
                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="profile" title="Blogs">
                                                <div class="row">
                                                    <div class="col-md-8 col-9">
                                                        <div class="form-group">
                                                            <input type="email" class="form-control serch-input" placeholder="Search " />
                                                            <img src="images/search-img.png" class="search-img" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-3">
                                                        <div class="filter-box" onClick={handleShow} >
                                                            <img src="images/filter-img.png" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive">
                                                            <Table className="buy-table">
                                                                <thead>
                                                                    <tr>
                                                                        <td class="head-td" >Name</td>
                                                                        <td class="head-td" >Email</td>
                                                                        <td class="head-td" >Subscription</td>
                                                                        <td class="head-td" >Start Date</td>
                                                                        <td class="head-td" >Expiry Date</td>
                                                                        <td class="head-td" >Action</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Lorem Ipsum</th>
                                                                        <th>Loremipsum@gmail.com</th>
                                                                        <th><span class="badge standard-btn">Standard</span></th>
                                                                        <th>March 21, 2020 <br /> 00.28</th>
                                                                        <th> March 23, 2020</th>
                                                                        <th> <button type="button" class="btn details-btn">Details</button> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Premium</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Premium</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Premium</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">Standard</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">Standard</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">Standard</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">Standard</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Premium</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">Standard</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">Standard</span></td>
                                                                        <td>March 21, 2020 <br /> 00.28</td>
                                                                        <td>March 23, 2020</td>
                                                                        <td> <button type="button" class="btn details-btn">Details</button></td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} className="filter-popup modal-dialog-slideout">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div class="pop-head">
                            <h1>Filters</h1>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-12">

                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {/* <button type="button" class="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" class="btn btn-login">SUBMIT MY FEEDBACK</button> */}
                    {/* <Button variant="secondary" onClick={handleClose}>Close</Button> */}
                    {/* <Button variant="primary" onClick={handleClose}>Save Changes</Button> */}
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default BlogCategoryList;