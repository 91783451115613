import React, { useEffect, useState } from 'react';
import Header from '../../../include/header';
import Sidemenu from '../../../include/side-manu';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal } from 'react-bootstrap';

import { Link, useNavigate, useParams } from "react-router-dom";
import { API_END_URLS } from '../../../../config/endUrls';
import { ApiRequest, formRequestToRaw } from '../../../../config/apiRequest';
import { saveCredentials } from '../../../../helpers/auth';
import { showMessage } from '../../../../helpers/toast';
import { setGlobalAuthState, setGlobalState } from '../../../../redux/dispatch';




function ServiceCategoryEdit() {


    /* Component state */
    const navigate = useNavigate()

    const params = useParams();

    const [state, setState] = useState({ data: { "service_category": {} } });
    const [serviceCategory, setServiceCategory] = useState({});
    const [serviceCategories, setServiceCategories] = useState([]);


    useEffect(() => {
        const getServiceCategory = async () => {
            const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.serviceCategoryEdit, method: "get", params: params, token: true })

            setState({ ...state, data: { "service_category": data["service_category"] } });
            setServiceCategory(data["service_category"]);
        }

        const getServiceCategories = async () => {
            const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.serviceParentCategories, method: "get", token: true })

            setState({ ...state, data: { "service_categories": data["service_categories"] } });
            setServiceCategories(data["service_categories"]);
        }

        getServiceCategory();
        getServiceCategories();
    }, []);


    /* Handle api's request */
    const handleSubmit = async (e) => {
        e.preventDefault()
        let formData = new FormData(e.target)
        let rawReq = formRequestToRaw(formData)

        console.log(rawReq);

        /* Validate user fields */
        // let validateResponse = objectValidate(rawReq)
        // if (!validateResponse.isValid) {
        //     return showMessage(validateResponse.msg)
        // }

        setGlobalState({ loader: true })
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.serviceCategoryEdit, method: "put", body: rawReq, params: params, token: true })
        setGlobalState({ loader: false })

        if (success) {
            showMessage(message)
            navigate("/categories/service-categories")
        } else {
            showMessage(message)
        }
    }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">

                                <div class="row ">
                                    <div class="col-md-6">
                                        <div class="head-text user-headtext add-text">
                                            <h1>Service Category Edit</h1>
                                        </div>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <Link to="/categories/service-categories"><button type="button" class="btn subscription-btn">Back</button></Link>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="border-bottom pb-2"></div>
                                    </div>
                                </div>

                                <form method="POST" onSubmit={handleSubmit}>
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Parent Category</label>
                                                <select name="parent_id" class="form-control" >
                                                    <option value="">Select</option>
                                                    {serviceCategories.map((item, key) => {
                                                        return (
                                                            <option value={item.id} selected={item.id === serviceCategory.parent_id}>{item.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Name</label>
                                                <input type="text" name="name" value={serviceCategory.name} onChange={(e) => {
                                                    setServiceCategory({ ...serviceCategory, name: e.target.value })
                                                }} class="form-control" placeholder="Enter Name" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="form-group">
                                                <label for="" >Alias</label>
                                                <input type="text" name="alias" value={serviceCategory.alias} onChange={(e) => {
                                                    setServiceCategory({ ...serviceCategory, alias: e.target.value })
                                                }} class="form-control" placeholder="Enter Alias" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Plan Status</label>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <input class="filled-in" name="status" type="radio" value="active" id="Check" onChange={(e) => {
                                                            setServiceCategory({ ...serviceCategory, status: e.target.value })
                                                        }} checked={serviceCategory.status == 'Active'} />
                                                        <label class="checkFrom-label" for="Check">Active</label>
                                                    </div>
                                                    <div class="col-md-6">

                                                        <input class="filled-in" name="status" type="radio" value="inactive" id="Check2" onChange={(e) => {
                                                            setServiceCategory({ ...serviceCategory, status: e.target.value })
                                                        }} checked={serviceCategory.status == 'Inactive'} />
                                                        <label class="checkFrom-label" for="Check2">Inactive</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 text-right">
                                            <Link to="/categories/service-categories"><button type="button" class="btn details-btn mr-2">Back</button></Link>
                                            <button type="submit" class="btn subscription-btn ">Submit</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceCategoryEdit;