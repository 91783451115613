import React, { useEffect, useState } from "react";
import InputFields from "../../Forms/InputField";
import { setGlobalState } from "../../../redux/dispatch";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";

function WriteYourExperiance() {
  const [data, setData] = useState({
    writeYourExperienceWithUs: null,
  });

  const handleChange = (value, key) => {
    setData((pre) => ({ ...pre, [key]: value }));
  };

  const handleUpdate = async (key) => {
    const PAYLOAD = {
      module_name: "write_your_experienceWithUs",
      section_name: key,
      content_value: data[key],
    };
    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };



  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setData(data2);

      if (success) {
        console.log(message)
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      <div className="col-md-9 p-sm-0 ">
        <div class="right-contentbox">
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="head-content">
                <p>Write your Experience with Us</p>
              </div>
              <div className="mt-3">
                <div class="row">
                  <div className="col-md-12">
                    <InputFields
                      placeholder={"Write your Experience with Us"}
                      className={"mt-0"}
                      name={"writeYourExperienceWithUs"}
                      onChange={(e) =>
                        handleChange(
                          e.target.value,
                          "writeYourExperienceWithUs"
                        )
                      }
                      value={data?.writeYourExperienceWithUs}
                    />
                    <div className="banner-uploadbtn">
                      <button
                        className="uploadBtn"
                        onClick={() =>
                          handleUpdate("writeYourExperienceWithUs")
                        }
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WriteYourExperiance;
