import { store } from "./combine";
import { appReducer, authReducer } from "./slicers";

const { saveAuth } = authReducer.actions /* Auth reducer logic */
export const setGlobalAuthState = (data) => {
    store.dispatch(saveAuth({ ...data }))
}

const { setAppState } = appReducer.actions /* App reducer logic */
export const setGlobalState = (data) => {
    store.dispatch(setAppState({ ...data }))
}