import React, { useEffect, useState } from "react";
import Sidemenu from "../../include/side-manu";
import Header from "../../include/header";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { HelpDeskStatus } from "../../../config/contants";
import { showMessage } from "../../../helpers/toast";
import { Link } from "react-router-dom";

function HelpDeskView() {
  const [view, setView] = useState({});
  const { id } = useParams();
  const params = useParams();
  const navigate = useNavigate();

  const [showBox, setShowBox] = useState(false);
  const onClick = () => {
    setShowBox(true);
  };

  const [helpDesk, setHelpDesk] = useState({
    reply_with_admin: "",
  });

  const handleSubmit = async (init = false) => {
    let req = {
      reply_with_admin: helpDesk.reply_with_admin,
      status: init ? HelpDeskStatus.Viewed : HelpDeskStatus.Responded,
    };
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.updateHelpDesk,
      method: "put",
      body: req,
      params: params,
    });
    if (success) {
      if (!init) navigate("/help-support/helpdesk-queries");
      showMessage(message)
    } else {
      showMessage(message);
    }
  };

  const viewHelpDesk = async () => {
    console.log("dfg");
    const { success, data, message } = await ApiRequest({
      endUrl: API_END_URLS.getHelpDeskView,
      params: { id },
      method: "get",
    });
    if (success) {
      setView(data.help_desk);
      if (data?.help_desk?.status === HelpDeskStatus.View) {
        handleSubmit(true);
      }
    } else {
      showMessage(message);
    }
  };
  useEffect(() => {
    viewHelpDesk();
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <div class="head-text user-headtext add-text mt-sm-0 mt-3">
                      <h1>Help Desk - Query</h1>
                    </div>
                  </div>
                  <div class="col-md-1 text-right mt-2 mt-sm-0 col-3">
                    <Link to="/help-support/helpdesk-queries">
                      <button type="button" class="btn btn-analy btn-block">
                        <img
                          className="mr-0"
                          src="/images/back-arrow.svg"
                          alt="/"
                        />
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="border-bottom pb-2"></div>
                  </div>
                </div>

                <div class="contact-detail mt-sm-5 mt-4">
                  <div class="row">
                    <div class="col-md-6 col-6">
                      <div class="query">
                        <h2>Name</h2>
                        <p>{view?.name || "N/A"}</p>
                      </div>
                      <div class="query">
                        <h2>Location </h2>
                        <p>{view?.User?.location || "N/A"}</p>
                      </div>
                    </div>
                    <div class="col-md-6 col-6">
                      <div class="query">
                        <h2>Email</h2>
                        <p>{view?.User?.email || "N/A"}</p>
                      </div>
                      <div class="query">
                        <h2>Phone No.</h2>
                        <p>{view?.User?.mobile || "N/A"}</p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="query">
                        <h2>Message</h2>
                        <p>{view?.message || "N/A"}</p>
                      </div>
                    </div>
                  </div>
                  {showBox ? (
                    <div class="">
                      <div class="button-field"></div>
                      <p className="feed-text">Response Message</p>

                      <div className="row">
                        <div className="col-md-7 col-12">
                          <div class="form-group mt-3">
                            <textarea
                              class="form-control content-inputsubscription"
                              wrap="off"
                              rows="2"
                              name="reply_with_admin"
                              value={helpDesk.reply_with_admin}
                              onChange={(e) => {
                                setHelpDesk({
                                  ...helpDesk,
                                  reply_with_admin: e.target.value,
                                });
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt-sm-4 mt-2">
                        <button
                          type="button"
                          class="btn sparessfill-btn new-save"
                          onClick={() => handleSubmit(false)}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div class="">
                      <div class="reply-buttons mt-4 pb-4 text-center d-flex justify-content-center">
                        <button
                          type="button"
                          class="btn sparessborder-btn new-cancel mr-3"
                          onClick={onClick}
                        >
                          Reply with Message
                        </button>
                        <button
                          type="button"
                          class="btn sparessfill-btn new-save"
                          onClick={onClick}
                        >
                          Reply with Email
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpDeskView;
