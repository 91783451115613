import React from 'react';
import Header from '../include/header';
import Sidemenu from '../include/side-manu';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import { Link } from "react-router-dom";


function HelpContactus() {



    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 p-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9  col-12 p-0">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div class="head-text user-headtext">
                                    <h1>Help & Support - Contact Us </h1>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <Tabs
                                            defaultActiveKey="home"
                                            id="uncontrolled-tab-example"
                                            className="mb-3 user-tabs justify-content-end "
                                        >
                                            <Tab eventKey="home" title="Contact Us">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive">
                                                            <Table className="manage-table table">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Name</td>
                                                                        <td>Email</td>
                                                                        <td>Status</td>
                                                                        <td>Contact No.</td>
                                                                        <td>Location</td>
                                                                        <td>Action</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>

                                            </Tab>
                                            <Tab eventKey="profile" title="Help Desk">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="table-responsive">
                                                            <Table className="manage-table table">
                                                                <thead>
                                                                    <tr>
                                                                        <td class="head-td" >Name</td>
                                                                        <td class="head-td" >Email</td>
                                                                        <td class="head-td" >Status</td>
                                                                        <td class="head-td" >Contact No.</td>
                                                                        <td class="head-td" >Location</td>
                                                                        <td class="head-td" >Action</td>
                                                                    </tr>

                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge premium-btn">Viewed</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td> <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Lorem Ipsum</td>
                                                                        <td>Loremipsum@gmail.com</td>
                                                                        <td><span class="badge standard-btn">View</span></td>
                                                                        <td>9876543210</td>
                                                                        <td>Sharjah, UAE</td>
                                                                        <td>  <Link to="/details"><button type="button" class="btn btn-edit">Details</button></Link> </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default HelpContactus;