import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiRequest } from '../../config/apiRequest';
import { API_END_URLS } from '../../config/endUrls';
import Header from '../include/header';
import Sidemenu from '../include/side-manu';

function Details() {


    const params = useParams();
    const [data, setData] = useState({ user: {} });

    useEffect(() => {
        const getUserDetail = async () => {
            const { success, data } = await ApiRequest({ endUrl: API_END_URLS.userDetailView, method: "get", params: params, token: true })
            if (success) {
                setData({ ...data })
            }
        }
        getUserDetail();
    }, []);


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 p-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div className="col-md-9 col-12 p-0">
                        <div className="main-box">
                            <div className="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div className="pageinnner-padding">

                                <div className="row">
                                    <div className="col-md-4 pr-3 pr-sm-0 pr-lg-3 col-lg-5 mb-3 mb-sm-0">
                                        <div className="user-deatilsbox">
                                            <div className="user-deatilsbg"></div>
                                            <div className="userdetails-pic">
                                                <img src="/images/details-userpic.svg" alt='/' />
                                            </div>

                                            <div className="padding-userdetails">
                                                <h1> Ashley Felecia </h1>
                                                <div className='row justify-content-center'>
                                                    <div className='col-md-6 col-7'>
                                                        <div className='premium-member-area text-center'>
                                                            <p>Premium Member</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='border-bottom py-2'></div>


                                                <div className="d-flex align-items-center deatils-box" >
                                                    <div className="social-icon" ><img src="/images/username.png" /></div>
                                                    <div className="social-text" >Sarjah, UAE</div>
                                                </div>

                                                <div className="d-flex align-items-center deatils-box" >
                                                    <div className="social-icon" ><img src="/images/msg.png" /></div>
                                                    <div className="social-text"  >Ashley9@gmail.com</div>
                                                </div>

                                                <div className="d-flex align-items-center deatils-box" >
                                                    <div className="social-icon" ><img src="/images/mobile.png" /> </div>
                                                    <div className="social-text" >(+971) 9876543</div>
                                                </div>

                                                <div className='border-bottom my-4'></div>
                                                <div className='details-user'>
                                                    <ul className='list-unstyled'>
                                                        <li>Status : <span><button className='btn standard-btn btn-listing'>Online</button></span></li>
                                                        <li>User name: <span className='dets-span'>John Doe</span></li>
                                                        <li>Email: <span className='dets-span'>vafgot@vultukir.org</span></li>
                                                        <li>Role: <span className='dets-span'>Designer</span></li>
                                                        <li>Mobile No. :  <span className='dets-span'>+98 9876543210</span></li>
                                                        <li>Landline Contact: <span className='dets-span'>(123) 456-7890</span></li>
                                                        <li>Languages:  <span className='dets-span'>English</span></li>
                                                        <li>Subscription : <span><button className='btn standard-btn btn-listing'>Active</button></span></li>
                                                        <li>Country: <span className='dets-span'>USA</span></li>


                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-12">
                                        {/* <div className="details-rightobx">
                                            <div className="row">
                                                <div className="col-md-6 border-right pb-3">
                                                    <h1>Personal Details</h1>

                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2 mt-lg-4">
                                                        <p>First Name</p>
                                                        <h6> {data?.user?.name || 'N/A'} </h6>
                                                    </div>
                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2">
                                                        <p>Last Name</p>
                                                        <h6>Felecia</h6>
                                                    </div>
                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2">
                                                        <p>Mobile No.</p>
                                                        <h6>{data?.user?.mobile || 'N/A'}</h6>
                                                    </div>
                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2">
                                                        <p>Alternate Mobile No.</p>
                                                        <h6>{data?.user?.alternate_mobile || 'N/A'}</h6>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 pb-3 pb-sm-0">


                                                    <div className="text-right mt-1 mt-sm-2">
                                                        <img src="images/edit.svg" />
                                                    </div>

                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2 mt-lg-4">
                                                        <p>Email</p>
                                                        <h6>{data?.user?.email || 'N/A'}</h6>
                                                    </div>
                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2">
                                                        <p>Alternate Email</p>
                                                        <h6>{data?.user?.alternate_email || 'N/A'}</h6>
                                                    </div>
                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2">
                                                        <p>Landline No.</p>
                                                        <h6>{data?.user?.landline_no || 'N/A'}</h6>
                                                    </div>
                                                    <div className="d-flex d-sm-block d-lg-flex justify-content-between align-items-center mt-2">
                                                        <p>Alternate Landline No.</p>
                                                        <h6>+91 987654321</h6>
                                                    </div>



                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="my-subscriptionbox mt-0">
                                            <div className="row">
                                                <div className="col-md-6 col-7">
                                                    <h1>My Subscription</h1>
                                                </div>
                                                <div className="col-md-6 col-5 text-right">
                                                    <button type="button" className="btn newpremium-btn">Premium </button>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                                <p>Paid</p>
                                                <h6>Yearly</h6>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-1 mt-lg-2">
                                                <p>Subscription Plan</p>
                                                <h6>Premium</h6>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-1 mt-lg-2">
                                                <p>Start Date</p>
                                                <h6>Mar. 24.2018</h6>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-1 mt-lg-2">
                                                <p>End Date</p>
                                                <h6>Mar.24.2019</h6>
                                            </div>

                                            {/* <div className="text-center mt-2">
                                                <button type="button" className="btn subscription-btn">Manage Subscription</button>
                                            </div> */}
                                        </div>

                                        <div className="row mt-3 mt-sm-4">
                                            <div className="col-md-12">
                                                <div className="details-rightobx">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4">
                                                            <h1>Personal Details</h1>
                                                            <div className='share-icon mt-3 mt-sm-4'>
                                                                <img src='/images/share-icon.svg' alt='/' />
                                                            </div>
                                                            <div className='location-icon d-flex align-items-center mt-3 mt-sm-5'>
                                                                <img src='/images/location-icon.svg' alt='/' />
                                                                <p className='px-2'>F9PM+5F</p>
                                                                <img src='/images/link-icon.svg' alt='/' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-12">
                                                            <div className="deatilslogo-box text-center">
                                                                <img src='/images/business-card.png' alt='/' />

                                                                {/* <img src="/images/logo-blue.jpg" /> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="row justify-content-center mt-3 pb-4">
                                                       
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3 mt-sm-4">
                                                <div className="details-rightobx">
                                                    <div className="row">
                                                        <div className="col-md-4 mt-sm-2">
                                                            <h1> Company Logo</h1>
                                                            <div className='share-icon mt-3 mt-sm-4'>
                                                                <img src='/images/share-icon.svg' alt='/' />
                                                            </div>
                                                           
                                                        </div>
                                                        <div className="col-md-8 col-12">
                                                            <div className="deatilslogo-box">
                                                                <img src='/images/business-card-2.png' alt='/' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;