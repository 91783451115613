import { configureStore } from '@reduxjs/toolkit'
import { authReducer, appReducer } from './slicers';

const store = configureStore({ /* Create store */
    reducer: {  /* Combine all reducers */
        auth: authReducer.reducer,
        app: appReducer.reducer
    }
});


export { store } /* Export store for childrens */