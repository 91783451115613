import React, { useEffect, useState } from "react";
import Header from "../../../include/header";
import Sidemenu from "../../../include/side-manu";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal } from "react-bootstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import { API_END_URLS } from "../../../../config/endUrls";
import { ApiRequest, formRequestToRaw } from "../../../../config/apiRequest";
import { saveCredentials } from "../../../../helpers/auth";
import { showMessage } from "../../../../helpers/toast";
import { setGlobalAuthState, setGlobalState } from "../../../../redux/dispatch";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SERVER_URL } from "../../../../config/contants";

function Bulkcategoryedit() {
  /* Category State */
  const url = SERVER_URL + API_END_URLS.ProductSmapleFileDownload;

  const [categories, setCategories] = useState([]);

  /* Component state */
  const navigate = useNavigate();

  const params = useParams();

  const initialValues = {
    product_name: null,
    CategoryId: null,
    status: null,
  };

  const validationSchema = Yup.object().shape({
    CategoryId: Yup.string().required("Please enter a valid category type"),
    status: Yup.string().required("Please choose a  status"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(
        "🚀 ~ file: bulkcategoryedit.js:46 ~ onSubmit: ~ values:",
        values
      );
      try {
        let req = {
          product_name: values.product_name,
          CategoryId: values.CategoryId,
          status: values.status,
        };

        console.log(req);
        setGlobalState({ loader: true });
        const { data, success, message } = await ApiRequest({
          endUrl: API_END_URLS.productCategoryCreate,
          method: "post",
          body: req,
          params: params,
          token: true,
        });
        setGlobalState({ loader: false });
        if (success) {
          showMessage(message);
          navigate("/categories/product-categories");
        } else {
          showMessage(message);
        }
      } catch (err) {
        showMessage(err.message);
      }
    },
  });

  const {
    values,
    setValues,
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
  } = formik;

  const getCategory = async () => {
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.productParentCategories,
      method: "get",
    });
    if (success) {
      setCategories(data);
    } else {
      showMessage(message);
    }
  };

  /* Bluck Upload  Start Code*/
  const handleUpload = async (file) => {
    try {
      const fileData = new FormData();
      fileData.append("files", file);
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.ProductBluckUpload,
        method: "post",
        body: fileData,
        headerType: "file",
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: bulkcategoryedit.js:119 ~ handleUpload ~ err:",
        err
      );
    }
  };
  /* Bluck Upload End Code*/

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 d-none d-sm-block p-0">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div class="row justify-content-between">
                  <div class="col-md-4 col-12">
                    <div class="head-text user-headtext add-text my-sm-0 my-3">
                      <h1>Add Product</h1>
                    </div>
                  </div>
                  <div className="col-md-3 col-9">
                    <div class="upload-btn-wrapper bulk-upload-btn cursor-pointer">
                      <button class="btn btn-block btn-analy cursor-pointer">
                        <img src="/images/bulk-icon.svg" alt="/" />
                        BULK UPLOAD
                      </button>
                      <input
                        type="file"
                        id="file"
                        accept=".xlsx"
                        onChange={(e) => handleUpload(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div class="col-md-4 col-3">
                    <a href={url}>
                      <button class="btn btn-block btn-analy cursor-pointer">
                        <img src="/images/bulk-icon.svg" alt="/" />
                        SampleFile Download
                      </button>
                    </a>
                  </div>

                  <div class="col-md-1 col-2">
                    <Link to="/categories/product-categories">
                      <button type="button" class="btn btn-block btn-analy">
                        <img
                          className="mr-0"
                          src="/images/back-arrow.svg"
                          alt="/"
                        />
                      </button>
                    </Link>
                  </div>

                  <div class="col-md-12">
                    <div class="border-bottom pb-2"></div>
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter Name"
                          {...getFieldProps("product_name")}
                        />
                        <div style={{ color: "red" }}>
                          {Boolean(touched.product_name) && errors.product_name}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Category Type</label>
                        <select
                          name="CategoryId"
                          class="form-control"
                          onChange={(e) =>
                            setFieldValue("CategoryId", e.target.value)
                          }
                        >
                          {" "}
                          <option value="">Select</option>
                          {categories.map((item, key) => {
                            return (
                              <option value={item.id}>
                                {item.category_name}
                              </option>
                            );
                          })}
                        </select>
                        <div style={{ color: "red" }}>
                          {Boolean(touched.CategoryId) && errors.CategoryId}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Status</label>
                        <div class="row">
                          <div class="col-md-4">
                            <input
                              class="with-gap nav-radio"
                              type="radio"
                              id="Check"
                              checked={values?.status === "Active"}
                              onChange={(e) =>
                                setFieldValue("status", "Active")
                              }
                            />
                            <label class="checkFrom-label" for="Check">
                              Active
                            </label>
                          </div>
                          <div style={{ color: "red" }}>
                            {Boolean(touched.status) && errors.status}
                          </div>
                          <div class="col-md-6">
                            <input
                              class="with-gap nav-radio"
                              name="status"
                              type="radio"
                              id="Check2"
                              checked={values.status === "Inactive"}
                              onChange={(e) =>
                                setFieldValue("status", "Inactive")
                              }
                            />
                            <label class="checkFrom-label" for="Check2">
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3 ">
                    <div class="col-md-12 text-center ">
                      <div class="border-top mb-4"></div>
                      <button
                        type="button"
                        class="btn sparessborder-btn new-cancel mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="btn sparessfill-btn new-save"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>

                <div class="border-top mb-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bulkcategoryedit;
