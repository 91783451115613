import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Sidemenu() {
  const activeMenu = window.location.pathname.split("/")[1];
  const navigate = useNavigate();
  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 side-menu-scroll">
            <div className="side-menu-bg">
              <div class="logo-box">
                <img
                  src="/images/nav-logo.png"
                  alt=""
                  onClick={() => navigate("/")}
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="side-border"></div>
                </div>
              </div>

              <div class="leftmenu-box">
                <ul>
                  <li
                    className={
                      activeMenu === "detail" ||
                      activeMenu === "countrywise" ||
                      activeMenu === "revenglobally" ||
                      activeMenu === "subsglobally" ||
                      activeMenu === "users" ||
                      activeMenu === "user" ||
                      activeMenu === ""
                        ? "active"
                        : ""
                    }
                  >
                    {" "}
                    <span
                      className={
                        activeMenu === "detail" ||
                        activeMenu === "countrywise" ||
                        activeMenu === "revenglobally" ||
                        activeMenu === "subsglobally" ||
                        activeMenu === "users" ||
                        activeMenu === "user" ||
                        activeMenu === ""
                          ? "point-circle"
                          : ""
                      }
                    ></span>
                    <Link to="/">
                      {" "}
                      <img src="/images/dash-icon.png" alt="/" /> Dashboard{" "}
                    </Link>
                  </li>
                  <li
                    class={
                      activeMenu === "cost" ||
                      activeMenu === "analytic" ||
                      activeMenu === "addform" ||
                      activeMenu === "addcost"
                        ? "active"
                        : ""
                    }
                  >
                    <span
                      className={
                        activeMenu === "cost"
                          ? "point-circle"
                          : "" || activeMenu === "analytic"
                          ? "point-circle"
                          : "" || activeMenu === "addform"
                          ? "point-circle"
                          : "" || activeMenu === "addcost"
                          ? "point-circle"
                          : ""
                      }
                    ></span>
                    <Link to="/cost">
                      {" "}
                      <img src="/images/cost-manage-icon.png" alt="/" /> Cost
                      Manager
                    </Link>
                  </li>
                  <li
                    class={ activeMenu === "maincategorylist" || activeMenu === "bulk-product-add" ||activeMenu === "categories" || activeMenu === "product"  || activeMenu === "product-category-edit" ? "active" : "" }
                  >
                    <span
                      className={ activeMenu === "product-categories" || activeMenu === "bulk-product-add" || activeMenu === "categories"  || activeMenu === "product" ||    activeMenu === "product-category-edit"? "point-circle" : "" }
                    ></span>
                    <Link to="/categories/product-categories">
                      <img src="/images/cate-icon.png" alt="/" />
                      Category Manager
                    </Link>
                  </li>
                  <li
                    class={
                      activeMenu === "reviews" ||
                      activeMenu === "review-feedback-edit"
                        ? "active"
                        : "" || activeMenu === "help-support"
                        ? "active"
                        : ""
                    }
                  >
                    <span
                      className={
                        activeMenu === "reviews"
                          ? "point-circle"
                          : "" || activeMenu === "review-feedback-edit"
                          ? "point-circle"
                          : "" || activeMenu === "help-support"
                          ? "point-circle"
                          : ""
                      }
                    ></span>
                    <Link to="/reviews">
                      <img src="/images/feedback-icon.png" alt="/" /> Feedback
                      Manager
                    </Link>
                  </li>
                  <li
                    class={
                      activeMenu === "content" ||
                      activeMenu === "who-we-are" ||
                      activeMenu === "about" ||
                      activeMenu === "write-your-experiance" ||
                      activeMenu === "choose-your-plan" ||
                      activeMenu === "blog" || activeMenu === "blog-list" ? "active" : "" ||   activeMenu === "blog-edit" ? "active" : ""||
                      activeMenu === "range-of-product" ||
                      activeMenu === "what-we-offer" ||
                      activeMenu === "wish-list" ||
                      activeMenu === "contact-us" ||
                      activeMenu === "terms-and-condition"? "active": "" || 
                      activeMenu === "privacy-policy" ? "active": ""   ||
                      activeMenu === "cookie-policy" ? "active" : "" 
                      
                    }
                  >
                    <span
                      className={
                        activeMenu === "content"
                          ? "point-circle"
                          : "" || activeMenu === "who-we-are"
                          ? "point-circle"
                          : "" || activeMenu === "contentblog"
                          ? "point-circle"
                          : "" || activeMenu === "privacy-policy"
                          ? "point-circle"
                          : "" || activeMenu === "contact-us"
                          ? "point-circle"
                          : "" || activeMenu === "terms-and-condition"
                          ? "point-circle"
                          : "" || activeMenu === "about"
                          ? "point-circle"
                          : "" || activeMenu === "choose-your-plan"
                          ? "point-circle"
                          : "" || activeMenu === "write-your-experiance"
                          ? "point-circle"
                          : "" || activeMenu === "blog"
                          ? "point-circle"
                          : "" || activeMenu === "range-of-product"
                          ? "point-circle"
                          : "" || activeMenu === "wish-list"
                          ? "point-circle"
                          : "" || activeMenu === "what-we-offer"
                          ? "point-circle"
                          : "" || activeMenu === "cookie-policy" ?  "point-circle" 
                          : "" || activeMenu === "blog-list" ?  "point-circle" : "" ||  activeMenu === "blog-edit" ?  "point-circle" : ""
                          

                      }
                    ></span>
                    <Link to="/content">
                      <img src="/images/content-manage-icon.png" alt="/" />{" "}
                      Content Manager
                    </Link>
                  </li>

                  <li
                    class={
                      activeMenu === "seo-list" ||
                      activeMenu === "seo-edit"  || 
                      activeMenu === "seo-create"
                      ? "active" : ""
                    }
                  >
                    <span
                      className={
                        activeMenu === "seo-list"
                          ? "point-circle"
                          : "" || activeMenu === "seo-edit"
                          ? "point-circle"
                          : "" || activeMenu === "seo-create"
                          ? "point-circle"
                          : ""
                      }
                    ></span>
                    <Link to="/seo-list">
                      <img src="/images/feedback-icon.png" alt="/" /> Seo
                    </Link>
                  </li>


                  {/* <li class={activeMenu == "help-support" ? "active" : ""}>
                                        <Link to="/help-support/contactus-queries">
                                            {" "}
                                            <i class="icon-help"></i> Help & Support{" "}
                                        </Link>
                                    </li> */}
                  {/* <li class={activeMenu == 'master' ? 'active' : ''}><Link to="/master/brands">  <i class="icon-help"></i> Master </Link></li> */}

                  {/* <li class={activeMenu == "subcategories" ? "active" : ""}>
                                        <Link to="/subcategories/product-subcategories">
                                            {" "}
                                            <i class="icon-catagory"></i>Sub Category Manager
                                        </Link>
                                    </li> */}
                </ul>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="side-border"></div>
                </div>
              </div>

              <div class="download-appbox">
                <div class="top-imgdowload">
                  <div class="bottom-imgdowload">
                    <h1>
                      Download <br /> Our App Now !
                    </h1>
                    <div class="soical-img">
                      <Link to="/">
                        {" "}
                        <img src="/images/dowl-apple.png" alt="" />
                      </Link>
                      <Link to="/">
                        {" "}
                        <img src="/images/play.png" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="copy-rightbox">
                                <p>Sparess.com  <br />  2023 All Right Reserved</p>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Sidemenu;
