import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
// compoments
import Header from "../../include/header";
import Sidemenu from "../../include/side-manu";

import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";
import { setGlobalState } from "../../../redux/dispatch";

function AddCost() {
    const params = useParams();
    const navigate = useNavigate();

    const initialValues = {
        serial_number: null,
        days: null,
        number_of_free_post: null,
        plan_cost: null,
        push_notifications_allowed: null,
        currency: "",
        period: null,
        subscription_type: null,
        status: null,
    };

    const validationSchema = Yup.object().shape({
        serial_number: Yup.string()
            .min(10)
            .max(30)
            .required("Please Enter Valid Serial Number"),
        days: Yup.string().min(1).max(10).required("Please Enter Days"),
        number_of_free_post: Yup.string()
            .min(2)
            .max(10)
            .required("Please Enter Number Of Free Post"),
        plan_cost: Yup.string().min(1).max(10).required("Please Enter Plan Cost"),
        push_notifications_allowed: Yup.string()
            .min(2)
            .max(10)
            .required("Please Enter Push Notifications Allowed"),
        currency: Yup.string().min(2).max(60).required("Please Enter Currency"),
        period: Yup.string()
            .notOneOf(["-1"], "Please Select Period")
            .required("Please Select Period"),
        subscription_type: Yup.string()
            .notOneOf(["-1"], "Please Select Subscription Type")
            .required("Please Select Subscription Type"),
        status: Yup.string().required("Please Select status"),
    });

    let query = new URLSearchParams(window.location.search);

    let pid = query.get("id");
    const [productId] = useState(pid ? pid : null);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                let req = {
                    serial_number: values.serial_number,
                    days: values.days,
                    number_of_free_post: values.number_of_free_post,
                    plan_cost: values.plan_cost,
                    currency: values.currency,
                    period: values.period,
                    subscription_type: values.subscription_type,
                    push_notifications_allowed: values.push_notifications_allowed,
                    status: values.status,
                };
                if (productId) {
                    const { data, message, success } = await ApiRequest({
                        endUrl: API_END_URLS.subscriptionPlanUpdate,
                        method: "put",
                        body: req,
                        params: { productId },
                    });
                    if (success) {
                        showMessage("Cost updated successfully");
                        navigate("/cost");
                    } else {
                        showMessage(message);
                    }
                } else {
                    setGlobalState({ loader: true });
                    const { data, message, success } = await ApiRequest({
                        endUrl: API_END_URLS.subscriptionPlanCreate,
                        method: "post",
                        body: req,
                    });
                    if (success) {
                        showMessage(message);
                        navigate("/cost");
                    } else {
                        showMessage(message);
                    }
                    setGlobalState({ loader: false });
                }
            } catch (error) {
                showMessage(error.message);
                console.log("🚀 ~ file: AddCostForm.js:22 ~ onSubmit: ~ error:", error);
            }
        },
    });
    const {
        values,
        touched,
        errors,
        getFieldProps,
        handleSubmit,
        setFieldValue,
        setValues,
    } = formik;
    console.log("values", values);

    const fetchData = async (id) => {
        const { data, success, message } = await ApiRequest({
            endUrl: API_END_URLS.subscriptionPlanEdit,
            method: "get",
            params: { productId },
        });
        if (success) {
            setValues({
                ...data?.plan,
            });
        } else {
            showMessage(message);
        }
    };

    useEffect(() => {
        if (productId) {
            fetchData(productId);
        }
    }, []);

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 p-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9 col-12 p-0">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div class="pageinnner-padding">
                                    <div class="row justify-content-between">
                                        <div class="col-md-6">
                                            <div class="head-text user-headtext add-text mt-sm-0 mt-3">
                                                <h1>Cost Manager - Add Subscription Plan</h1>
                                            </div>
                                        </div>
                                        <div class="col-md-1 text-right mt-2 mt-sm-0 col-3">
                                            <Link to="/cost">
                                                <button type="button" class="btn btn-analy btn-block">
                                                    {" "}
                                                    <img
                                                        className="mr-0"
                                                        src="images/back-arrow.svg"
                                                        alt="/"
                                                    />
                                                </button>
                                            </Link>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="border-bottom pb-2"></div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Serial Number <span className="label-star"> * </span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control add-form"
                                                    placeholder="Enter Serial Number"
                                                    {...getFieldProps("serial_number")}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.serial_number) &&
                                                        errors.serial_number}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Period <span className="label-star"> * </span>{" "}
                                                </label>
                                                <select
                                                    class="form-control add-select "
                                                    {...getFieldProps("period")}
                                                >
                                                    <option value="-1">Select Period</option>
                                                    <option value="yearly">Yearly</option>
                                                    <option value="monthly">Monthly</option>
                                                </select>
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.period) && errors.period}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Days <span className="label-star"> * </span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control add-form"
                                                    placeholder="Enter Days"
                                                    {...getFieldProps("days")}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.days) && errors.days}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Subscription Type{" "}
                                                    <span className="label-star"> * </span>{" "}
                                                </label>
                                                <select
                                                    class="form-control add-select"
                                                    placeholder="Please Select Subscription Type"
                                                    {...getFieldProps("subscription_type")}
                                                >
                                                    <option value={"-1"}>Select Subscription Type</option>
                                                    <option value="standard">Standard</option>
                                                    <option value="premium">Premium</option>
                                                </select>
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.subscription_type) &&
                                                        errors.subscription_type}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    No. of free posting allowed{" "}
                                                    <span className="label-star"> * </span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control add-form"
                                                    placeholder="Enter Number Of Free Posting allowed"
                                                    {...getFieldProps("number_of_free_post")}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.number_of_free_post) &&
                                                        errors.number_of_free_post}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    No. of free promotions (Push Notifications) allowed{" "}
                                                    <span className="label-star"> * </span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control add-form"
                                                    placeholder="Enter Number of Promotions Push Notifications"
                                                    {...getFieldProps("push_notifications_allowed")}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.push_notifications_allowed) &&
                                                        errors.push_notifications_allowed}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Plan Cost ( USD ){" "}
                                                    <span className="label-star"> * </span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control add-form"
                                                    placeholder="Enter Plan Cost $ 12"
                                                    {...getFieldProps("plan_cost")}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.plan_cost) && errors.plan_cost}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Plan Status <span className="label-star"> * </span>{" "}
                                                </label>
                                                <div class="row mt-2">
                                                    <div class="col-md-4 col-4">
                                                        <div class="form-group post-check  ">
                                                            <input
                                                                class="filled-in"
                                                                type="checkbox"
                                                                id="Check"
                                                                checked={
                                                                    values?.status === "Active" ? true : false
                                                                }
                                                                onChange={(e) =>
                                                                    setFieldValue("status", "Active")
                                                                }
                                                            />
                                                            <label for="Check" className="active-label">
                                                                {" "}
                                                                Active{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-4">
                                                        <div class="form-group post-check  ">
                                                            <input
                                                                class="filled-in"
                                                                type="checkbox"
                                                                id="Check2"
                                                                checked={
                                                                    values?.status === "Inactive" ? true : false
                                                                }
                                                                onChange={(e) =>
                                                                    setFieldValue("status", "Inactive")
                                                                }
                                                            />
                                                            <label className="active-label" for="Check2">
                                                                {" "}
                                                                Inactive{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div style={{ color: "red" }}>
                                                        {Boolean(touched.status) && errors.status}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Currency <span className="label-star"> * </span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control add-form"
                                                    placeholder="Enter Currency"
                                                    {...getFieldProps("currency")}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.currency) && errors.currency}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col-md-6 ">
                                            <div class="form-group">
                                                <label for="" className="add-label">
                                                    Plan Status <span className="label-star"> * </span>{" "}
                                                </label>
                                                <div class="row mt-2">
                                                    <div class="col-md-4 col-4">
                                                        <div class="form-group post-check  ">
                                                            <input
                                                                class="filled-in"
                                                                type="checkbox"
                                                                id="Check"
                                                                checked={
                                                                    values?.status === "Active" ? true : false
                                                                }
                                                                onChange={(e) =>
                                                                    setFieldValue("status", "Active")
                                                                }
                                                            />
                                                            <label for="Check" className="active-label">
                                                                {" "}
                                                                Active{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-4">
                                                        <div class="form-group post-check  ">
                                                            <input
                                                                class="filled-in"
                                                                type="checkbox"
                                                                id="Check2"
                                                                checked={
                                                                    values?.status === "Inactive" ? true : false
                                                                }
                                                                onChange={(e) =>
                                                                    setFieldValue("status", "Inactive")
                                                                }
                                                            />
                                                            <label className="active-label" for="Check2">
                                                                {" "}
                                                                Inactive{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div style={{ color: "red" }}>
                                                        {Boolean(touched.status) && errors.status}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row mt-3 ">
                                        <div className="col-md-12 text-center ">
                                            <div className="border-top mb-4"></div>
                                            <button
                                                type="button"
                                                className="btn sparessborder-btn new-cancel  mr-2 "
                                                onClick={() => {
                                                    navigate("/cost");
                                                }}
                                            >
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn sparessfill-btn new-save"
                                            >
                                                {productId != null ? "Update" : "Submit"}
                                            </button>
                                        </div>
                                    </div>

                                    {/* <div className="row mt-5">
                                        <div className="col-md-12">
                                            <h6 className="note-text">
                                                * First 25 posting free and remaining @0.001% of the
                                                sale price for items exceeding amount $5000 and any item
                                                below $5000 @ 0.005%.
                                            </h6>
                                            <h6 className="note-text mt-3">
                                                * $1 / Will be charged for every notification after free
                                                promotion.
                                            </h6>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCost;
