import React, { useEffect, useState } from "react";
import InputFields from "../../Forms/InputField";
import { API_END_URLS } from "../../../config/endUrls";
import { ApiRequest } from "../../../config/apiRequest";
import { setGlobalState } from "../../../redux/dispatch";
import { showMessage } from "../../../helpers/toast";

function ContactUs() {
  const [data, setData] = useState({
    emailId: null,
    phoneNumber: null,
    address: null,
  });

  const hanldeChange = (value, key) => {
    setData((pre) => ({ ...pre, [key]: value }));
  };

  const handleUpdate = async (key) => {
    const PAYLOAD = {
      module_name: "contact_us_page",
      section_name: key,
      content_value: data[key],
    };
    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };

  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setData(data2);

      if (success) {
        console.log(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div class="col-md-9">
        <div class="right-contentbox p-sm-0">
          <div class="head-content mt-3 mt-sm-4">
            <p>Contact</p>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="contact-field-area">
                <img src="/images/help-icon.svg" alt="/" />
                <p className="ml-3">Help@navigatorsRus.com</p>
              </div>

              <div className="contact-input mt-3">
                <InputFields
                  placeholder={"Help@navigatorsRus.com"}
                  className={"mt-0"}
                  name={"emailId"}
                  onChange={(e) => hanldeChange(e.target.value, "emailId")}
                  value={data?.emailId}
                />
                <div className="banner-uploadbtn">
                  <button
                    className="uploadBtn"
                    onClick={() => handleUpdate("emailId")}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="contact-field-area">
                <img src="/images/help-icon.svg" alt="/" />
                <p className="ml-3">+09876543210</p>
              </div>

              <div className="contact-input mt-3">
                <InputFields
                  placeholder={"+09876543210"}
                  className={"mt-0"}
                  name={"phoneNumber"}
                  onChange={(e) => hanldeChange(e.target.value, "phoneNumber")}
                  value={data?.phoneNumber}
                />
                <div className="banner-uploadbtn">
                  <button
                    className="uploadBtn"
                    onClick={() => handleUpdate("phoneNumber")}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12">
              <div className="contact-field-area">
                <img src="/images/help-icon.svg" alt="/" />
                <p className="ml-3">Sharjah, UAE</p>
              </div>

              <div className="contact-input mt-3">
                <InputFields
                  placeholder={"Sharjah, UAE"}
                  className={"mt-0"}
                  name={"address"}
                  onChange={(e) => hanldeChange(e.target.value, "address")}
                  value={data?.address}
                />
                <div className="banner-uploadbtn">
                  <button
                    className="uploadBtn"
                    onClick={() => handleUpdate("address")}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
