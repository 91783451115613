import React, { useEffect, useState } from 'react';
import Header from '../../include/header';
import Sidemenu from '../../include/side-manu';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ApiRequest } from '../../../config/apiRequest';



import { Link, useParams } from "react-router-dom";
import { API_END_URLS } from '../../../config/endUrls';
import { showMessage } from '../../../helpers/toast';
import { formatDate } from '../../../helpers/general';
import { useSelector } from 'react-redux';
import { setGlobalState } from '../../../redux/dispatch';
import { Nav, NavLink } from 'react-bootstrap';
import { Pagination } from '@mui/material';


function SubscriptionPlanList() {

    const params = useParams();

    const [state, setState] = useState({ data: { "plans": [] } });
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const reduxAppState = useSelector(state => state.app)
    const [key, setKey] = useState(reduxAppState.tabKey);
    const [noOfPages, setNoOfPages] = useState(0);

    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
    });

    useEffect(() => {
        const getSubscriptionPlans = async () => {
            const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.subscriptionPlans, method: "get", token: true, query: query })

            setState({ ...state, data: { "plans": data.plans.rows } });
            setSubscriptionPlans(data.plans.rows);
            setNoOfPages(Math.ceil(data.plans.count / query.limit));
        }
        getSubscriptionPlans();
    }, [query]);

    const deleteRecord = async (id) => {
        const { data, success, message } = await ApiRequest({ endUrl: 'v1/admin/subscription-plan/' + id, method: "delete", token: true })
        showMessage(message);

        const remainingSibscriptionPlans = subscriptionPlans.filter((result) => result.id !== id)
        setSubscriptionPlans(remainingSibscriptionPlans);
    }

    const handleTabChange = (k) => {
        setKey(k)
        setGlobalState({ tabKey: k })
    }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div class="head-text user-headtext cost-head">
                                    <h1>Cost Manager</h1>
                                </div>
                                {/* <NavLink to="/hdsjdh" className={(isActive)=> isActive ?"dsfs" : "dsfs"}>JKDJSK</NavLink> */}

                                <div class="row">
                                    <div class="col-md-12">
                                        <Nav.Link to="subscription-plans" activeClassName="active" className="mb-3 user-tabs cost-tab justify-content-end" >Subscription Plan</Nav.Link>
                                        <Nav.Link to="advirtisement" activeClassName="active" className="mb-3 user-tabs cost-tab justify-content-end" >Advertisement</Nav.Link>
                                        <div class="text-right">
                                            <Link to="/subscription-plan/create">  <button type="button" class="btn subscription-btn">Add</button> </Link>
                                        </div>
                                        <div class="row mt-0 mt-lg-2">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <Table className="buy-table">
                                                        <thead>
                                                            <tr>
                                                                <td class="head-td" >Serial Number</td>
                                                                <td class="head-td" >Name</td>
                                                                <td class="head-td" >Plan Cost ( AED )(Yearly)</td>
                                                                <td class="head-td" >Plan Cost ( AED )(Monthly)</td>
                                                                <td class="head-td" >Plan Status</td>
                                                                <td class="head-td" >Created On</td>
                                                                <td class="head-td" >Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {subscriptionPlans.map((item, key) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{((query.page - 1) * query.limit) + key + 1}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.annual_price}</td>
                                                                        <td>{item.monthly_price}</td>
                                                                        <td><span className={"badge " + (item.status == 'active' ? 'standard-btn' : 'premium-btn')}>{item.status}</span></td>
                                                                        <td>{formatDate(item.createdAt)}</td>
                                                                        <td>
                                                                            <Link to={`/subscription-plan/edit/${item.id}`}><button type="button" class="btn details-btn">Edit</button></Link>
                                                                            <button type="button" class="btn details-btn ml-1" onClick={() => {
                                                                                deleteRecord(item.id)
                                                                            }}>Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div class="row justify-content-end">
                                                    <div class="text-right mr-3">
                                                        {
                                                            noOfPages ?
                                                                <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="primary" onChange={(e, page) => {
                                                                    setQuery({ ...query, page: page });
                                                                }} />
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>





        </>
    );
}

export default SubscriptionPlanList;