import React from "react";
import Header from "../../include/header";
import Sidemenu from "../../include/side-manu";
import HomePageContent from "../cms/HomePageContent";
import CmsSideBar from "../../../components/CMS/CmsSideBar";


function Content() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div className="col-md-9 col-12 p-0">
            <div className="main-box">
              <div className="page-headpaddingbox">
                <Header></Header>
              </div>

              <div className="pageinnner-padding padding-rightnone">
                <div className="row">
                  <div className="col-md-3 col-8 pr-0 border-right mb-2 mb-sm-0">
                    <CmsSideBar />
                  </div>
                  <HomePageContent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
