import React, { useState, useEffect } from 'react';
import Header from '../../include/header';
import Sidemenu from '../../include/side-manu';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
// import { ApiRequest } from '../../../config/apiRequest';
// import { Pagination } from '@mui/material';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { Link } from "react-router-dom";
// import { API_END_URLS } from '../../../config/endUrls';
import moment from 'moment';

import { Line } from 'react-chartjs-2';

// import { Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js';
// import { showMessage } from '../../../helpers/toast';
// import { SubScriptionStatus, period } from '../../../config/contants';

// ChartJS.register(
//     Title, Tooltip, LineElement, Legend,
//     CategoryScale, LinearScale, PointElement, Filler,
// )

// const currentYear = parseInt(moment().year());

function Revenglobally() {
    // const [chartdata, setChartData] = useState(null) /* Data ko jaan buzh kr maine "null " kra, */
    // /* Because phele null rahega , jab tak API reponse nhi aataaa */

    const [lgShow, setLgShow] = useState(-1);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [sh, setSh] = useState(false);
    const handleClo = () => setSh(false);
    const handleSh = () => setSh(true);

    const [data, setData] = useState([]);
    // const [noOfPages, setNoOfPages] = useState(0);


    // const [query, setQuery] = useState({
    //     page: 1,
    //     limit: 10
    // })



    // const fetchUsers = async () => {
    //     const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.users, method: "get", query })
    //     if (success) {
    //         setData(data?.userSubscritions?.rows);
    //         setNoOfPages(Math.ceil(data?.userSubscritions?.count / query.limit));
    //         console.log(Math.ceil(data?.userSubscritions?.count / query.limit), "page")

    //     } else {
    //         showMessage(message);
    //     }
    // }

    // const userGraph = async (params) => {
    //     let x = [] /* months */, y = []/* users count */
    //     const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.userGraph, method: "get", query: params })
    //     if (success) {
    //         data.map((item) => {
    //             x.push(moment().set({ month: item.month }).format("MMM"))
    //             y.push(item.total)
    //         })
    //         setChartData({
    //             labels: x,
    //             datasets: [
    //                 {
    //                     label: "Users",
    //                     data: y, /* data vo daal deya jo backend se aa rha hai */
    //                     background: 'linear-gradient(180deg, #FF5501 50%, rgba(255, 153, 1, 0) 100%);',
    //                     borderColor: '#FF5501',
    //                     tension: 0.4,
    //                     fill: true,
    //                     pointStyle: 'round',
    //                     pointBorderColor: '#FF5501',
    //                     pointBackgroundColor: '#FF5501',
    //                     showLine: true
    //                 }
    //             ]
    //         })
    //     } else {
    //         showMessage(message)
    //     }

    // }

    // const handleChange = (e) => {
    //     userGraph({ year: e.target.value })
    // }

    // const handleTextChange = (e) => {
    //     setQuery((prev) => ({ ...prev, searchText: e.target.value }))
    // }

    // useEffect(() => {
    //     fetchUsers("")
    //     userGraph();
    // }, [query]);

    // const subscriptionCss = (SubscriptionStatus) => {
    //     if (SubscriptionStatus === SubScriptionStatus.standard) {
    //         return "standard-btn"
    //     } else if (SubscriptionStatus === SubScriptionStatus.premium) {
    //         return "permium-btn"
    //     }

    // }

    return (
        <>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 d-none d-sm-block p-0">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9 col-12 p-0">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div className='row align-items-center justify-content-end'>
                                    <div className='col-md-7 col-12'>
                                        <div class="head-text new-cost-head">
                                            <h1>Subscription Revenue Globally</h1>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-9 pr-0'>
                                        <div class="form-group m-0">
                                            <input
                                                type="text"
                                                class="form-control serch-input"
                                                placeholder="Search "
                                            // onChange={handleTextChange}
                                            />
                                            <img src="images/search-img.png" class="search-img" alt='' />
                                        </div>
                                    </div>

                                    <div class="col-md-1 text-right col-3">
                                        <Link to="/">
                                            <button type="button" class="btn btn-analy btn-block">
                                                {" "}
                                                <img
                                                    className="mr-0"
                                                    src="images/back-arrow.svg"
                                                    alt="/"
                                                />
                                            </button>
                                        </Link>
                                    </div>
                                    <div class="col-md-12"><div class="border-bottom pb-2"></div></div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <Table className="table manage-table">
                                                        <thead>
                                                            <tr>
                                                                <td class="head-td" >Country</td>
                                                                <td class="head-td" >Total User</td>
                                                                <td class="head-td" >Subscribers</td>
                                                                <td class="head-td" >Revenue</td>
                                                                <td class="head-td" >Advertisement</td>
                                                                <td class="head-td" >Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lorem Ipsum</td>
                                                                <td>345</td>
                                                                <td>
                                                                    123
                                                                </td>
                                                                <td>183000$</td>
                                                                <td>35</td>
                                                                <td>
                                                                    <Link to="/countrywise">
                                                                        <button className='btn btn-edit'>
                                                                            View
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                         

                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {/* <div className='row justify-content-end'>
                                                    <div className='text-right mr-3'>
                                                        {
                                                            noOfPages ?
                                                                <Pagination
                                                                    count={noOfPages}
                                                                    defaultPage={1}
                                                                    siblingCount={2}
                                                                    boundaryCount={2}
                                                                    variant="outlined"
                                                                    shape="rounded"
                                                                    color="success"
                                                                    onChange={(e, page) => {
                                                                        setQuery({ ...query, page: page });
                                                                    }}
                                                                />
                                                                : " "
                                                        }
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal
                className="new-model"
                size="lg"
                show={lgShow === -1 ? false : true}
                onHide={() => setLgShow(-1)}
                aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton >
                    <h1 className="new-pophead" >User - Details</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive" >
                        <Table className="pop-table">
                            <tbody>
                                <tr>
                                    <td>User Name</td>
                                    <td>Vinay Kumar </td>
                                    <td>User Email Id</td>
                                    <td>User@spare.com</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>India</td>
                                    <td>Credit Card Number</td>
                                    <td>3************9617 <img className='pl-2' src='/images/pop-pass-eye.svg' alt='/' /></td>
                                </tr>
                          
                                <tr>
                                    <td>Card Expiry Date</td>
                                    <td>10/25</td>
                                    <td>Subscription Type</td>
                                    <td>Standard Yearly</td>
                                </tr>
                                <tr>
                                    <td>Subscription Start Date</td>
                                    <td>03/07/2023</td>
                                    <td>Subscription End Date</td>
                                    <td>03/11/2023</td>
                                </tr>
                                <tr>
                                    <td>No. of Days left for Subscription Exp. </td>
                                    <td>15 Days</td>
                                    <td>Free Product/Service Posted</td>
                                    <td>15 </td>
                                </tr>
                                <tr>
                                    <td>Additional Product/ Service Posted</td>
                                    <td>05</td>
                                    <td>Free Video Adverts Posted</td>
                                    <td>05</td>
                                </tr>
                                <tr>
                                    <td>Additional Video Adverts Posted</td>
                                    <td>03</td>
                                    <td>Free Banner Adverts Posted</td>
                                    <td>03</td>
                                </tr>
                                <tr>
                                    <td>Additional Banner Adverts Posted</td>
                                    <td>04</td>
                                    <td>Free Promotions Posted</td>
                                    <td>04</td>
                                </tr>
                                <tr>
                                    <td>Additional Promotions Posted</td>
                                    <td>15</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal> */}


            {/* <Modal show={show} onHide={handleClose} className="filter-popup modal-dialog-slideout">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div class="pop-head">
                            <h1>Filters</h1>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Example select</label>
                                <select class="form-control" id="exampleFormControlSelect1">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> */}

            {/* <Modal
                className="new-model chart-pop-1"
                show={sh}
                onHide={handleClo}
                size="lg" >
                <Modal.Header>

                </Modal.Header>
                <Modal.Body>
                    <div className="row text-center justify-content-center">
                        <div className="col-md-11">
                            <div class="revenue-chart-box">
                                <div class="total-revenue d-flex align-items-center justify-content-between">
                                    <div class="head-text">
                                        <h1>Total Users (Numbers)</h1>
                                    </div>

                                    <div class="y-drops">
                                        <div class="form-group">
                                            <select
                                                class="form-select form-control"
                                                aria-label="Default select example"
                                                onChange={handleChange}
                                            >
                                                <option value={currentYear}>{currentYear}</option>
                                                <option value={currentYear - 1}>{currentYear - 1}</option>
                                                <option value={currentYear - 2}>{currentYear - 2}</option>
                                            </select>
                                        </div>
                                        <div class="export-img ml-4">
                                            <a href="#"><img onClick={handleSh} src="images/export-icon.png" alt="/" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="revenue-chart">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Line height="200px" data={chartdata}>Hello</Line>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal> */}

        </>
    );
}

export default Revenglobally;