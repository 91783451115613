import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import Header from '../include/header';
import Sidemenu from '../include/side-manu';
import Table from 'react-bootstrap/Table';

import { Link, useNavigate } from "react-router-dom";
import { ApiRequest } from '../../config/apiRequest';
import { API_END_URLS } from '../../config/endUrls';
import { showMessage } from '../../helpers/toast';
import { Modal } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { colors } from '@mui/material';
import { Colors } from 'chart.js';

// import ApexCharts from 'apexcharts'



function Dashboard() {

    const [userShow, setuserShow] = useState(false);
    const [subsShow, setsubsShow] = useState(false);

    const navigate = useNavigate()

    const [userData, setUserData] = useState([])

    const [country, setCountry] = useState([])

    const [subscriptions, setSubscriptions] = useState([])

    const [countryCopy, setCountryCopy] = useState([])

    /* Country Search */
    const searchCountry = (searchKey) => {
        let data = []
        countryCopy.map((search) => {
            if ((search?.country.toLowerCase().includes(searchKey?.toLowerCase()))) {
                data.push(search)
            }
        })
        setCountry(data)
    }

    const fetchData = async () => {
        const { data, message, success } = await ApiRequest({ endUrl: API_END_URLS.usersDashboard, method: "get" })
        if (success) {
            setUserData(data?.users?.rows);
            setCountry(data?.userCount)
            setCountryCopy(data?.userCount)

            setSubscriptions(data?.allSubscriptionCountryCount)
        } else {
            showMessage(message)
        }
    }

    const colors = ['rgba(105, 162, 39, 0.2)', 'rgba(105, 162, 39, 0.2)', 'rgba(105, 162, 39, 1)', 'rgba(105, 162, 39, 0.2)', 'rgba(105, 162, 39, 0.2)', 'rgba(105, 162, 39, 0.2)', 'rgba(105, 162, 39, 0.2)', 'rgba(105, 162, 39, 0.2)',];

    const options = {
        chart: {
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                }
            },
            toolbar: {
                show: false
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        series: [
            {
                name: 'Chart Revenue',
                data: [70, 80, 150, 50, 79, 100, 80, 90, 45],
            },
        ],
        xaxis: {
            categories: [
                ['Jan'],
                ['Feb'],
                ['Mar'],
                ['Apr'],
                ['May'],
                ['Jun'],
                ['July'],
                ['Aug'],
                ['Sept'],

            ],
            labels: {
                style: {
                    colors: '#000',
                    fontSize: '12px'
                }
            }
        }
    };
    const optionss = {
        chart: {
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                }
            },
            toolbar: {
                show: false
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        series: [
            {
                name: 'Chart Revenue',
                data: [40, 80, 150, 50, 79, 100, 80, 90, 45],
            },
        ],
        xaxis: {
            categories: [
                ['Jan'],
                ['Feb'],
                ['Mar'],
                ['Apr'],
                ['May'],
                ['Jun'],
                ['July'],
                ['Aug'],
                ['Sept'],

            ],
            labels: {
                style: {
                    colors: '#000',
                    fontSize: '12px'
                }
            }
        }
    };
    const optionsz = {
        chart: {
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                }
            },
            toolbar: {
                show: false
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        series: [
            {
                name: 'Chart Revenue',
                data: [100, 80, 150, 50, 79, 50, 80, 90, 45],
            },
        ],
        xaxis: {
            categories: [
                ['Jan'],
                ['Feb'],
                ['Mar'],
                ['Apr'],
                ['May'],
                ['Jun'],
                ['July'],
                ['Aug'],
                ['Sept'],

            ],
            labels: {
                style: {
                    colors: '#000',
                    fontSize: '12px'
                }
            }
        }
    };
    const optionsa = {
        chart: {
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                }
            },
            toolbar: {
                show: false
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        series: [
            {
                name: 'Chart Revenue',
                data: [40, 80, 150, 100, 79, 50, 80, 90, 45],
            },
        ],
        xaxis: {
            categories: [
                ['Jan'],
                ['Feb'],
                ['Mar'],
                ['Apr'],
                ['May'],
                ['Jun'],
                ['July'],
                ['Aug'],
                ['Sept'],

            ],
            labels: {
                style: {
                    colors: '#000',
                    fontSize: '12px'
                }
            }
        }
    };


    useEffect(() => {
        if (userShow == false || subsShow == false) {
            fetchData()
        }
    }, [userShow, subsShow])


    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 p-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9 p-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div class="head-text mt-3 mt-sm-0">
                                    <h1>Total Users Map</h1>
                                </div>

                                <div class="row mt-3 mt-sm-4">
                                    <div class="col-md-4 mb-2">
                                        <div class="seller-box">
                                            <div class="seller-boxbgimg">
                                                <div className='table-responsive'>
                                                    <Table className="seller-table m-0">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={3}>Users Globally</th>
                                                                {/* <th><p class="last-text" >{userData?.length}</p></th> */}
                                                            </tr>
                                                        </thead>
                                                        <Link to="/users">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/in.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>25.8%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/af.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            Afghanistan
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>16.2%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/dz.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>12.3%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/as.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            American Samoa
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>11.9%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/in.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>25.8%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/af.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            Afghanistan
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>16.2%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/dz.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>12.3%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/as.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>626</p>
                                                                            American Samoa
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>11.9%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                {/* {
                                                            countryCopy.slice(0, 4).map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td> <div class="flag-img">
                                                                                <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                                            </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className='country-vall'>
                                                                                    <p>626</p>
                                                                                    {item?.country}
                                                                                </div>
                                                                            </td>
                                                                            <td><p class="bold-textcontent">{item?.count}</p></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        } */}
                                                            </tbody>
                                                        </Link>

                                                    </Table>
                                                </div>


                                                {/* <div class="border-top">
                                                    <Link to=""><h4 onClick={() => setuserShow(true)} >View Full Stats</h4></Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-2">
                                        <div class="seller-box">
                                            <div class="seller-boxbgimg">
                                                <div className='table-responsive'>
                                                    <Table className="seller-table m-0" >
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={3}>Subscribers Globally</th>
                                                                {/* <th><p class="last-text" >{userData?.length}</p></th> */}
                                                            </tr>
                                                        </thead>
                                                        <Link to="/subsglobally">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/in.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>21</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>25.8%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/af.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>62</p>
                                                                            Afghanistan
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>16.2%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/dz.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>89</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>12.3%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/as.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>152</p>
                                                                            American Samoa
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>11.9%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/in.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>21</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>25.8%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/af.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>62</p>
                                                                            Afghanistan
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>16.2%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/dz.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>89</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>12.3%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/as.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>152</p>
                                                                            American Samoa
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>11.9%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                {/* {
                                                            countryCopy.slice(0, 4).map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td> <div class="flag-img">
                                                                                <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                                            </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className='country-vall'>
                                                                                    <p>626</p>
                                                                                    {item?.country}
                                                                                </div>
                                                                            </td>
                                                                            <td><p class="bold-textcontent">{item?.count}</p></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        } */}
                                                            </tbody>
                                                        </Link>

                                                    </Table>
                                                </div>


                                                {/* <div class="border-top">
                                                    <Link to=""><h4 onClick={() => setuserShow(true)} >View Full Stats</h4></Link>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* <div class="seller-box">
                                            <div class="seller-boxbgimg">
                                                <Table className="seller-table">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={3}>Subscriptions Globality</th>
                                                            <th><p class="last-text" >{subscriptions}</p></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            countryCopy.slice(0, 4).map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td> <div class="flag-img">

                                                                                <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                                            </div>
                                                                            </td>
                                                                            <td>{item?.country}</td>
                                                                            <td><p class="bold-textcontent">{item?.subscriptionCount}</p></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                <div class="border-top">
                                                    <Link to=""> <h4 onClick={() => setsubsShow(true)} >View Full Stats</h4> </Link>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div class="col-md-4 mb-2">
                                        <div class="seller-box">
                                            <div class="seller-boxbgimg">
                                                <div className='table-responsive'>
                                                    <Table className="seller-table m-0" >
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={3}>Subscription Revenue Globally</th>
                                                                {/* <th><p class="last-text" >{userData?.length}</p></th> */}
                                                            </tr>
                                                        </thead>
                                                        <Link to="/revenglobally">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/in.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>25.8%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/af.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            Afghanistan
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>16.2%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/dz.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>12.3%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/as.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            American Samoa
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>11.9%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/in.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>25.8%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/af.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            Afghanistan
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>16.2%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/dz.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            India
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count green-count'>
                                                                            <img src='/images/green-arrow.svg' alt='/' />
                                                                            <span>12.3%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='flag-img'>
                                                                            <img src='/images/flgs/as.svg' alt='/' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='country-vall'>
                                                                            <p>$348</p>
                                                                            American Samoa
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='globality-count red-count'>
                                                                            <img src='/images/red-arrow.svg' alt='/' />
                                                                            <span>11.9%</span>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                                {/* {
                                                            countryCopy.slice(0, 4).map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td> <div class="flag-img">
                                                                                <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                                            </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className='country-vall'>
                                                                                    <p>626</p>
                                                                                    {item?.country}
                                                                                </div>
                                                                            </td>
                                                                            <td><p class="bold-textcontent">{item?.count}</p></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        } */}
                                                            </tbody>
                                                        </Link>

                                                    </Table>
                                                </div>


                                                {/* <div class="border-top">
                                                    <Link to=""><h4 onClick={() => setuserShow(true)} >View Full Stats</h4></Link>
                                                </div> */}
                                            </div>
                                        </div>


                                        {/* <div class="seller-box">
                                            <div class="seller-boxbgimg">
                                                <Table className="seller-table">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={3}>Subscriptions Globality</th>
                                                            <th><p class="last-text" >{subscriptions}</p></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            countryCopy.slice(0, 3).map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td> <div class="flag-img">
                                                                                <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                                            </div>
                                                                            </td>
                                                                            <td>{item?.country}</td>
                                                                            <td><p class="bold-textcontent">{item?.subscriptionCount}</p></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                <div class="border-top">
                                                    <Link to=""> <h4 onClick={() => setsubsShow(true)} >View Full Stats</h4> </Link>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className='col-md-12 mt-3 mt-sm-4'>
                                        <div className='chart-tabs position-relative'>
                                            <div className='chart-head'>
                                                <h1>Add on Revenue Globally</h1>
                                                <p>Yearly Earnings Overview in Dollar</p>
                                            </div>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="subsription">
                                                <div className='row pt-sm-0 pt-5'>
                                                    <div className='col-md-12'>
                                                        <Nav className='justify-content-sm-end justify-content-center d-sm-flex d-block'>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="subsription">
                                                                    <div className='text-center ch-nav-icon'>
                                                                        <img src='/images/ch-icon-1.png' alt='/' />
                                                                        <p>Subscription</p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="advertisement">
                                                                    <div className='text-center ch-nav-icon'>
                                                                        <img src='/images/ch-icon-2.png' alt='/' />
                                                                        <p>Advertisement</p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="additional">
                                                                    <div className='text-center ch-nav-icon'>
                                                                        <img src='/images/ch-icon-3.png' alt='/' />
                                                                        <p>Additional Posting</p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="promotion">
                                                                    <div className='text-center ch-nav-icon'>
                                                                        <img src='/images/ch-icon-4.png' alt='/' />
                                                                        <p>Promotion</p>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                </div>

                                                <div className='row mt-sm-3'>
                                                    <div className='col-md-12'>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="subsription">
                                                                <div className='row '>
                                                                    <div className='col-md-10'>
                                                                        <div className='dash-chart'>
                                                                            <ReactApexChart height={350} options={options} series={options.series} type="bar" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="advertisement">
                                                                <div className='row '>
                                                                    <div className='col-md-10'>
                                                                        <div className='dash-chart'>
                                                                            <ReactApexChart height={350} options={optionss} series={optionss.series} type="bar" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="additional">
                                                                <div className='row '>
                                                                    <div className='col-md-10'>
                                                                        <div className='dash-chart'>
                                                                            <ReactApexChart height={350} options={optionsz} series={optionsz.series} type="bar" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="promotion">
                                                                <div className='row '>
                                                                    <div className='col-md-10'>
                                                                        <div className='dash-chart'>
                                                                            <ReactApexChart height={350} options={optionsa} series={optionsa.series} type="bar" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>

                                        {/* <Nav variant="tabs" defaultActiveKey="/">
                                            <Nav.Item>
                                                <Nav.Link href="/">Active</Nav.Link>
                                            </Nav.Item>
                                          
                                            <Nav.Item>
                                                <Nav.Link eventKey="link-1">Option 2</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="disabled" disabled>
                                                    Disabled
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav> */}
                                    </div>

                                    {/* <div class="col-md-6">
                                        <div class="seller-box">
                                            <div class="seller-bottomimg">
                                                <div class="row">
                                                    <div class="col-md-12">

                                                        <div class="table-responsive">
                                                            <Table className="seller-table sec-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={4}>Recently Added - Top 5 Users</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {userData.slice(0, 5).map((item, key) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="user-pics">
                                                                                            <img
                                                                                                src={API_END_URLS.S3Bucket + item?.profile_url || "images/table-user1.png"} alt=''
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {item?.name}
                                                                                        <br />
                                                                                        {item?.email}
                                                                                    </td>
                                                                                    <td>{item?.country}</td>
                                                                                    <td>
                                                                                        <span class="badge green-badge">
                                                                                            Standard
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="border-top">
                                                    <h4 style={{ cursor: "pointer" }} onClick={() => navigate('/users')}>
                                                        View Full Stats
                                                    </h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="seller-box">
                                            <div class="seller-bottomimglast">
                                                <div class="row">
                                                    <div class="col-md-12">

                                                        <div class="table-responsive">
                                                            <Table className="seller-table sec-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={4}>Most Active - Top 5 Users</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user1.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p>  </td>
                                                                        <td>UAE</td>
                                                                        <td><span class="badge green-badge">Standard</span></td>
                                                                    </tr>
                                                                    <tr>

                                                                        <td><div class="user-pics"><img src="images/table-user2.png" alt='' /> </div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p>  </td>
                                                                        <td>India</td>
                                                                        <td><span class="badge red-badge">Premium</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user3.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p> </td>
                                                                        <td>Australia</td>
                                                                        <td><span class="badge red-badge">Premium</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user4.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p></td>
                                                                        <td>UAE</td>
                                                                        <td><span class="badge green-badge">Standard</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><div class="user-pics"><img src="images/table-user5.png" alt='' /></div></td>
                                                                        <td>Lorem Ipsum <p>Lorem@ipsum.com</p> </td>
                                                                        <td>UAE</td>
                                                                        <td><span class="badge green-badge">Standard</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="border-top">
                                                    <h4 style={{ cursor: "pointer" }}>View Full Stats</h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal
                className="new-model"
                size="md"
                show={userShow}
                onHide={() => setuserShow(false)}
                aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton >
                    <h1 className="new-pophead" >Users Globality</h1>
                </Modal.Header>
                <Modal.Body >
                    <div class="globality">
                        <div class="form-group position-relative">
                            <input
                                type="email"
                                class="form-control serch-input"
                                placeholder="Search"
                                onChange={event => searchCountry(event.target.value)}
                            />
                            <img src="images/search-img.png" class="search-img" alt="/" />
                        </div>
                        <div class="country-globe-ranks">
                            <div class="row align-items-center">
                                {
                                    country?.length != 0 ? country?.map((item, key) =>
                                    (
                                        <>
                                            <div class="col-md-8">
                                                <div class="country-ranks">
                                                    <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                    <h2>{item?.country}</h2>
                                                </div>
                                            </div>
                                            <div class="col-md-4 text-right">
                                                <div class="ranks">
                                                    <h1>{item?.count}</h1>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    ) :
                                        <>
                                            <div class="col-md-8">
                                                <div class="country-ranks">
                                                    <h2>Data not found</h2>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                className="new-model"
                size="md"
                show={subsShow}
                onHide={() => { setsubsShow(false) }}
                aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header closeButton >
                    <h1 className="new-pophead" >Subscriptions Globality</h1>
                </Modal.Header>
                <Modal.Body >
                    <div class="globality">
                        <div class="form-group position-relative">
                            <input
                                type="text"
                                class="form-control serch-input"
                                placeholder="Search"
                                onChange={event => searchCountry(event.target.value)}
                            />
                            <img src="images/search-img.png" class="search-img" alt="/" />
                        </div>
                        <div class="country-globe-ranks">
                            <div class="row align-items-center">
                                {
                                    country?.length != 0 ? country?.map((item, key) =>
                                    (
                                        <>
                                            <div class="col-md-8">
                                                <div class="country-ranks">
                                                    <img src={`/images/flgs/${item?.sortcode.toLowerCase()}.svg`} alt='' />
                                                    <h2>{item?.country}</h2>
                                                </div>
                                            </div>
                                            <div class="col-md-4 text-right">
                                                <div class="ranks">
                                                    <h1>{item?.subscriptionCount}</h1>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    ) : <>
                                        <div class="col-md-8">
                                            <div class="country-ranks">
                                                <h2>Data not found</h2>
                                            </div>
                                        </div>

                                    </>
                                }
                            </div>
                        </div>
                    </div>



                </Modal.Body>
            </Modal> */}



        </>
    );
}

export default Dashboard;