import React from "react";

export default function InputFields({
  props,
  value,
  className,
  type,
  id,
  name,
  placeholder,
  onChange,
  inputRef,
  style = {},
}) {
  return (
    <div className={`${className || "mt-3"} form-group`}>
      <input
        {...props}
        type={type || "text"}
        value={value}
        className="form-control content-input"
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        id={id}
        ref={inputRef}
        style={{ ...style }}
      />
    </div>
  );
}
