import React, { useEffect, useState } from 'react';
import Header from '../../../include/header';
import Sidemenu from '../../../include/side-manu';
import { Link, useNavigate } from "react-router-dom";
import { API_END_URLS } from '../../../../config/endUrls';
import { ApiRequest, formRequestToRaw } from '../../../../config/apiRequest';
import { showMessage } from '../../../../helpers/toast';
import { setGlobalState } from '../../../../redux/dispatch';




function ProductCategoryCreate() {

    /* Images State */
    // const [icon, setIcon] = useState({ iconImagerender: 'images/bg.png', name: null })

    /* Component state */
    const navigate = useNavigate()
    const [state, setState] = useState({ data: { "product_categories": [] } });
    // const [productCategories, setProductCategories] = useState([]);

    // const handleUploadIcon = async () => {
    //     const fileData = new FormData();
    //     let fileJson = {};
    //     fileData.append("file", icon["icon"]);
    //     let fname = icon["icon"]["name"];
    //     fileJson[fname] = "icon/" + fname;
    //     fileData.append("jsondata", JSON.stringify(fileJson));
    //     const { success, message, data } = await ApiRequest({ endUrl: API_END_URLS.fileUpload, method: "post", body: fileData, "headerType": "file" })
    //     if (success) {
    //         showMessage("Header Images Created SuccessFully");
    //         return fname
    //     } else {
    //         showMessage(message);
    //         return null;
    //     }

    // }

    /* Handle api's request */
    const handleSubmit = async (e) => {
        e.preventDefault()


        // let res = await handleUploadIcon()
        // if (!res) return
        let formData = new FormData(e.target)
        let rawReq = formRequestToRaw(formData)
        // rawReq.icon = res


        /* Validate user fields */
        // let validateResponse = objectValidate(rawReq)
        // if (!validateResponse.isValid) {
        //     return showMessage(validateResponse.msg)
        // }

        setGlobalState({ loader: true })
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productCategoryCreate, method: "post", body: rawReq, token: true })
        setGlobalState({ loader: false })
        if (success) {
            showMessage(message)
            navigate("/categories/product-categories")
        } else {
            showMessage(message)
        }
    }


    /* Icon Image Save Function */
    // const saveHeaderImage = (e, key, flg) => {
    //     let type = ['image/gif', 'image/jpeg', 'image/png'];
    //     const file = e.target.files[0];
    //     const fileSize = e.target.files[0].size / 1024;
    //     if (e.target.files.length > 0) {
    //         if (type.indexOf(file.type) < 0) {
    //             showMessage("Please upload correct file type: png, jpeg");
    //             return;
    //         }
    //         if (fileSize < 50 && fileSize < 2000) {
    //             showMessage("Please upload file size must be between 200KB and 2MB");
    //             return false;
    //         }
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             let nab = { ...icon };
    //             nab["icon"] = file;
    //             nab["iconImagerender"] = e.target.result;
    //             setIcon(nab);

    //         };

    //         reader.readAsDataURL(e.target.files[0]);
    //     }

    //     return true;
    // };

    const getProductCategories = async () => {
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productParentCategories, method: "get", token: true })
        if (success) {
            setState({ ...state, data: { "product_categories": data["product_categories"] } });
        } else {
            showMessage(message);
        }
        // setProductCategories(data["product_categories"]);
    }


    useEffect(() => {
        getProductCategories();
    }, [])



    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">

                                <div class="row ">
                                    <div class="col-md-6">
                                        <div class="head-text user-headtext add-text">
                                            <h1>Category Add</h1>
                                        </div>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <Link to="/categories/product-categories"> <button type="button" class="btn subscription-btn">Back</button></Link>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="border-bottom pb-2"></div>
                                    </div>
                                </div>

                                <form method="POST" onSubmit={handleSubmit}>
                                    <div class="row mt-3">
                                        {/* <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Category</label>
                                                <select name="parent_id" class="form-control" >
                                                    <option value="">Select</option>
                                                    {productCategories.map((item, key) => {
                                                        return (
                                                            <option value={item.id}>{item.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div> */}
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Name</label>
                                                <input type="text" name="name" class="form-control" placeholder="Enter Name" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Alias</label>
                                                <input type="text" name="alias" class="form-control" placeholder="Enter Alias" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Descripition</label>
                                                <input type="text" name="description" class="form-control" placeholder="Enter Alias" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="">Category Type</label>
                                                <select name="category_type" class="form-control" >
                                                    <option value="">Select</option>
                                                    <option value="Product">Product</option>
                                                    <option value="Service">Service</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="" >Status</label>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <input class="filled-in" name="status" type="radio" value="active" id="Check" checked />
                                                        <label class="checkFrom-label" for="Check">Active</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input class="filled-in" name="status" type="radio" value="inactive" id="Check2" />
                                                        <label class="checkFrom-label" for="Check2">Inactive</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 mb-3 mb-sm-0">
                                            <div class="contentimg-box">
                                                <img src={icon['iconImagerender']} alt='' />
                                                <div class="upload-btn-wrapper edit-upload  newedit-upload">
                                                    <button class="uploadBtn edit-userallow">
                                                        <img src="images/sparess-camera.svg" alt='' />
                                                    </button>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => saveHeaderImage(e)}
                                                    />

                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 text-right">
                                            <Link to="/categories/product-categories"><button type="button" class="btn details-btn mr-2">Back</button></Link>
                                            <button type="submit" class="btn subscription-btn ">Submit</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductCategoryCreate;