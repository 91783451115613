import React, { useEffect, useState } from 'react';
import Header from '../../../include/header';
import Sidemenu from '../../../include/side-manu';


import { Link, useNavigate } from "react-router-dom";
import { API_END_URLS } from '../../../../config/endUrls';
import { ApiRequest } from '../../../../config/apiRequest';
import { showMessage } from '../../../../helpers/toast';
import { setGlobalState } from '../../../../redux/dispatch';
import { useFormik } from 'formik';
import * as Yup from 'yup';


function ProductSubCategoryCreate() {

    const navigate = useNavigate()

    const initialValues = {
        ProductCategoryId: null,
        name: null,
        status: null,
    }

    const validationSchema = Yup.object().shape({
        ProductCategoryId: Yup.string().required("Please Select  Category"),
        name: Yup.string().min(10).max(60).required("Please Enter Sub Category"),
        status: Yup.string().required("Please choose  status"),
    })



    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                let req = {
                    ProductCategoryId: values.ProductCategoryId,
                    name: values.name,
                    status: values.status
                }
                setGlobalState({ loader: true })
                const { data, message, success } = await ApiRequest({ endUrl: API_END_URLS.productSubCategoryCreate, method: "post", body: req })
                setGlobalState({ loader: false })
                if (success) {
                    showMessage(message)
                    navigate("/subcategories/product-subcategories")
                } else {
                    showMessage(message)
                }

            } catch (err) {
                showMessage(err.message)
                console.log("🚀 ~ file: SubCategoryCreate.js:55 ~ onSubmit: ~ err:", err)
            }

        }
    })


    const { handleSubmit, getFieldProps, touched, errors, setFieldValue } = formik

    const [productCategories, setProductCategories] = useState([]);

    const getProductCategories = async () => {
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.productCategories, method: "get", token: true })
        if (success) {
            setProductCategories(data.product_categories.rows);
        } else {
            showMessage(message);
        }

    }


    useEffect(() => {
        getProductCategories();
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div className="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div className="main-box">
                            <div className="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div className="pageinnner-padding">

                                <div className="row ">
                                    <div className="col-md-6">
                                        <div className="head-text user-headtext add-text">
                                            <h1>Add Sub Category </h1>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        {/* <Link to="/subcategories/product-subcategories"> <button type="button" className="btn subscription-btn">Back</button></Link> */}
                                    </div>

                                    <div className="col-md-12">
                                        <div className="border-bottom pb-2"></div>
                                    </div>
                                </div>


                                <form onSubmit={handleSubmit}>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Select Category</label>
                                                <select
                                                    style={{ cursor: 'pointer' }}
                                                    className="form-control"
                                                    {...getFieldProps('ProductCategoryId')}
                                                >
                                                    <option value="">Select</option>
                                                    {productCategories.map((item, key) => {
                                                        return (
                                                            <option value={item.id}>{item.name}</option>
                                                        );
                                                    })}
                                                </select>
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.ProductCategoryId && errors.ProductCategoryId) && errors.ProductCategoryId}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="" >Sub Category</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Sub Category"
                                                    {...getFieldProps('name')}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {Boolean(touched.name) && errors.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="" >Status</label>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <input
                                                            className="filled-in"
                                                            type="radio"
                                                            id="Check"
                                                            onChange={(e) => setFieldValue('status', 'Active')}
                                                        />
                                                        <label className="checkFrom-label" for="Check">Active</label>
                                                        <div style={{ color: "red" }}>
                                                            {Boolean(touched.status) && errors.status}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input
                                                            className="filled-in"
                                                            type="radio"
                                                            id="Check2"
                                                            onChange={(e) => setFieldValue('status', 'Inactive')}
                                                        />
                                                        <label className="checkFrom-label" for="Check2">Inactive</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <Link to="/subcategories/product-subcategories">
                                                <button type="button" className="btn details-btn mr-2">
                                                    Back
                                                </button>
                                            </Link>
                                            <button
                                                type="submit"
                                                className="btn subscription-btn"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductSubCategoryCreate;