import React, { useEffect, useState } from 'react';
import Header from '../../include/header';
import Sidemenu from '../../include/side-manu';
import Table from 'react-bootstrap/Table';

import { Link } from "react-router-dom";
import { ApiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { showMessage } from '../../../helpers/toast';
import { Pagination } from '@mui/material';
import { ReviewStatus } from '../../../config/contants';
import ReactStars from 'react-rating-stars-component';
import moment from 'moment';

function Reviews() {


    const [reviews, setReviews] = useState([])
    const [noOfPages, setNoOfPages] = useState(0);

    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
    });

    const featchData = async () => {
        const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.getReviews, method: "get", token: true, query: query })
        if (success) {
            setReviews(data?.review?.rows || [])
            // setNoOfPages(Math.ceil(data?.count / query.limit));
        } else {
            showMessage(message)
        }
    }

    useEffect(() => {
        featchData()
    }, [query])


    const buttubCss = (status) => {
        if (status === ReviewStatus.Reply) {
            return "reply-btn"
        } else if (status === ReviewStatus.Published) {
            return "publish-btn"
        } else if (status === ReviewStatus.Replied) {
            return "replied-btn"

        } else if (status === ReviewStatus.Unpublished) {
            return "unPublished-btn"
        } else if (status === ReviewStatus.Viewed) {
            return "viewed-btn"
        }
    }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 p-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9 col-12 p-0">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div className='row align-items-center mt-sm-0 mt-3'>
                                    <div className='col-md-6 col-6'>
                                        <div class="head-text new-cost-head">
                                            <h1>Reviews & Rating </h1>
                                        </div>
                                    </div>
                                    <div className='col-md-6 text-right col-6'>
                                        <Link to="/help-support/contactus-queries">
                                            <button className='btn sparessfill-btn'>
                                                Help & Support
                                            </button>
                                        </Link>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='divider-line'></div>
                                    </div>
                                </div>


                                <div class="row mt-0 mt-sm-3">
                                    <div class="col-md-12">
                                        <div class="table-responsive">
                                            <Table className="manage-table table">
                                                <thead>
                                                    <tr>
                                                        <td class="head-td" >Feedback Title</td>
                                                        <td class="head-td" >Ratings</td>
                                                        <td class="head-td" >Status</td>
                                                        <td class="head-td" >Feedback Date & Time</td>
                                                        <td class="head-td" >Action</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        reviews.map((item, key) => {
                                                            return (
                                                                <tr>
                                                                    <td>{item.review_title}</td>
                                                                    <td>
                                                                        <ReactStars classNames="react-stars"
                                                                            name="review_rating"
                                                                            value={parseInt(item?.review_rating)}
                                                                            count={5}
                                                                            size={24}
                                                                            isHalf={true}
                                                                            emptyIcon={<i className="far fa-star"></i>}
                                                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                            fullIcon={<i className="fa fa-star"></i>}
                                                                            activeColor="#69A227"
                                                                            edit={false}
                                                                        />
                                                                        {/* {
                                                                            [1, 2, 3, 4, 5].map((value) =>
                                                                                <img src={item?.review_rating >= value ? "images/fill-star.png" : "images/star.png"} alt='' />)
                                                                        } */}
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge ${buttubCss(item.status)}`}>
                                                                            {item.status}
                                                                        </span>
                                                                    </td>
                                                                    <td>{moment(item?.createdAt).format('MM-DD-YY hh:mm A')}</td>

                                                                    <td> <Link to={`/review-feedback-edit/${item.id}`}>
                                                                        <button type="button" class="btn btn-edit">
                                                                            Details
                                                                        </button>
                                                                    </Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                            <div class="row justify-content-end">
                                                <div class="text-right mr-3">
                                                    {
                                                        noOfPages ?
                                                            <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="primary" onChange={(e, page) => {
                                                                setQuery({ ...query, page: page });
                                                            }} />
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Reviews;