import React, { useEffect, useState } from "react";
import Header from "../../../include/header";
import Sidemenu from "../../../include/side-manu";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal } from "react-bootstrap";

import { Link, useParams } from "react-router-dom";
import { API_END_URLS } from "../../../../config/endUrls";
import { ApiRequest } from "../../../../config/apiRequest";
import { showMessage } from "../../../../helpers/toast";
import { Pagination } from "@mui/material";

function ProductCategoryList() {


  const [productCategories, setProductCategories] = useState([]);
  // const [productCategoryView, setProductCategoryView] = useState([])
  const [noOfPages, setNoOfPages] = useState(0);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  const getProductListing = async () => {
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.productParentCategories,
      method: "get",
      token: true,
    });
    if (success) {
      setProductCategories(data);
    } else {
      showMessage(message);
    }
  };

  // const getCategoryView = async (id) => {
  //   const { data, success, message } = await ApiRequest({
  //     endUrl: API_END_URLS.categoryView+"/"+id,
  //     method: "get",
  //     params: params,
  //   });
  //   if (success) {
  //     setProductCategoryView(data);
  //   } else {
  //     showMessage(message);
  //   }
  // };

  // useEffect(() => {
  //   const getProductCategories = async () => {
  //     const { data, success, message } = await ApiRequest({
  //       endUrl: API_END_URLS.productCategories,
  //       method: "get",
  //       token: true,
  //       query: query,
  //     });
  //     setState({
  //       ...state,
  //       data: { product_categories: data.product_categories.rows },
  //     });
  //     setProductCategories(data.product_categories.rows);
  //     setNoOfPages(Math.ceil(data.product_categories.count / query.limit));
  //   };

  //   getProductCategories();
  // }, [query]);

  // const deleteRecord = async (id) => {
  //   let permision = window.confirm(
  //     "Are you sure you want to delete the category"
  //   );
  //   if (!permision) {
  //     return;
  //   }
  //   const { data, success, message } = await ApiRequest({
  //     endUrl: "v1/admin/product-category/" + id,
  //     method: "delete",
  //     token: true,
  //   });
  //   showMessage(message);
  //   const remainingProductCategories = productCategories.filter(
  //     (result) => result.id !== id
  //   );
  //   setProductCategories(remainingProductCategories);
  // };

  useEffect(() => {
    getProductListing();
    // getCategoryView()
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div className="row align-items-center mt-sm-0 mt-3">
                  <div className="col-md-9 col-12">
                    <div class="head-text new-cost-head">
                      <h1>Category Manager</h1>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <Link to="/bulk-product-add">
                      <button
                        type="button"
                        class="btn btn-block btn-analy btn-analy-2"
                      >
                        <img src="/images/plus-btn.svg" alt="/" />
                        ADD SUB CATEGORY
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="divider-line"></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <Table className="manage-table table">
                            <thead>
                              <tr>
                                <td class="head-td">ID</td>
                                <td class="head-td">Category</td>
                                <td class="head-td">Product </td>
                                <td class="head-td">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {productCategories?.map((item) => {
                                console.log("🚀 ~ file: categoryList.js:147 ~ {productCategories?.map ~ item:", item)
                                return (
                                  <tr>
                                    <td>{item?.id}</td>
                                    <td>{item?.category_name}</td>
                                    <td>
                                      {item?.CategoryProducts?.length} 
                                      {" "}

                                      {item?.CategoryProducts?.length > 1
                                        ? "Products"
                                        : "Product"}
                                    </td>
                                    <td>
                                      <Link to={`/product/categories-listing/${item?.id}`}>
                                        <button className="btn btn-edit">
                                          View
                                        </button>{" "}
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div class="row justify-content-end">
                          <div class="text-right mr-3">
                            {noOfPages ? (
                              <Pagination
                                count={noOfPages}
                                defaultPage={1}
                                siblingCount={2}
                                boundaryCount={2}
                                variant="outlined"
                                shape="rounded"
                                color="success"
                                onChange={(e, page) => {
                                  setQuery({ ...query, page: page });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCategoryList;
