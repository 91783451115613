import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";
import Header from "../../include/header";
import Sidemenu from "../../include/side-manu";
import { S3Bucket, ReviewStatus } from "../../../config/contants";
import moment from "moment";

function Feedback() {
  const navigate = useNavigate();

  /* Component Show And Hide*/
  const [showBox, setShowBox] = useState(false);

  const onClick = () => {
    setShowBox(true);
  };

  const params = useParams();

  const [feedback, setFeedback] = useState({
    admin_comment: "",
  });

  const handleSubmit = async () => {
    let req = {
      admin_comment: feedback.admin_comment,
      status: ReviewStatus.Replied,
    };
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.reviewUpdate,
      method: "put",
      body: req,
      params: params,
    });
    if (success) {
      showMessage(message);
      navigate("/reviews");
    } else {
      showMessage(message);
    }
  };

  const fetchFeedbackData = async () => {
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.reviewEdit,
      method: "get",
      params: params,
      token: true,
    });
    if (success) {
      if (data?.review?.status === ReviewStatus.Reply) {
        handlePublish(ReviewStatus.Viewed, data.review);
      } else {
        setFeedback(data.review);
      }
    } else {
      showMessage(message);
    }
  };

  const handlePublish = async (status, extraInfo = null) => {
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.reviewPublish,
      method: "put",
      body: { status },
      params: params,
    });
    if (success) {
      if (extraInfo) {
        setFeedback({ ...extraInfo, status });
      } else {
        setFeedback({ ...feedback, status });
      }

      showMessage(message);
    } else {
      showMessage(message);
    }
  };

  useEffect(() => {
    fetchFeedbackData();
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">

              <div class="row justify-content-between">
                  <div class="col-md-6">
                    <div class="head-text user-headtext add-text mt-sm-0 mt-3">
                      <h1>Feedback Title - Details</h1>
                    </div>
                  </div>
                  <div class="col-md-1 text-right mt-2 mt-sm-0 col-3">
                    <Link to="/reviews">
                      <button type="button" class="btn btn-analy btn-block">
                        <img
                          className="mr-0"
                          src="/images/back-arrow.svg"
                          alt="/"
                        />
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="border-bottom pb-2"></div>
                  </div>
                </div>
                <div class="row mt-3 mt-sm-4">
                  <div class="col-md-12 feed-reviews-area">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="profile-feed d-flex">
                          <div class="somya-img">
                            <img
                              src={
                                S3Bucket + feedback?.User?.profile_url === null 
                                  ? S3Bucket + feedback?.User?.profile_url
                                  : "/images/somya-img.png"
                              }
                              alt="/"
                            />  
                          </div>
                          <div>
                            <h3>{feedback?.User?.name}</h3>
                            <p>{moment(feedback?.createdAt).format("DD MMMM YYYY")}</p>
                          </div>
                        </div>
                        <div class="feed-descrip">
                          <h5 class="feed-rate-icon">
                            {feedback?.hasOwnProperty("review_rating") && (
                              <ReactStars
                                name="review_rating"
                                value={parseInt(feedback?.review_rating)}
                                count={5}
                                size={24}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={
                                  <i className="fa fa-star-half-alt"></i>
                                }
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#69A227"
                                edit={false}
                              />
                            )}
                          </h5>
                          <h6>“{feedback?.review_title}” </h6>
                          <p>{feedback?.review_description},</p>
                        </div>

                        <div class="descrip-img-selector d-flex">
                          {feedback?.ReviewImages?.length > 0 &&
                            feedback?.ReviewImages[0].rating_images.map(
                              (img) => (
                                <img
                                  src={
                                    S3Bucket +
                                    "rating/" +
                                    feedback.id +
                                    "/" +
                                    img
                                  }
                                  alt="/"
                                />
                              )
                            )}
                        </div>
                      </div>
                      {showBox ? (
                        <div class="col-md-12">
                          <div class="button-field"></div>
                          <p className="feed-text">Response On feedback</p>

                          <div className="row">
                            <div className="col-md-7">
                              <div class="form-group mt-3">
                                <textarea
                                  maxLength={200}
                                  class="form-control content-inputsubscription"
                                  wrap="off"
                                  rows="2"
                                  name="admin_comments"
                                  value={feedback.admin_comment}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 200) {
                                      setFeedback({
                                        ...feedback,
                                        admin_comment: e.target.value,
                                      });
                                    }
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="button"
                              class="btn sparessfill-btn new-save"
                              onClick={handleSubmit}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div class="col-md-12">
                          <div class="button-field">
                            <button
                              type="button"
                              class="btn sparessborder-btn new-cancel feedback-new mr-1"
                              onClick={onClick}
                            >
                              <img src="/images/respond-icon.png" alt="" />
                              Respond
                            </button>
                            <button
                              type="button"
                              class="btn sparessfill-btn new-save ml-3"
                              onClick={() => {
                                handlePublish(
                                  feedback.status === ReviewStatus.Published ||
                                    feedback.status === ReviewStatus.Replied
                                    ? ReviewStatus.Unpublished
                                    : ReviewStatus.Published
                                );
                              }}
                            >
                              <img src="/images/publish-icon.png" alt="" />
                              {feedback.status === ReviewStatus.Published ||
                              feedback.status === ReviewStatus.Replied
                                ? ReviewStatus.Unpublished
                                : ReviewStatus.Published}
                            </button>

                            {/* <button
                              type="button"
                              class="btn sparessborder-btn new-cancel  mr-1 "
                              onClick={onClick}
                            >
                              Reply with Message
                            </button> */}
                            {/* <button
                              type="button"
                              class="btn sparessfill-btn new-save "
                            >
                              Reply with Email
                            </button> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedback;
