// import React, { useState } from "react";
// import Header from "../../include/header";
// import Sidemenu from "../../include/side-manu";
import { Modal } from "react-bootstrap";
// import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Nav from "react-bootstrap/Nav";
// import { SERVER_URL } from '../../../config/contants';

import React, { useEffect, useState } from "react";
import Header from "../../include/header";
import Sidemenu from "../../include/side-manu";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import { ApiRequest } from '../../../config/apiRequest';
import { Link } from "react-router-dom";
// import { API_END_URLS } from '../../../config/endUrls';
// import { showMessage } from '../../../helpers/toast';
// import { useSelector } from 'react-redux';
// import { setGlobalState } from '../../../redux/dispatch';
// import { Pagination } from '@mui/material';
import moment from "moment";

import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
// import { useEffect } from "react";
import { showMessage } from "../../../helpers/toast";
import { Pagination } from "@mui/material";
// import moment from "moment";
import { TransactionStatus } from "../../../config/contants";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const cureentYear = parseInt(moment().year());

function Analytic() {
  const [analyticData, setAnalyticData] = useState(null);

  const [sh, setSh] = useState(false);
  const handleClo = () => setSh(false);
  const handleSh = () => setSh(true);

  const [state, setState] = useState({ data: [], count: 0 });

  const [noOfPages, setNoOfPages] = useState(0);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    text: "",
  });

  const [lgShow, setLgShow] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fecthTransactionHistory = async (page = 1, text = "") => {
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.getTransactionHistory,
      method: "get",
      query: { ...query, page, searchText: text },
    });
    console.log(
      "🚀 ~ file: Analytic.jsx:53 ~ fecthTransactionHistory ~ data:",
      data
    );
    if (success) {
      setState({
        ...state,
        data: data?.transactions?.rows,
        count: data?.transactions?.count,
      });
      setNoOfPages(Math.ceil(data?.transactions?.count / query.limit));
      setQuery({ ...query, page, text });
    } else {
      showMessage(message);
    }
  };

  const transactionGraph = async (params) => {
    let x = [],
      y = [];
    const { data, success, message } = await ApiRequest({
      endUrl: API_END_URLS.transactionGraph,
      method: "get",
      query: params,
    });
    if (success) {
      data.map((item) => {
        x.push(moment(item.month, "M").format("MMM"));
        y.push(item.count);
      });
      setAnalyticData({
        labels: x,
        datasets: [
          {
            label: "Revenue",
            data: y,
            background:
              "linear-gradient(180deg, #FF5501 50%, rgba(255, 153, 1, 0) 100%);",
            borderColor: "#69A227",
            tension: 0.4,
            fill: true,
            pointStyle: "round",
            pointBorderColor: "#69A227",
            pointBackgroundColor: "#69A227",
            showLine: true,
          },
        ],
      });
    } else {
      showMessage(message);
    }
  };

  const handleOnChange = (e) => {
    transactionGraph({ year: e.target.value });
  };

  useEffect(() => {
    fecthTransactionHistory();
    transactionGraph();
  }, []);

  const buttubCss = (status) => {
    if (status === TransactionStatus.Failed) {
      return "failed-btn";
    } else if (status === TransactionStatus.succeeded) {
      return "succeeded-btn";
    } else if (status === TransactionStatus.Pending) {
      return "pending-btn";
    }
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="subsription"
                >
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <div class="head-text cost-head new-cost-head mt-sm-0 mt-3">
                        <h1>Cost - Analytics</h1>
                      </div>
                    </div>

                    <div className="col-md-4 p-sm-0">
                      <Nav className="user-tabs cost-tab justify-content-end pb-0">
                        <Nav.Item>
                          <Nav.Link eventKey="subsription">
                            Subscription Plan
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="advertisement">
                            Advertisement
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="col-md-3 mt-sm-0 mt-3">
                      <div className="row">
                        <div className="col-md-8 pr-0 col-9">
                          <Link to="/analytic">
                            <button
                              type="button"
                              class="btn btn-block btn-analy"
                              to="/analytic"
                            >
                              <img src="images/analy-icon.svg" alt="/" />
                              Analytics
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-4 col-3">
                          <Link to="/addform">
                            <button
                              type="button"
                              class="btn btn-block btn-analy"
                              to="/addform"
                            >
                              {" "}
                              <img
                                className="mr-0"
                                src="images/plus-icon.svg"
                                alt="/"
                              />
                            </button>
                          </Link>

                          {/* <div class="text-right">
                                                        <a href={url}>
                                                            <img
                                                                src="images/new-cloud.svg"
                                                                className="cloud-img mr-2"
                                                                alt=''
                                                            />
                                                        </a>
                                                        <img src="images/new-eyes.svg" onClick={() => setLgShow(true)} className="cloud-img mr-2" alt='' />
                                                        <Link to="/addform">  <button type="button" class="btn subscription-btn">Add</button> </Link>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="divider-line"></div>
                    </div>
                  </div>
                </Tab.Container>

                {/* <div class="head-text">
                                    <h1>Cost Manager Analytics Dashboard</h1>
                                </div> */}

                {/* <div class="row mt-4">
                                    <div class="col-md-8 col-9">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control serch-input"
                                                placeholder="Search "
                                                onChange={(e) => fecthTransactionHistory(1, e.target.value)}
                                            />
                                            <img src="images/search-img.png" class="search-img" alt="/" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-3">
                                        <div class="filter-box" >
                                            <img src="images/filter-img.png" alt="/" />
                                        </div>
                                    </div>
                                </div> */}

                <div class="revenue-chart-box">
                  <div class="total-revenue d-flex align-items-center justify-content-between">
                    <div class="head-text">
                      <h1>Total Revenue (In US $)</h1>
                    </div>

                    <div class="y-drops">
                      <div class="form-group">
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          onChange={handleOnChange}
                        >
                          <option value={cureentYear}>{cureentYear}</option>
                          <option value={cureentYear - 1}>
                            {cureentYear - 1}
                          </option>
                          <option value={cureentYear - 2}>
                            {cureentYear - 2}
                          </option>
                        </select>
                      </div>
                      <div class="export-img ml-4">
                        <a href="#">
                          <img
                            onClick={handleSh}
                            src="images/export-icon.png"
                            alt="/"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="revenue-chart">
                    <div className="row">
                      <div className="col-md-12">
                        {analyticData && <Line data={analyticData}>Hello</Line>}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="head-content mt-5">
                  <p>Transaction History</p>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <Table className="buy-table mb-0">
                        <thead>
                          <tr>
                            <td class="head-td">User ID</td>
                            <td class="head-td">Transaction ID</td>
                            <td class="head-td">Transaction Date / Time</td>
                            {/* <td class="head-td" >Credit Card Info</td> */}
                            {/* <td class="head-td" >Card Expiry Date</td> */}
                            <td class="head-td">Transaction Type</td>
                            <td class="head-td">Transaction Status</td>
                            <td class="head-td">Currency</td>
                            <td class="head-td">Amount</td>
                            <td class="head-td">Action</td>
                          </tr>
                        </thead>

                        <tbody>
                          {state?.data?.map((item, key) => {
                            return (
                              <tr>
                                <td>{item?.User?.email}</td>
                                <td>{item?.customer_Id}</td>
                                <td>
                                  {moment(item?.createdAt).format(
                                    "MMMM DD, YYYY, hh:mm A"
                                  )}
                                </td>

                                <td>
                                  {item?.planInfo?.subscription_type}
                                  <br />
                                  {item?.planInfo?.period}
                                </td>
                                <td>
                                  <span
                                    className={`badge ${buttubCss(
                                      item.status
                                    )}`}
                                  >
                                    {item.status}
                                  </span>
                                </td>
                                <td>{item?.currency}</td>
                                <td>
                                  <span class="colored">{item?.amount}$</span>
                                </td>

                                <td>
                                  <DropdownButton className="new-drop">
                                    <Dropdown.Item href="#/action-1">
                                      Edit User
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      Send Notification
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      Block User
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                      Send E-mail
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-5">
                                      Send Message{" "}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-6">
                                      Chat with User
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="row justify-content-end">
                      <div className="text-right mr-3">
                        {noOfPages ? (
                          <Pagination
                            style={{ marginTop: "20px" }}
                            count={noOfPages}
                            variant="outlined"
                            shape="rounded"
                            color="success"
                            onChange={(e, page) => {
                              fecthTransactionHistory(page, query.text);
                            }}
                          />
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="new-model"
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <h1 class="new-pophead">User - Stats & Analytics</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <Table className="pop-table">
              <tbody>
                <tr>
                  <td>User Name</td>
                  <td>Vinay Kumar</td>
                  <td>User Email Id</td>
                  <td>User@spare.com</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>Vinay Kumar</td>
                  <td>Credit Card Number</td>
                  <td>
                    3 ＊＊＊ ＊＊＊＊ ＊＊＊＊ 9671{" "}
                    <img src="images/eyes-picnew.svg" className="ml-2" />{" "}
                  </td>
                </tr>
                <tr>
                  <td>Card Expiry Date</td>
                  <td>10/25</td>
                  <td>Subscription Type</td>
                  <td>Standard Yearly</td>
                </tr>
                <tr>
                  <td>Subscription Start Date</td>
                  <td>03/07/2023</td>
                  <td>Subscription End Date</td>
                  <td>Standard Yearly</td>
                </tr>
                <tr>
                  <td>No. of Days left for Subscription Exp.</td>
                  <td>15 Days</td>
                  <td>Free Product/Service Posted</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>Additional Product/ Service Posted</td>
                  <td>05</td>
                  <td>Free Video Adverts Posted</td>
                  <td>05</td>
                </tr>
                <tr>
                  <td>Additional Video Adverts Posted</td>
                  <td>03</td>
                  <td>Free Banner Adverts Posted</td>
                  <td>03</td>
                </tr>
                <tr>
                  <td>Additional Banner Adverts Posted</td>
                  <td>04</td>
                  <td>Free Promotions Posted</td>
                  <td>04</td>
                </tr>
                <tr className="last-row">
                  <td>Additional Promotions Posted</td>
                  <td>15</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        className="filter-popup modal-dialog-slideout"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div class="pop-head">
              <h1>Filters</h1>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Example select</label>
                <select class="form-control" id="exampleFormControlSelect1">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        className="new-model chart-pop-1"
        show={sh}
        onHide={handleClo}
        size="lg"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="row text-center justify-content-center">
            <div className="col-md-11">
              <div class="revenue-chart-box">
                <div class="total-revenue d-flex align-items-center justify-content-between">
                  <div class="head-text">
                    <h1>Total Revenue (In US $)</h1>
                  </div>

                  <div class="y-drops">
                    <div class="form-group">
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        onChange={handleOnChange}
                      >
                        <option value={cureentYear}>{cureentYear}</option>
                        <option value={cureentYear - 1}>
                          {cureentYear - 1}
                        </option>
                        <option value={cureentYear - 2}>
                          {cureentYear - 2}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="revenue-chart">
                  <div className="row">
                    <div className="col-md-12">
                      <Line height="200px" data={analyticData}>
                        Hello
                      </Line>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Analytic;
