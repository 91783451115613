import React, { useEffect, useState } from "react";
import Sidemenu from "../../../include/side-manu";
import Header from "../../../include/header";
import { Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ApiRequest } from "../../../../config/apiRequest";
import { API_END_URLS } from "../../../../config/endUrls";
import { showMessage } from "../../../../helpers/toast";

function ProductCategoriesList() {
  const [productListing, setProductListing] = useState([]);

  const params = useParams();

  const fetchProductListing = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.ProductCategoryListing,
        method: "get",
        params: params,
      });
      if (success) {
        setProductListing(data);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: ProductCategoriesList.js:25 ~ fetchProductListing ~ err:",
        err
      );
    }
  };
  useEffect(() => {
    fetchProductListing();
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div class="row justify-content-between">
                  <div class="col-md-8 col-12">
                    <div class="head-text user-headtext add-text my-sm-0 my-3">
                      <h1>Product Listing</h1>
                    </div>
                  </div>
                  <div class="col-md-1 col-3">
                    <Link to="/categories/product-categories">
                      <button type="button" class="btn btn-block btn-analy">
                        <img
                          className="mr-0"
                          src="/images/back-arrow.svg"
                          alt="/"
                        />
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="border-bottom pb-2"></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <Table className="manage-table table">
                            <thead>
                              <tr>
                                <td class="head-td">ID</td>
                                <td class="head-td">Category</td>
                                <td class="head-td">Product Name</td>
                                <td class="head-td">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {productListing.map((item) => {
                                return (
                                  <tr>
                                    <td>{item?.id}</td>
                                    <td>{item?.Category?.category_name}</td>
                                    <td>{item?.product_name}</td>
                                    <td>
                                      <Link
                                        to={`/product-category-edit/${item?.id}`}
                                      >
                                        <button className="btn btn-edit">
                                          Edit
                                        </button>{" "}
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        {/* <div class="row justify-content-end">
                          <div class="text-right mr-3">
                            {noOfPages ? (
                              <Pagination
                                count={noOfPages}
                                defaultPage={1}
                                siblingCount={2}
                                boundaryCount={2}
                                variant="outlined"
                                shape="rounded"
                                color="success"
                                onChange={(e, page) => {
                                  setQuery({ ...query, page: page });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCategoriesList;
