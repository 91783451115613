import React from 'react';

import { Link } from "react-router-dom";


function Verify() {

    return (
        <>
            <section class="login-bg  py-5 ">
                <div class="container-fluid">

                    <div class="row justify-content-center pt-0 pt-lg-5">
                        <div class="col-md-7 col-lg-5">
                            <div class="login-form verfication ">

                                <div class="text-center mt-3 mt-lg-5">
                                    <img src="images/black-logo.png" />
                                    <h1>Dashboard Panel Login</h1>
                                </div>

                                <div class="row justify-content-center  mt-3">
                                    <div class="col-md-9 col-lg-8">
                                        <div class="d-flex justify-content-center">
                                            <input type="text" />
                                            <input type="text" />
                                            <input type="text" />
                                            <input type="text" />
                                            <input type="text" />
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="col-md-10 col-lg-9 mt-4 mt-sm-5 pb-5">
                                       <Link to="/"><button type="button" class="btn login-btn btn-block">Verify</button></Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Verify;