import React, { useEffect, useState } from 'react';
import Header from '../../../include/header';
import Sidemenu from '../../../include/side-manu';
import Table from 'react-bootstrap/Table';


import { Link, useParams } from "react-router-dom";
import { API_END_URLS } from '../../../../config/endUrls';
import { ApiRequest } from '../../../../config/apiRequest';
import { showMessage } from '../../../../helpers/toast';
import { Pagination } from '@mui/material';


function ServiceCategoryList() {

    const params = useParams();

    const [state, setState] = useState({ data: { "service_categories": [] } });
    const [serviceCategories, setServiceCategories] = useState([]);
    const [noOfPages, setNoOfPages] = useState(0);

    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
    });

    useEffect(() => {
        console.log(params);
        const getServiceCategories = async () => {
            const { data, success, message } = await ApiRequest({ endUrl: API_END_URLS.serviceCategories, method: "get", token: true, query: query })

            setState({ ...state, data: { "service_categories": data.service_categories.rows } });
            setServiceCategories(data.service_categories.rows);
            setNoOfPages(Math.ceil(data.service_categories.count / query.limit));
        }

        getServiceCategories();
    }, [query])

    const deleteRecord = async (id) => {
        const { data, success, message } = await ApiRequest({ endUrl: 'v1/admin/service-category/' + id, method: "delete", token: true })
        showMessage(message);

        const remainingServiceCategories = serviceCategories.filter((result) => result.id !== id)
        setServiceCategories(remainingServiceCategories);
    }

    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3 pl-0 d-none d-sm-block">
                        <Sidemenu></Sidemenu>
                    </div>
                    <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div class="main-box">
                            <div class="page-headpaddingbox">
                                <Header></Header>
                            </div>

                            <div class="pageinnner-padding">
                                <div class="head-text user-headtext">
                                    <h1>Manage Categories</h1>
                                </div>

                                <div class="row justify-content-end">
                                <div class="new-seller">
                                        <ul>
                                            <li>
                                                <Link to="/categories/product-categories">
                                                    <button type="button" class="btn">Product</button>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/categories/service-categories">
                                                    <button type="button" class="btn active">Service</button>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">

                                        <div class="text-right mt-3">
                                            <Link to="/categories/service-category/create">  <button type="button" class="btn subscription-btn">Add Category</button> </Link>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <Table className="buy-table">
                                                        <thead>
                                                            <tr>
                                                                <td colSpan={2} class="head-td" >Sr. No</td>
                                                                <td class="head-td" >Name</td>
                                                                <td class="head-td" >Alias</td>
                                                                <td class="head-td" >Status</td>
                                                                <td class="head-td" >Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {serviceCategories.map((item, key) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <tr>
                                                                            <td colSpan={2}>{((query.page - 1) * query.limit) + key + 1}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.alias}</td>
                                                                            <td><span className={"badge " + (item.status == 'active' ? 'standard-btn' : 'premium-btn')}>{item.status}</span></td>
                                                                            <td>
                                                                                <Link to={`/categories/service-category/edit/${item.id}`}><button type="button" class="btn details-btn">Edit</button></Link>
                                                                                <button type="button" class="btn details-btn ml-1" onClick={() => {
                                                                                    deleteRecord(item.id)
                                                                                }}>Delete</button>
                                                                            </td>
                                                                        </tr>
                                                                        {item.ServiceCategories.map((item1, key1) => {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td>{key1 + 1}</td>
                                                                                        <td>{item1.name}</td>
                                                                                        <td>{item1.alias}</td>
                                                                                        <td><span className={"badge " + (item1.status == 'active' ? 'standard-btn' : 'premium-btn')}>{item1.status}</span></td>
                                                                                        <td>
                                                                                            <Link to={`/categories/service-category/edit/${item1.id}`}><button type="button" class="btn details-btn">Edit</button></Link>
                                                                                            <button type="button" class="btn details-btn ml-1" onClick={() => {
                                                                                                deleteRecord(item1.id)
                                                                                            }}>Delete</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                    </React.Fragment>

                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div class="row justify-content-end">
                                                    <div class="text-right mr-3">
                                                        {
                                                            noOfPages ?
                                                                <Pagination count={noOfPages} defaultPage={1} siblingCount={2} boundaryCount={2} variant="outlined" shape="rounded" color="primary" onChange={(e, page) => {
                                                                    setQuery({ ...query, page: page });
                                                                }} />
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceCategoryList;