import React, { useEffect, useState } from "react";
import InputFields from "../../Forms/InputField";
import { API_END_URLS } from "../../../config/endUrls";
import { ApiRequest } from "../../../config/apiRequest";
import { setGlobalState } from "../../../redux/dispatch";
import { showMessage } from "../../../helpers/toast";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../ReactQuill/TextEditor";

function Blog() {
  const navigate = useNavigate();
  const [blogTypes, setBlogTypes] = useState([]);

  const blogTypeList = async () => {
    try {
      const { success, message, data } = await ApiRequest({
        endUrl: API_END_URLS.blogTypes,
        method: "get",
      });
      if (success) {
        setBlogTypes(data?.list);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: Blog.jsx:29 ~ blogTypeList ~ err:", err);
    }
  };

  const [banner, setBanner] = useState({
    blogImageHeaderUrl: null,
  });

  const saveImage = (e, key) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
    }
    if (fileSize < 50 && fileSize < 2000) {
      alert("Please upload file size must be between 200KB and 2MB");
      return false;
    }
    UploadImage(file, key);
    return true;
  };

  const UploadImage = async (file, key) => {
    const fileData = new FormData();
    let fileJson = {};
    fileData.append("file", file);
    let fname = file["name"];
    fileJson[fname] = "CMSHomeImage/" + fname;
    fileData.append("jsondata", JSON.stringify(fileJson));
    const { data, success } = await ApiRequest({
      endUrl: API_END_URLS.fileUpload,
      method: "post",
      body: fileData,
      headerType: "file",
    });
    setBanner({ ...banner, [key]: data });

    console.log(
      "🚀 ~ file: HomePage.jsx:77 ~ handleUpdate ~ success:",
      success,
      data
    );
  };

  const handleUpdate = async (key) => {
    console.log("🚀 ~ file: Blog.jsx:56 ~ handleUpdate ~ key:", key);
    const PAYLOAD = {
      module_name: "blog_image_url",
      section_name: key,
      content_value: banner[key],
    };
    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };

  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setBanner(data2);

      if (success) {
        console.log(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  const [blogImageView, setBlogImageView] = useState({
    blogImage: "images/whoweare1.png",
  });

  const [createBlog, setCreateBlog] = useState({
    blog_type: "",
    blog_title: "",
    blog_description: "",
    blog_image: "",
  });


  const blogImageRender = (e) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        alert("Please upload file size must be between 200KB and 2MB");
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        let nab = { ...blogImageView };
        nab["blogImageFile"] = file;
        nab["blogImage"] = e.target.result;
        setBlogImageView(nab);
      };

      reader.readAsDataURL(e.target.files[0]);
      setBlogImageView(e.target.files[0]);

      setCreateBlog({ ...createBlog, blog_image: "blogs/" + file["name"] });
    }

    return true;
  };

  const handleSubmit = async () => {
    setGlobalState({ loader: true });
    try {
      let req = createBlog;

      console.log(createBlog)
      // return
      if (!req?.blog_type) {
        showMessage("Please Select Blog Type");
      } else if (!req?.blog_image) {
        showMessage("Please Upload Blog Image");
      } else if (!req?.blog_title) {
        showMessage("Please Enter Blog Title");
      } else if (!req?.blog_description) {
        showMessage("Please Enter Blog Description");
      }
      const { success, message } = await ApiRequest({
        endUrl: API_END_URLS.createBlog,
        method: "post",
        body: req,
      });
      if (success) {
        if (blogImageView["blogImageFile"]) {
          const fileData = new FormData();
          let fileJson = {};
          fileData.append("file", blogImageView["blogImageFile"]);
          let fname = blogImageView["blogImageFile"]["name"];
          fileJson[fname] = "blogs/" + fname;
          fileData.append("jsondata", JSON.stringify(fileJson));
          const { success } = await ApiRequest({
            endUrl: API_END_URLS.fileUpload,
            method: "post",
            body: fileData,
            headerType: "file",
          });
          showMessage("Blog Created Successfully");
          navigate("/blog-list");
        } else {
          showMessage(message);
        }
      } else {
        showMessage(message);
      }
    } catch (err) {
      showMessage(err);
    } finally {
      setGlobalState({ loader: false });
    }
  };

  useEffect(() => {
    getData();
    blogTypeList();
  }, []);

  return (
    <>
      <div className="col-md-9 p-sm-0 ">
        <div class="right-contentbox">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="head-content">
                <p>Blog - Banner</p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    banner?.blogImageHeaderUrl != null
                      ? banner?.blogImageHeaderUrl
                      : "/images/blog-banner.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="/images/nav-camera.png" alt=""></img>
                  </button>
                  <input
                    name="blogImageHeaderUrl"
                    type="file"
                    onChange={(e) => saveImage(e, "blogImageHeaderUrl")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-4">
              <div class="head-content">
                <p>Image Resolution </p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                1440 px * 365 px only{" "}
              </span>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("blogImageHeaderUrl")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div class="row mt-4 mt-sm-5">
            <div class="col-md-12 col-12">
              <div class="head-content">
                <p className="dark-p">Blog Catagory</p>
              </div>
            </div>
            <div className="col-md-12 col-12 mt-3">
              <select
                class="form-control blog-select-drop"
                value={createBlog?.blog_type}
                onChange={(e) => {
                  setCreateBlog({ ...createBlog, blog_type: e.target.value });
                }}
              >
                <option value="">Select</option>
                {blogTypes?.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item?.id}
                    >
                      {item?.blog_type}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row mt-3">
            <div class="col-md-4 mb-sm-0 mb-3">
              <div class="contentimg-box position-relative">
                <img src={blogImageView["blogImage"]} alt="" />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="images/nav-camera.png" alt=""></img>
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => blogImageRender(e, createBlog.blog_image)}
                  />
                </div>
              </div>
              <div class="head-content mt-2">
                <p>Image Resolution</p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                {/* 298 px * 298 px only */}
                1440 px * 530 px only
              </span>
            </div>
          </div>

          <div className="row mt-2 mt-sm-4">
            <div className="col-md-12 col-12">
              <div class="head-content">
                <p className="dark-p">Blog Heading</p>
              </div>
            </div>
            <div className="col-md-12 col-12 mt-3">
              <div className="">
                <InputFields
                  placeholder={"Let Us Help!"}
                  className={"mt-0"}
                  onChange={(e) =>
                    setCreateBlog({ ...createBlog, blog_title: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-sm-5">
            <div className="col-md-12 col-12">
              <div class="form-group">
                <b> Blog Text</b>
                <div class="form-group mt-3">
                  <TextEditor
                    value={createBlog?.blog_description}
                    onChange={(e) =>
                      setCreateBlog((prev) => ({
                        ...prev,
                        blog_description: e,
                      }))
                    }
                  />
                </div>
              </div>
              <div class="banner-uploadbtn mt-3 mt-sm-5">
                <button
                  class="uploadBtn new-update-btn align-items-center d-flex"
                  onClick={() => handleSubmit()}
                >
                  <img
                    className="mr-1"
                    src="/images/uploadbtn-icon.svg"
                    alt="/"
                  />{" "}
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
