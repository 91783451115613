import React, { useEffect, useState } from "react";
import InputFields from "../../Forms/InputField";
import TextEditor from "../../ReactQuill/TextEditor";
import { setGlobalState } from "../../../redux/dispatch";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";

function AboutUs() {
  const [aboutUsData, setAboutUsData] = useState({
    aboutUsBannerImageUrl: null,
    ourStoryText: null,
    aboutUsHeading: null,
    descriptionOne: null,
    descriptionTwo: null,
    descriptionThree: null,
    descriptionFour: null,
    descriptionFive: null,
    descriptionSix: null,
    descriptionSeven: null,
    whyUsImageUrl: null,
    whyUsDescriptionOne: null,
    whyUsDescriptionTwo: null,
    whyUsDescriptionThree: null,
    whyUsDescriptionFour: null,
    whyUsDescriptionFive: null,
    whyUsDescriptionSix: null,
    whyUsDescriptionSeven: null,
    ourMissionDescription: null,
    ourMissionImageUrl: null,
    ourInvestorsDescription: null,
    ourInvestorsImageOneUrl: null,
    ourInvestorsImageTwoUrl: null,
    ourInvestorsImageThreeUrl: null,
    ourInvestorsImageFourUrl: null,
  });

  const saveImage = (e, key) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        alert("Please upload file size must be between 200KB and 2MB");
        return false;
      }
    }
    UploadImage(file, key);
    return true;
  };

  const hanldeChange = (value, key) => {
    setAboutUsData((pre) => ({ ...pre, [key]: value }));
  };

  const UploadImage = async (file, key) => {
    const fileData = new FormData();
    let fileJson = {};
    fileData.append("file", file);
    let fname = file["name"];
    fileJson[fname] = "CMSHomeImage/" + fname;
    fileData.append("jsondata", JSON.stringify(fileJson));
    const { data, success } = await ApiRequest({
      endUrl: API_END_URLS.fileUpload,
      method: "post",
      body: fileData,
      headerType: "file",
    });
    setAboutUsData({ ...aboutUsData, [key]: data });

    console.log(
      "🚀 ~ file: HomePage.jsx:77 ~ handleUpdate ~ success:",
      success,
      data
    );
  };

  const handleUpdate = async (key) => {
    const PAYLOAD = {
      module_name: "about_us",
      section_name: key,
      content_value: aboutUsData[key],
    };

    console.log(PAYLOAD, "payload");

    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };

  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setAboutUsData(data2);

      if (success) {
        console.log(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="col-md-9 p-sm-0 ">
        <div class="right-contentbox">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="head-content">
                <p>About Us - Banner</p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    aboutUsData?.aboutUsBannerImageUrl != null
                      ? aboutUsData?.aboutUsBannerImageUrl
                      : "/images/about-banner.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="/images/nav-camera.png" alt=""></img>
                  </button>
                  <input
                    name="aboutUsBannerImageUrl"
                    type="file"
                    onChange={(e) => saveImage(e, "aboutUsBannerImageUrl")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-4">
              <div class="head-content">
                <p>Image Resolution </p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                2304 px * 2880 px only{" "}
              </span>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("aboutUsBannerImageUrl")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="head-content">
                <p>Our Story</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-left" style={{ color: "#000000" }}>
                  Digital revolution across the globe over the last few decades
                  had transformed and re-shaped the operation models of
                  businesses. The rise of internet and advancements in digital
                  technologies has revolutionized e-commerce leading to a
                  transformation of how we connect and conduct business.
                  Geographical barriers have disappeared making it ......
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="ourStoryText"
                  onChange={(e) => hanldeChange(e, "ourStoryText")}
                  value={aboutUsData?.ourStoryText}
                />
              </div>
              <div class="banner-uploadbtn">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("ourStoryText")}
                >
                  Update{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              <div class="head-content">
                <p>Heading</p>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Our Portal Feature"}
                  className={"mt-0"}
                  value={aboutUsData?.aboutUsHeading}
                  name={"aboutUsHeading"}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "aboutUsHeading")
                  }
                />
              </div>

              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("aboutUsHeading")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-sm-5 mt-4">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"descriptionOne"}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "descriptionOne")
                  }
                  value={aboutUsData?.descriptionOne}
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("descriptionOne")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl- col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"descriptionTwo"}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "descriptionTwo")
                  }
                  value={aboutUsData?.descriptionTwo}
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("descriptionTwo")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"descriptionThree"}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "descriptionThree")
                  }
                  value={aboutUsData?.descriptionThree}
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("descriptionThree")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"descriptionFour"}
                  value={aboutUsData?.descriptionFour}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "descriptionFour")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("descriptionFour")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"descriptionFive"}
                  value={aboutUsData?.descriptionFive}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "descriptionFive")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("descriptionFive")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"descriptionSix"}
                  value={aboutUsData?.descriptionSix}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "descriptionSix")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("descriptionSix")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"descriptionSeven"}
                  value={aboutUsData?.descriptionSeven}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "descriptionSeven")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("descriptionSeven")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 col-12">
              <div class="head-content">
                <p>Why Us</p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    aboutUsData?.whyUsImageUrl != null
                      ? aboutUsData?.whyUsImageUrl
                      : "/images/about-banner.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="images/nav-camera.png" alt=""></img>
                  </button>
                  <input
                    name="whyUsImageUrl"
                    type="file"
                    onChange={(e) => saveImage(e, "whyUsImageUrl")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <div class="head-content">
                <p>Image Resolution </p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                2880 px * 2158 px only{" "}
              </span>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("whyUsImageUrl")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"whyUsDescriptionOne"}
                  value={aboutUsData?.whyUsDescriptionOne}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "whyUsDescriptionOne")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("whyUsDescriptionOne")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"whyUsDescriptionTwo"}
                  value={aboutUsData?.whyUsDescriptionTwo}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "whyUsDescriptionTwo")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("whyUsDescriptionTwo")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"whyUsDescriptionThree"}
                  value={aboutUsData?.whyUsDescriptionThree}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "whyUsDescriptionThree")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("whyUsDescriptionThree")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"whyUsDescriptionFour"}
                  value={aboutUsData?.whyUsDescriptionFour}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "whyUsDescriptionFour")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("whyUsDescriptionFour")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"whyUsDescriptionFive"}
                  value={aboutUsData?.whyUsDescriptionFive}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "whyUsDescriptionFive")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("whyUsDescriptionFive")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"whyUsDescriptionSix"}
                  value={aboutUsData?.whyUsDescriptionSix}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "whyUsDescriptionSix")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("whyUsDescriptionSix")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-1 mt-3 col-2">
              <div className="grren-dot"></div>
            </div>
            <div className="col-md-11 pl-0 col-10">
              <div className="darker-input-field">
                <InputFields
                  placeholder={"Lorem Ipsum is a simply dummy text"}
                  className={"mt-0"}
                  name={"whyUsDescriptionSeven"}
                  value={aboutUsData?.whyUsDescriptionSeven}
                  onChange={(e) =>
                    hanldeChange(e.target.value, "whyUsDescriptionSeven")
                  }
                />
              </div>
              <div className="banner-uploadbtn">
                <button
                  className="uploadBtn"
                  onClick={() => handleUpdate("whyUsDescriptionSeven")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="head-content">
                <p>Our Mission</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-left" style={{ color: "#000000" }}>
                  The mission of Navigators R us is to become a global online
                  portal enabling our subscribers to connect with the world with
                  ease and comfort to achieve their business goals.
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="ourMissionDescription"
                  value={aboutUsData?.ourMissionDescription}
                  onChange={(e) => hanldeChange(e, "ourMissionDescription")}
                />
              </div>
              <div class="banner-uploadbtn">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("ourMissionDescription")}
                >
                  Update{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="head-content">
                <p>Our Mission Image</p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contentimg-box position-relative">
              <img
                src={
                  aboutUsData?.ourMissionImageUrl != null
                    ? aboutUsData?.ourMissionImageUrl
                    : "images/whoweare1.png"
                }
                alt=""
              />
              <div class="upload-btn-wrapper new-updatebtn">
                <button class="uploadBtn">
                  <img src="images/nav-camera.png" alt=""></img>
                </button>
                <input
                  name="ourMissionImageUrl"
                  type="file"
                  onChange={(e) => saveImage(e, "ourMissionImageUrl")}
                />
              </div>
            </div>
            <div class="banner-uploadbtn mt-4">
              <button
                class="uploadBtn"
                onClick={() => handleUpdate("ourMissionImageUrl")}
              >
                Update{" "}
              </button>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="head-content">
                <p>Our Investors</p>
              </div>
            </div>
          </div>
          <div className="sparess-textbox mt-3">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-left" style={{ color: "#000000" }}>
                  The investors of “Navigators Are Us” are based in United Arab
                  Emirates and comprise of a group of professional individuals
                  who have a multitude of experience and knowledge in the fields
                  of marine, air and land based businesses.
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="ourInvestorsDescription"
                  value={aboutUsData?.ourInvestorsDescription}
                  onChange={(e) => hanldeChange(e, "ourInvestorsDescription")}
                />
              </div>
              <div class="banner-uploadbtn">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("ourInvestorsDescription")}
                >
                  Update{" "}
                </button>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-3 col-6 mb-sm-0 mb-3">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    aboutUsData?.ourInvestorsImageOneUrl != null
                      ? aboutUsData?.ourInvestorsImageOneUrl
                      : "/images/action-user.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="images/nav-camera.png" alt="" />
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    name="ourInvestorsImageOneUrl"
                    onChange={(e) => saveImage(e, "ourInvestorsImageOneUrl")}
                  />
                </div>
              </div>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("ourInvestorsImageOneUrl")}
                >
                  Update
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6 mb-sm-0 mb-3">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    aboutUsData?.ourInvestorsImageTwoUrl != null
                      ? aboutUsData?.ourInvestorsImageTwoUrl
                      : "/images/action-user.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="images/nav-camera.png" alt="" />
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    name="ourInvestorsImageTwoUrl"
                    onChange={(e) => saveImage(e, "ourInvestorsImageTwoUrl")}
                  />
                </div>
              </div>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("ourInvestorsImageTwoUrl")}
                >
                  Update
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    aboutUsData?.ourInvestorsImageThreeUrl != null
                      ? aboutUsData?.ourInvestorsImageThreeUrl
                      : "/images/action-user.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="images/nav-camera.png" alt="/" />
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    name="ourInvestorsImageThreeUrl"
                    onChange={(e) => saveImage(e, "ourInvestorsImageThreeUrl")}
                  />
                </div>
              </div>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("ourInvestorsImageThreeUrl")}
                >
                  Update
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="contentimg-box banner-box position-relative">
                <img
                  src={
                    aboutUsData?.ourInvestorsImageFourUrl != null
                      ? aboutUsData?.ourInvestorsImageFourUrl
                      : "/images/action-user.png"
                  }
                  alt=""
                />
                <div class="upload-btn-wrapper new-updatebtn">
                  <button class="uploadBtn">
                    <img src="images/nav-camera.png" alt="" />
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    name="ourInvestorsImageFourUrl"
                    onChange={(e) => saveImage(e, "ourInvestorsImageFourUrl")}
                  />
                </div>
              </div>
              <div class="upload-btn-wrapper mt-3">
                <button
                  class="uploadBtn"
                  onClick={() => handleUpdate("ourInvestorsImageFourUrl")}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-4">
              <div class="head-content mt-4">
                <p>Image Resolution </p>
              </div>
              <span class="lenth-box mt-2">
                {" "}
                <img src="images/question.png" alt="" />
                625 px * 485 px only{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 p-sm-0"></div>
    </>
  );
}

export default AboutUs;
