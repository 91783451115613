module.exports = {
  SERVER_URL:
    process.env.REACT_APP_ENV === "live"
      ? process.env.REACT_APP_BACKEND_URL_LIVE
      : process.env.REACT_APP_ENV === "uat"
      ? process.env.REACT_APP_BACKEND_URL_UAT
      : process.env.REACT_APP_BACKEND_URL_LOCAL,
  pageLimit: 10,
  CRYPTO_SECRET_KEY: "Marine2453%^&#",
  roles: {
    admin: "Admin",
    superAdmin: "SuperAdmin",
    user: "User",
  },

  // S3Bucket: "https://s3bucketstaqo.s3.ap-south-1.amazonaws.com/marine/",
  S3Bucket:
    "https://navigatorsrus-s3.s3.ap-south-1.amazonaws.com/navigatorsrus/",

  ReviewStatus: {
    Reply: "Reply",
    Replied: "Replied",
    Published: "Published",
    Unpublished: "Unpublished",
    Viewed: "Viewed",
  },

  ContactUsStatus: {
    View: "View",
    Viewed: "Viewed",
    Responded: "Responded",
  },

  HelpDeskStatus: {
    View: "View",
    Viewed: "Viewed",
    Responded: "Responded",
  },
  TransactionStatus: {
    Pending: "Pending",
    Holding: "Holding",
    Failed: "Failed",
    Authorized: "Authorized",
    succeeded: "succeeded",
  },
  SubScriptionStatus: {
    standard: "Standard",
    premium: "Premium",
  },
  period: {
    Monthly: "M",
    Annual: "Y",
  },

  ContentManagement: {
    homepage: {
      alisa: "Home Page",
      as: "home_page",
      sections: {
        bannerHeading: {
          alisa: "Banner Heading",
          as: "banner_heading",
        },
        bannerSubText: {
          alisa: "Banner Sub Text",
          as: "banner_sub_text",
        },
        newMonthlyCustomer: {
          alisa: "New Monthly Customer",
          as: "new_monthly_customer",
        },
        newMonthlyCustomerValue: {
          alisa: "New Monthly Customer Value",
          as: "new_monthly_customer_value",
        },
        increaseInTheSize: {
          alisa: "Increase in the size our Parts",
          as: "increase_in_the_size_our_parts",
        },
        increaseInTheSizeValue: {
          alisa: "Increase in the size our Parts Value",
          as: "increase_in_the_size_our_parts_value",
        },
        yearOfExperienceAsMarketPlayer: {
          alisa: "Year Of Experience As Market Player",
          as: "year_of_experience_as_market_player",
        },
        yearOfExperienceAsMarketPlayerValue: {
          alisa: "Year Of Experience As Market Player Value",
          as: "year_of_experience_as_market_player_value",
        },
        timeMoreRFQsSentAnnually: {
          alisa: "Time More RFQs Sent Annually Value",
          as: "time_more_rfqs_sent_annually_value",
        },
        imageResolution: {
          alisa: "Image Resolution",
          as: "image_resolution",
        },
        sea: {
          alisa: "Sea",
          as: "sea",
        },
        air: {
          alisa: "Air",
          as: "air",
        },
        land: {
          alisa: "Land",
          as: "land",
        },
        seaDescription: {
          alisa: "Sea Description",
          as: "sea_description",
        },
        airDescription: {
          alisa: "Air Description",
          as: "air_desciption",
        },
        landDesciption: {
          alisa: "Land Description",
          as: "land_description",
        },
        landImage: {
          alies: "Land Image",
          as: "land_image",
        },
        airImage: {
          alies: "Air Image",
          as: "air_image",
        },
        sectionHeading: {
          alisa: "Section Heading",
          as: "section_heading",
        },
        bottambannerSubText: {
          alies: "Bottam Banner Sub Text",
          as: "bottam_banner_sub_text",
        },
        satisfiedCustomers: {
          alies: "SatisFied Customers",
          as: "satusfied_customers",
        },
        satisfiedCustomersValue: {
          alies: "SatisFied Customers Value",
          as: "satisfied_customers_value",
        },
        whyWeAreTextFirst: {
          alies: "Why We Are First Text First",
          as: "why_we_are_first_text_first",
        },
        whyWeAreTextSecond: {
          alies: "Why We Are Second Text ",
          as: "why_we_are_first_text_second",
        },
        whyWeAreTextThird: {
          alies: "Why We Are Third Text",
          as: "why_we_are_thid_text",
        },
      },
    },
  },
};
