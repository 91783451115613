import React, { useEffect, useState } from "react";
import Header from "../../../pages/include/header";
import Sidemenu from "../../../pages/include/side-manu";
import CmsSideBar from "../../../components/CMS/CmsSideBar";
import { Link } from "react-router-dom";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";

function BlogList() {
  const [blogData, setBlogData] = useState([]);

  const fatchData = async () => {
    try {
      const { data, message, success } = await ApiRequest({
        endUrl: API_END_URLS.getBlog,
        method: "get",
        token: true,
      });
      if (success) {
        setBlogData(data?.blogData);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: BlogList.jsx:24 ~ fatchData ~ err:", err);
    }
  };

  const deleteRecord = async (id) => {
    try {
      let permision = window.confirm(
        "Are you sure you want to delete the blog"
      );
      if (!permision) {
        return;
      }
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.deleteBlog + id,
        method: "delete",
        token: true,
      });
      if (success) {
        showMessage(message);
        fatchData();
      } else {
        showMessage(message);
      }
    } catch (error) {
      console.log("🚀 ~ file: BlogList.jsx:38 ~ deleteRecord ~ error:", error);
    }
  };

  useEffect(() => {
    fatchData();
  }, []);

  return (
    <React.Fragment>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding padding-rightnone">
                <div class="row align-items-center mt-sm-0 mt-3">
                  <div class="col-md-9 col-12">
                    <div class="head-text new-cost-head">
                      <h1>Blog Listing</h1>
                    </div>
                  </div>
                  <div class="col-md-3 col-12">
                    <Link to="/blog">
                      <button
                        type="button"
                        class="btn btn-block btn-analy btn-analy-2"
                      >
                        <img src="/images/plus-btn.svg" alt="/" />
                        CREATE BLOG
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="divider-line"></div>
                  </div>
                </div>
                <div class="row">
                  <div className="col-md-3 col-8 pr-0 border-right mb-2 mb-sm-0">
                    <CmsSideBar />
                  </div>
                  <div class="col-md-9">
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="manage-table table table">
                            <thead>
                              <tr>
                                {/* <td class="head-td">ID</td> */}
                                <td class="head-td">Blog Type</td>
                                <td class="head-td">Blog Title</td>
                                <td class="head-td">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {                              
                              blogData?.map((blogItem, blogIndex) => {
                                return blogItem?.CMSBlogs.map((item, index) => {
                                  return (
                                    <tr>
                                      {/* <td>{index +1}</td> */}
                                      <td>{blogItem?.blog_type}</td>
                                      <td>{item?.blog_title}</td>
                                      <td>
                                        <Link to={`/blog-edit/${item?.id}`}>
                                          <button class="btn btn-edit">
                                            Edit
                                          </button>
                                        </Link>
                                        <button
                                          type="button"
                                          class="btn details-btn ml-1"
                                          onClick={() => {
                                            deleteRecord(item?.id);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                });
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div class="row justify-content-end">
                          <div class="text-right mr-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BlogList;
