import React, { useEffect, useState } from "react";
import Sidemenu from "../../../include/side-manu";
import Header from "../../../include/header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_END_URLS } from "../../../../config/endUrls";
import { ApiRequest } from "../../../../config/apiRequest";
import { showMessage } from "../../../../helpers/toast";

function ProductCategoryEdit() {
    
    const navigate = useNavigate()
  const params = useParams();

  const [updateProduct, setUpdateProduct] = useState({});

  const fatchProduct = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.ProductCategoryView,
        method: "get",
        params: params,
      });
      if (success) {
        setUpdateProduct({ ...data });
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: ProductCategoriesList.js:25 ~ fetchProductListing ~ err:",
        err
      );
    }
  };

  const handleUpdate = async (req, res) => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.ProductCategoryUpdate,
        method: "put",
        body: updateProduct,
        params: params,
      });

      if (success) {
        setUpdateProduct(data);
        showMessage(message);
        navigate(`/product/categories-listing/${updateProduct?.CategoryId}`)
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: ProductCategoriesList.js:25 ~ fetchProductListing ~ err:",
        err
      );
    }
  };

  useEffect(() => {
    fatchProduct();
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 d-none d-sm-block p-0">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding">
                <div class="row justify-content-between">
                  <div class="col-md-8 col-12">
                    <div class="head-text user-headtext add-text my-sm-0 my-3">
                      <h1>Product Edit</h1>
                    </div>
                  </div>
                  <div class="col-md-1 col-3">
                    <Link to={`/product/categories-listing/${updateProduct?.CategoryId}`}>
                      <button type="button" class="btn btn-block btn-analy">
                        <img
                          className="mr-0"
                          src="/images/back-arrow.svg"
                          alt="/"
                        />
                      </button>
                    </Link>
                  </div>
                  <div class="col-md-12">
                    <div class="border-bottom pb-2"></div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Product Name</label>
                      <input
                        type="text"
                        name="product_name"
                        class="form-control"
                        placeholder="Enter Name"
                        value={updateProduct?.product_name}
                        onChange={(e) =>
                          setUpdateProduct((prev) => ({
                            ...prev,
                            product_name: e.target.value,
                          }))
                        }
                      />
                      <div style={{ color: "red" }}>
                        {/* {Boolean(touched.name) && errors.name} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Category Type</label>
                      <select
                        className="form-control"
                        style={{ cursor: "pointer" }}
                        name="category_type"
                        disabled
                      >
                        <div style={{ color: "red" }}>
                          {/* {Boolean(touched.category_type) &&
                              errors.category_type} */}
                        </div>
                        <option value={updateProduct?.Category?.category_name}>
                          {updateProduct?.Category?.category_name}
                        </option>
                      </select>
                      <div style={{ color: "red" }}>
                        {/* {Boolean(touched.category_type) &&
                            errors.category_type} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Status</label>
                      <div class="row">
                        <div class="col-md-4">
                          <input
                            class="with-gap nav-radio"
                            type="radio"
                            id="Check"
                            checked={updateProduct?.status === "Active"}
                            onChange={(e) =>
                              setUpdateProduct((prev) => ({
                                ...prev,
                                status: "Active",
                              }))
                            }
                          />
                          <label class="checkFrom-label" for="Check">
                            Active
                          </label>
                        </div>
                        <div style={{ color: "red" }}>
                          {/* {Boolean(touched.status) && errors.status} */}
                        </div>
                        <div class="col-md-6">
                          <input
                            class="with-gap nav-radio"
                            name="status"
                            type="radio"
                            id="Check2"
                            checked={updateProduct.status === "Inactive"}
                            onChange={(e) =>
                              setUpdateProduct((prev) => ({
                                ...prev,
                                status: "Inactive",
                              }))
                            }
                          />
                          <label class="checkFrom-label" for="Check2">
                            Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3 ">
                  <div class="col-md-12 text-center ">
                    <div class="border-top mb-4"></div>
                    <button
                      type="button"
                      class="btn sparessborder-btn new-cancel mr-2"
                      onClick={() =>navigate('/product/categories-listing/2')}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn sparessfill-btn new-save"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCategoryEdit;
