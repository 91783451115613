import React, { useEffect, useState } from "react";
import TextEditor from "../../ReactQuill/TextEditor";
import { setGlobalState } from "../../../redux/dispatch";
import { ApiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";

function TermsAndCondition() {
  const [data, setData] = useState({
    termsAndConditionText: null,
  });

  const hanldeChange = (value, key) => {
    setData((pre) => ({ ...pre, [key]: value }));
  };

  const handleUpdate = async (key) => {
    const PAYLOAD = {
      module_name: "terms_condition",
      section_name: key,
      content_value: data[key],
    };
    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };


  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setData(data2);

      if (success) {
        console.log(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div class="col-md-9">
        <div class="right-contentbox">
          <div class="row">
            <div class="col-md-12">
              <div class="head-content">
                <p>Terms & Condition - Content</p>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <div className="sparess-textbox mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="text-left" style={{ color: "#818181" }}>
                      This document and any other documents that we reference
                      below in this document are the Terms of Use (the “Terms”).
                      The Terms are a legally binding contract between you “the
                      Subscriber” and “Navigators R us” By agreeing to the
                      Terms, you agree to resolve all disputes through binding
                      individual arbitration, which means that you waive any
                      right to have those disputes decided by a judge or jury,
                      and that you waive your right to participate in class
                      actions, class arbitrations, or representative actions. 
                      This contract sets out your rights and responsibilities
                      when you use “Navigators R us” website, mobile apps, and
                      the other services provided by Navigators R us we’ll refer
                      to all of these collectively as our “Services
                    </h3>
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <b>Text - Update</b>
                <TextEditor
                  name="termsAndConditionText"
                  onChange={(e) => hanldeChange(e, "termsAndConditionText")}
                  value={data?.termsAndConditionText}
                />
              </div>
              <button
                type="submit"
                class="uploadBtn "
                onClick={() => handleUpdate("termsAndConditionText")}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndCondition;
