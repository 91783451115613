import React, { useEffect, useState } from "react";
import Header from "../../../pages/include/header";
import Sidemenu from "../../../pages/include/side-manu";
import CmsSideBar from "../CmsSideBar";
import TextEditor from "../../ReactQuill/TextEditor";
import { API_END_URLS } from "../../../config/endUrls";
import { ApiRequest } from "../../../config/apiRequest";
import { setGlobalState } from "../../../redux/dispatch";
import { showMessage } from "../../../helpers/toast";

export default function WhoWeAre() {
  const [whoWeAreData, setWhoWeAreData] = useState({
    imageWhoWeAreOneUrl: null,
    imageWhoWeAreTwoUrl: null,
    imageWhoWeAreThreeUrl: null,
    WhoWeAreDescription: null,
  });

  const saveImage = (e, key) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        alert("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        alert("Please upload file size must be between 200KB and 2MB");
        return false;
      }
    }
    UploadImage(file, key);
    return true;
  };

  const hanldeChange = (value, key) => {
    setWhoWeAreData((pre) => ({ ...pre, [key]: value }));
  };

  const UploadImage = async (file, key) => {
    const fileData = new FormData();
    let fileJson = {};
    fileData.append("file", file);
    let fname = file["name"];
    fileJson[fname] = "CMSHomeImage/" + fname;
    fileData.append("jsondata", JSON.stringify(fileJson));
    const { data, success } = await ApiRequest({
      endUrl: API_END_URLS.fileUpload,
      method: "post",
      body: fileData,
      headerType: "file",
    });
    setWhoWeAreData({ ...whoWeAreData, [key]: data });

    console.log(
      "🚀 ~ file: HomePage.jsx:77 ~ handleUpdate ~ success:",
      success,
      data
    );
  };

  const handleUpdate = async (key) => {
    const PAYLOAD = {
      module_name: "who_we_are",
      section_name: key,
      content_value: whoWeAreData[key],
    };
    try {
      setGlobalState({ loader: true });
      const { message, success } = await ApiRequest({
        endUrl: API_END_URLS.createCMS,
        method: "post",
        body: PAYLOAD,
      });
      if (success) {
        showMessage(message);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:58 ~ handleUpdate ~ err:", err);
    }
    setGlobalState({ loader: false });
  };


  const getData = async () => {
    try {
      const { data, success, message } = await ApiRequest({
        endUrl: API_END_URLS.getCMSRecord,
        method: "get",
      });
      let data2 = {};
      data?.map((x) => {
        data2[x?.key] = x?.value;
      });
      setWhoWeAreData(data2);

      if (success) {
        console.log(message)
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: HomePage.jsx:80 ~ getData ~ err:", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);




  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 p-0 d-none d-sm-block">
            <Sidemenu></Sidemenu>
          </div>
          <div class="col-md-9 col-12 p-0">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Header></Header>
              </div>

              <div class="pageinnner-padding padding-rightnone">
                <div class="row">
                  <div class="col-md-3 col-8 pr-0 border-right mb-2 mb-sm-0">
                    <CmsSideBar />
                  </div>
                  <div class="col-md-9 p-sm-0">
                    <div class="right-contentbox">
                      <div class="row mt-2">
                        <div class="col-md-12 col-12">
                          <div class="head-content">
                            <p>Who We Are - Popup</p>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-md-4 mb-sm-0 mb-3">
                          <div class="contentimg-box position-relative">
                            <img
                              src={
                                whoWeAreData?.imageWhoWeAreOneUrl != null
                                  ? whoWeAreData?.imageWhoWeAreOneUrl
                                  : "images/whoweare1.png"
                              }
                              alt=""
                            />
                            <div class="upload-btn-wrapper new-updatebtn">
                              <button class="uploadBtn">
                                <img src="images/nav-camera.png" alt=""></img>
                              </button>
                              <input
                                name="imageWhoWeAreOneUrl"
                                type="file"
                                onChange={(e) =>
                                  saveImage(e, "imageWhoWeAreOneUrl")
                                }
                              />
                            </div>
                          </div>
                          <div class="head-content mt-2">
                            <p>Image Resolution</p>
                          </div>
                          <span class="lenth-box mt-2">
                            {" "}
                            <img src="images/question.png" alt="" />
                            298 px * 298 px only{" "}
                          </span>
                          <div class="upload-btn-wrapper mt-3">
                            <button
                              class="uploadBtn"
                              onClick={() =>
                                handleUpdate("imageWhoWeAreOneUrl")
                              }
                            >
                              Update
                            </button>
                          </div>
                        </div>

                        <div class="col-md-4 mb-sm-0 mb-3">
                          <div class="contentimg-box position-relative">
                            <img
                              src={
                                whoWeAreData?.imageWhoWeAreTwoUrl != null
                                  ? whoWeAreData?.imageWhoWeAreTwoUrl
                                  : "images/whoweare1.png"
                              }
                              alt=""
                            />
                            <div class="upload-btn-wrapper new-updatebtn">
                              <button class="uploadBtn">
                                <img src="images/nav-camera.png" alt=""></img>
                              </button>
                              <input
                                name="imageWhoWeAreTwoUrl"
                                type="file"
                                onChange={(e) =>
                                  saveImage(e, "imageWhoWeAreTwoUrl")
                                }
                              />
                            </div>
                          </div>
                          <div class="head-content mt-2">
                            <p>Image Resolution</p>
                          </div>
                          <span class="lenth-box mt-2">
                            {" "}
                            <img src="images/question.png" alt="" />
                            220 px * 220 px only{" "}
                          </span>
                          <div class="upload-btn-wrapper mt-3">
                            <button
                              class="uploadBtn"
                              onClick={() =>
                                handleUpdate("imageWhoWeAreTwoUrl")
                              }
                            >
                              Update
                            </button>
                          </div>
                        </div>

                        <div class="col-md-4 mb-sm-0 mb-3">
                          <div class="contentimg-box position-relative">
                            <img
                              src={
                                whoWeAreData?.imageWhoWeAreThreeUrl != null
                                  ? whoWeAreData?.imageWhoWeAreThreeUrl
                                  : "images/whoweare1.png"
                              }
                              alt=""
                            />
                            <div class="upload-btn-wrapper new-updatebtn">
                              <button class="uploadBtn">
                                <img src="images/nav-camera.png" alt=""></img>
                              </button>
                              <input
                                name="imageWhoWeAreThreeUrl"
                                type="file"
                                onChange={(e) =>
                                  saveImage(e, "imageWhoWeAreThreeUrl")
                                }
                              />
                            </div>
                          </div>
                          <div class="head-content mt-2">
                            <p>Image Resolution</p>
                          </div>
                          <span class="lenth-box mt-2">
                            {" "}
                            <img src="images/question.png" alt="" />
                            164 px * 146 px only{" "}
                          </span>
                          <div class="upload-btn-wrapper mt-3">
                            <button
                              class="uploadBtn"
                              onClick={() =>
                                handleUpdate("imageWhoWeAreThreeUrl")
                              }
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="head-content">
                            <p>Popup - Text</p>
                          </div>
                        </div>
                      </div>
                      <div className="sparess-textbox mt-3">
                        <div className="row">
                          <div className="col-md-12">
                            <h3
                              className="text-left"
                              style={{ color: "#000000" }}
                            >
                              An online portal to list your products & services
                              applied in marine, aviation & land based needs to
                              sale or rent or lease
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div class="form-group mt-3">
                            <b>Text - Update</b>
                            <TextEditor
                              name="WhoWeAreDescription"
                              onChange={(e) =>
                                hanldeChange(e, "WhoWeAreDescription")
                              }
                              value={whoWeAreData?.WhoWeAreDescription}
                            />
                          </div>
                          <div class="banner-uploadbtn">
                            <button
                              class="uploadBtn"
                              onClick={() =>
                                handleUpdate("WhoWeAreDescription")
                              }
                            >
                              Update{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
